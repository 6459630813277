*{
  font-family: 'Poppins', sans-serif;
}

.dashboard {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f8f9fa;
  font-family: 'Arial', sans-serif;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #e9ecef;
  width: 100%;
  max-width: 768px;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.dashboard-header h1 {
  font-size: 2rem;
  color: #343a40;
  margin: 0;
}

.availability-toggle {
  display: flex;
  align-items: center;
}

.availability-toggle span {
  margin-right: 8px;
}

.earnings {
  background: linear-gradient(135deg, #4CAF50, #45a049);
  color: white;
  padding: 0.5rem;
  border-radius: 12px;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  max-width: 30%;
  text-align: center;
  display: inline-block;
  transition: transform 0.3s ease-in-out;
}

.earnings span {
  font-size: 1.5rem;
  font-weight: 600;
  display: inline-block;
  animation: pulse 2s infinite ease-in-out;
  margin-left: 53%;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
  justify-content: center;
  align-content: start;
  width: 100%;
  max-width: 768px;
  margin: 20px auto;
}

.stat-item {
  background: white;
  padding: 1.5rem;
  border-radius: 12px;
  text-align: center;
  text-decoration: none;
  color: #495057;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.stat-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.stat-item svg {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #6c757d;
}

.stat-number {
  font-size: 2rem;
  font-weight: 700;
  margin: 0.5rem 0;
  color: #212529;
}

.stat-label {
  font-size: 1rem;
  color: #6c757d;
}

.stats-grid::after {
  content: '';
  display: block;
  height: 0;
}

.stat-item:last-child:nth-child(odd) {
  grid-column-start: auto;
  grid-column-end: auto;
  grid-column-end: span 2;
}

/* Interruptor */

/* Responsive design for different screen sizes */
@media (max-width: 768px) {
  .dashboard {
    width: 100%;
    max-width: none;
  }
  .stats-grid {
    grid-template-columns: 1fr;
  }
  .dashboard-header, .earnings {
    padding: 10px;
  }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.04); }
  100% { transform: scale(1); }
}

@media (max-width: 768px) {
  .earnings {
    padding: 10px;
    font-size: 1rem;
  }
}
