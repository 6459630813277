/* FeeManager.css */
.fee-manager {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra horizontalmente el contenido */
  }
  
  .fee-manager .titleFee {
    margin-top: 10%;
    text-align: center;
  }

.fee-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.fee-table th,
.fee-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.fee-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.fee-table input {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}

.fee-table button {
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
}

.fee-table button:hover {
  background-color: #ddd;
}

@media (max-width: 768px) {
  .fee-manager {
    padding: 10px;
  }

  .fee-table {
    font-size: 14px;
  }

  .fee-table th,
  .fee-table td {
    padding: 6px;
  }

  .fee-table button {
    padding: 5px;
    margin: 2px;
  }

  .fee-table input {
    padding: 4px;
  }
}

@media (max-width: 480px) {
  .fee-table {
    font-size: 12px;
  }

  .fee-table th,
  .fee-table td {
    padding: 4px;
  }

  .fee-table button {
    padding: 4px;
    margin: 1px;
    font-size: 12px;
  }

  .fee-table input {
    padding: 3px;
    font-size: 12px;
  }
}
