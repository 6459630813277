.success-animation-container {
  text-align: center;
  max-width: 60%; /* Establece un ancho máximo para el contenedor */
  margin: 50px auto; /* Centra el contenedor y añade espacio arriba y abajo */
  padding: 40px; /* Espacio interior */
  background-color: #f9f9f9; /* Fondo claro para el contenedor */
  border-radius: 8px; /* Bordes redondeados */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
  font-family: "Arial", sans-serif; /* Fuente del texto */
  animation: fadeIn 0.5s ease-in-out both; /* Animación de entrada */
}

.checkmark-circle {
  width: 100px;
  height: 100px;
  display: inline-block;
  background: #4bb543; /* Color de fondo del círculo */
  border-radius: 50%; /* Hace el círculo redondo */
  margin: 20px auto; /* Centra el círculo horizontalmente y añade espacio vertical */
}

.checkmark {
  color: white; /* Color del ícono de verificación */
  font-size: 50px; /* Tamaño del ícono de verificación */
  position: relative; /* Posicionamiento relativo para centrar el ícono */
  top: 50%; /* Desplazamiento vertical para centrar */
  left: 50%; /* Desplazamiento horizontal para centrar */
  transform: translate(
    -50%,
    -50%
  ); /* Centra exactamente el ícono en el círculo */
}

.checkmark-circle circle {
  stroke: #4bb543;
  stroke-width: 2;
  fill: none;
  animation: stroke 0.6s ease-in-out forwards;
}

.checkmark-check {
  stroke: #ffffff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 86px;
  stroke-dashoffset: 86px;
  animation: stroke 0.6s ease-in-out 0.6s forwards;
}

.titulosE {
  color: #4bb543; /* Color del texto para "Entrega Exitosa" */
  margin: 20px 0; /* Espacio arriba y abajo del texto */
  font-size: 24px; /* Tamaño del texto */
  font-weight: bold; /* Negrita para el texto */
  animation: fadeInUp 0.8s ease-in-out forwards; /* Animación de entrada */
  animation-delay: 1.2s; /* Retraso de la animación */
}

.SubTituloFail{
  color: #44ac3d; /* Color del texto para "¡Gracias por tu trabajo!" */
  margin: 20px 0; /* Espacio arriba y abajo del texto */
  font-weight: normal; /* Peso normal del texto */
  animation: fadeInUp 0.8s ease-in-out forwards; /* Animación de entrada */
  animation-delay: 1.4s; /* Retraso de la animación */
}

.btn-primary {
  background-color: #7380EC; /* Color de fondo del botón */
  color: white; /* Color del texto del botón */
  padding: 10px 20px; /* Relleno alrededor del texto del botón */
  border-radius: 20px; /* Bordes redondeados del botón */
  font-size: 1rem; /* Tamaño del texto del botón */
  border: none; /* Sin bordes para el botón */
  cursor: pointer; /* Cursor tipo puntero para indicar que es clickeable */
  outline: none; /* Sin contorno al hacer foco en el botón */
  margin-top: 20px; /* Espacio arriba del botón */
  text-transform: uppercase; /* Texto en mayúsculas */
  animation: fadeInUp 0.8s ease-in-out forwards; /* Animación de entrada */
  animation-delay: 1.6s; /* Retraso de la animación */
}

/* Animaciones */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes stroke {
    to {
      stroke-dashoffset: 0;
    }
  }
