/* Estilos para la lista de entregas */
.delivery-list {
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 600px; /* Ajustado para escritorio */
  margin: 0 auto; /* Centrado en la página */
  width: 100%; /* Usa todo el ancho disponible en dispositivos pequeños */
}

/* Estilos para cada ítem de pedido */
.order-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 15px;
  padding: 15px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative; /* Para posicionamiento relativo del botón */
}

/* Títulos de cada ítem de pedido */
.order-item h3 {
  margin: 0;
  color: #000;
  font-size: 1.1rem;
}

/* Parágrafos en ítems de pedido */
.order-item p {
  margin: 5px 0;
  color: #333;
  font-size: 0.9rem;
}

/* Botón para detalles del pedido */
.boton {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 0.9rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  align-self: flex-end; /* Alineación al final del contenedor flex */
  margin-top: auto; /* Margen superior automático para empujarlo al final */
}

.boton:hover {
  background-color: #0056b3;
}

/* Estilos para los botones en la cabecera */
.header-buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
}

.header-button {
  background-color: #7c7c7c;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  margin-right: 10px;
  font-size: 0.9rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
}

.header-button:hover {
  background-color: #5a5c5f;
}

/* Media queries para adaptabilidad */
@media (max-width: 768px) {
  .delivery-list {
    max-width: 95%; /* Más ancho en dispositivos más pequeños */
  }
  
  .boton {
    padding: 8px 10px; /* Padding más pequeño para botones en dispositivos móviles */
    font-size: 0.8rem; /* Tamaño de fuente más pequeño para botones */
  }
  
  .header-button {
    padding: 8px 10px; /* Más pequeño para adaptarse mejor en pantallas pequeñas */
  }
}
