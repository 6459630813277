.pqr-container {
    margin-left: 270px;
    margin-right: 20px;
  }
  
  .pqr-title {
    text-align: center;
    margin-top: 2%;
  }
  
  .pqr-subtitle {
    text-align: left;
  }
  
  .pqr-button {
    background-color: #3F51B5;
    border-color: #3F51B5;
    color: white;
    font-size: 16px;
    height: 40px;
    padding: 0 20px;
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  
  .pqr-button:hover,
  .pqr-button:focus,
  .pqr-button:active {
    background-color: #303F9F;
    border-color: #303F9F;
    color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .pqr-export-button {
    margin-bottom: 20px;
    margin-right: 20px;
    background-color: #4CAF50;
    border-color: #4CAF50;
  }

  .pqr-export-button:hover {
    background-color: #45A049;
    border-color: #45A049;
  }
  
  .pqr-manage-button {
    margin-bottom: 20px;
  }
  
  .pqr-table {
    margin-top: 20px;
  }
  
  .pqr-filter-dropdown {
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .pqr-filter-input-container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .pqr-filter-input {
    margin-right: 8px;
  }
  
  .pqr-filter-search-button {
    padding: 0;
    width: auto;
    background-color: #3F51B5;
    border-color: #3F51B5;
    color: white;
  }
  
  .pqr-filter-reset-button {
    width: 100%;
    background-color: #3F51B5;
    border-color: #3F51B5;
    color: white;
  }
  
  .pqr-filter-search-button:hover,
  .pqr-filter-search-button:focus,
  .pqr-filter-search-button:active,
  .pqr-filter-reset-button:hover,
  .pqr-filter-reset-button:focus,
  .pqr-filter-reset-button:active {
    background-color: #303F9F;
    border-color: #303F9F;
    color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  /* Estilos específicos para el icono de la lupa */
  .pqr-filter-search-button .anticon {
    color: white;
  }
  
  @media (max-width: 768px) {
    .pqr-container {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .pqr-table .ant-btn-link {
    padding: 0;
    height: auto;
    font-size: 14px;
    color: #3F51B5;
  }

  .pqr-table .ant-btn-link:hover {
    color: #303F9F;
  }