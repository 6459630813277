.transaccion-pagos-container {
  margin-left: 270px;
  margin-right: 20px;
}

.transaccion-pagos-title {
  text-align: center;
  margin-top: 2%;
}

.transaccion-pagos-subtitle {
  text-align: left;
}

.transaccion-pagos-button {
  background-color: #3F51B5;
  border-color: #3F51B5;
  color: white;
  font-size: 16px;
  height: 40px;
  padding: 0 20px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.transaccion-pagos-button:hover,
.transaccion-pagos-button:focus,
.transaccion-pagos-button:active {
  background-color: #303F9F;
  border-color: #303F9F;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.transaccion-pagos-export-button {
  margin-bottom: 20px;
  margin-right: 20px;
}

.transaccion-pagos-table {
  margin-top: 20px;
}

.transaccion-pagos-filter-dropdown {
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.transaccion-pagos-filter-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.transaccion-pagos-filter-input {
  margin-right: 8px;
}

.transaccion-pagos-filter-search-button {
  padding: 0;
  width: auto;
  background-color: #3F51B5;
  border-color: #3F51B5;
  color: white;
}

.transaccion-pagos-filter-reset-button {
  width: 100%;
  background-color: #3F51B5;
  border-color: #3F51B5;
  color: white;
}

.transaccion-pagos-filter-search-button:hover,
.transaccion-pagos-filter-search-button:focus,
.transaccion-pagos-filter-search-button:active,
.transaccion-pagos-filter-reset-button:hover,
.transaccion-pagos-filter-reset-button:focus,
.transaccion-pagos-filter-reset-button:active {
  background-color: #303F9F;
  border-color: #303F9F;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.transaccion-pagos-filter-search-button .anticon {
  color: white;
}

@media (max-width: 768px) {
  .transaccion-pagos-container {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.custom-table .ant-table-thead > tr > th {
  background-color: #3f51b5;
  color: white;
  font-weight: bold;
  text-align: center;
}

.custom-table .ant-table-tbody > tr > td {
  text-align: center;
  padding: 12px;
}

.custom-table .ant-table-tbody > tr:hover > td {
  background-color: #f5f5f5;
}

.custom-table .ant-table-pagination.ant-pagination {
  margin: 16px 0;
}

.custom-table .ant-table-thead > tr > th.ant-table-column-sort {
  background-color: #303f9f;
}

.custom-table .ant-table-tbody > tr > td.ant-table-column-sort {
  background-color: #e8eaf6;
}