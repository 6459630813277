.table-containerC {
    width: 90%;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #ffffff;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
}

.ant-form-item {
    margin-bottom: 16px;
}

.inventory-input,
.inventory-select-selector,
.inventory-upload,
.inventory-checkbox-wrapper {
    border-radius: 6px;
    border: 1px solid #d9d9d9;
    padding: 8px 12px;
    transition: all 0.3s ease;
}

.inventory-input:hover,
.inventory-select-selector:hover,
.inventory-upload:hover {
    border-color: #4096ff;
}

.inventory-input:focus,
.inventory-select-focused .inventory-select-selector,
.inventory-checkbox-wrapper:hover {
    border-color: #1677ff;
    box-shadow: 0 0 0 2px rgba(22, 119, 255, 0.2);
}

.ant-btn-primary {
    background-color: #1677ff;
    border-color: #1677ff;
    border-radius: 6px;
    padding: 8px 16px;
    margin-bottom: 1.5rem;
    font-weight: 500;
    box-shadow: 0 2px 4px rgba(22, 119, 255, 0.2);
}

.ant-btn-primary:hover {
    background-color: #4096ff;
    border-color: #4096ff;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(22, 119, 255, 0.3);
    transition: all 0.2s ease;
}

.ant-btn-dashed {
    border-color: #1890ff;
    color: #1890ff;
    border-radius: 5px;
}

.ant-btn-dashed:hover {
    border-color: #40a9ff;
    color: #40a9ff;
}

.ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.ant-upload-list-item {
    border-radius: 8px;
}

.ant-checkbox + span {
    font-size: 16px;
    margin-left: 8px;
}

.ant-form-item-label > label {
    font-weight: 600;
    color: #262626;
    font-size: 14px;
}

.ant-form-horizontal .ant-form-item {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.ant-form-horizontal .ant-form-item-label {
    text-align: left;
    min-width: 100px;
    margin-right: 8px;
}

.ant-form-horizontal .ant-form-item-control {
    flex-grow: 1;
}

.variation-row {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
    background-color: #f5f5f5;
    padding: 16px;
    border-radius: 8px;
}

.variation-row .ant-input {
    width: 100px; /* Fija el ancho de los inputs de las variaciones */
    text-align: center;
}

.variation-row .ant-form-item-label {
    min-width: auto;
    margin-right: 4px;
    font-size: 14px; /* Ajusta el tamaño del texto para que ocupe menos espacio */
}

.variation-remove-button {
    font-size: 18px;
    color: #ff4d4f;
    cursor: pointer;
    transition: all 0.3s ease;
}

.variation-remove-button:hover {
    color: #ff7875;
    transform: scale(1.1);
}

.ant-btn-danger {
    background-color: #ff4d4f;
    border-color: #ff4d4f;
    border-radius: 5px;
}

.ant-btn-danger:hover {
    background-color: #ff7875;
    border-color: #ff7875;
}
 

.ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.ant-modal-content {
    border-radius: 12px;
    padding: 24px;
}

.ant-form-item {
    margin-bottom: 16px;
    width: 100%; /* Asegura que los campos ocupen todo el espacio disponible */
}

/* .ant-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
} */

.ant-col {
    flex: 1; /* Cada columna ocupa la misma cantidad de espacio */
    padding: 0 8px; /* Espaciado entre las columnas */
}

.variation-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    gap: 8px;
    width: 100%; /* Asegura que ocupe todo el ancho del modal */
}

.variation-row .ant-form-item {
    flex: 1;
    margin-bottom: 0;
}

.variation-remove-button {
    font-size: 20px;
    color: red;
    cursor: pointer;
}

/* Responsive para tablets */
@media (max-width: 768px) {
    .table-containerC {
        width: 90%;
        margin-top: 10%;
        margin-left: 5%;
    }

    .ant-col {
        flex: 1 1 100%; /* Hace que las columnas tomen el 100% del ancho en pantallas pequeñas */
        padding: 8px 4px;
    }

    .variation-row .ant-input {
        width: auto; /* Hace que los inputs dentro de las filas de variaciones sean flexibles */
    }
}

/* Responsive para móviles */
@media (max-width: 480px) {
    .table-containerC {
        width: 100%;
        margin-top: 12%;
        margin-left: 0;
    }

    .ant-modal-body {
        padding: 10px; /* Reduce el padding en dispositivos muy pequeños */
    }

    .ant-form-item {
        margin-bottom: 12px;
    }

    .ant-row, .ant-col {
        flex-direction: column; /* Coloca los elementos en columna */
    }

    .variation-row {
        flex-direction: column; /* Hace que las variaciones se apilen verticalmente */
    }

    .variation-row .ant-form-item-label {
        width: auto;
    }
}