/* Estilos base */
.content-wrapper {
    margin: auto;
    padding: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgb(241, 239, 239);
}
.tab-derecha {
    margin: 50px 10px;
    transition: all 50ms;
    padding: 5%;
    box-shadow: 0 4% 8% 0 rgba(0, 0, 0, 0.2);
    position: relative;
    display: inline-block;
    background-color: rgba(132, 139, 200, 0.18);
    transform: scale(1.05);
}

.tab-derecha:hover {
    transform: scale(1.07); /* Aumenta un poco más al pasar el mouse */
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3); /* Añade una sombra más pronunciada */
}


.tab-izquierda {
    margin: 50px 0;
    transition: all 50ms;
    padding: 5%;
    box-shadow: 0 4% 8% 0 rgba(0, 0, 0, 0.2);
    position: relative;
    display: inline-block;
    background-color: rgba(132, 139, 200, 0.18);
    transform: scale(1.05);
}

.tab-izquierda:hover {
    transform: scale(1.07); /* Aumenta un poco más al pasar el mouse */
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3); /* Añade una sombra más pronunciada */
}



.btn-registro {
    background-color: #6C63FF;
    border: none;
    color: white;
    padding: 10px 20px; 
    text-align: center; 
    text-decoration: none;
    display: inline-block; 
    font-size: 100%; 
    margin-top: 170px;
    cursor: pointer; 
    transition-duration: 0.4s; 
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 13px;
    display: block; 
}

.imagen-superpuestaRepartidor {
    position: absolute;
    top: 25px;
    left: 50%; 
    transform: translateX(-50%); 
    margin-top: -20px; 
    z-index: 1; 
    max-width: 120%;
    height: auto;
}

.imagen-superpuestaSucursal {
    position: absolute;
    top: 10px;
    left: 50%; 
    transform: translateX(-50%); 
    margin-top: -20px; 
    z-index: 1; 
    max-width: 90%;
    height: auto;
}
.btn-registro:hover {
    background-color: #6C63FF; 
    color: white; 
}

.icon-circleA {
    width: 50px; /* Ajusta el tamaño según sea necesario */
    height: 50px; /* Ajusta el tamaño según sea necesario */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%; /* Hace que el icono sea redondo */
    background-color: #f0f0f0; /* Cambia el color de fondo si lo deseas */
    margin: 0 auto; /* Centra el ícono dentro de la tarjeta */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Opcional: añade una sombra */
  }
  

@media only screen and (max-width: 600px) {
     
    .content-wrapper {
        padding: 4%;
        flex-direction: column;
        justify-content: center; /* Ensures vertical centering */
        align-items: center; /* Ensures horizontal centering */
        margin-top: 20px; /* Adds some space above the content */
    }

    .tab-derecha, .tab-izquierda {
        width: 80%; /* El ancho se ajusta al 100% del contenedor padre */
        margin: 15px 0;
        padding: 20px;
        box-shadow: 0 2% 4% 0 rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        align-items: center; /* Asegura que todo dentro de las tabs esté centrado */
        transform: none;
    }

    .btn-registro {
        padding: 12px 20px;
        margin-top: 15px;
        width: auto; /* El ancho se ajusta al contenido */
        height: 10%; /* Ajusta el alto según sea necesario */
        font-size: 16px; /* Tamaño de fuente legible */
        border-radius: 10px;
        order: 2; /* Coloca el botón después de la imagen superpuesta (aunque las imágenes estarán ocultas) */
    }

    .imagen-superpuestaRepartidor,
    .imagen-superpuestaSucursal {
        display: none; /* Las imágenes se ocultan en dispositivos móviles */
    }


    /* Ajuste para el formulario y sus elementos si es necesario */
    .formularioL {
        width: 90%; /* Asegura que el formulario no exceda el ancho del dispositivo */
        padding: 0; /* Remueve el padding si es necesario */
        margin-left: -11%;
    }

    /* Otros ajustes para elementos de formulario, texto, etc. */
    .titulo-card, .tab-derecha p, .tab-izquierda p, .ant-form-item-label label {
        font-size: 14px; /* Asegura que el texto es legible */
    }
}


.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
    color: #6C63FF;
}


.password-animation::before,
.password-animation::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: dotAnimation 1s infinite; /* Se aplica la animación */
}

.password-animation::before {
  left: 0;
}

.password-animation::after {
  right: 0;
}

/* Asegúrate de que la animación no afecte al ícono de visibilidad */
.ant-input-password .ant-input-suffix {
  position: relative;
  z-index: 2;
}

