.container {
  background-color: white;
  padding: 20px;
  max-width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.left-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15.6rem;
}

.right-section {
  display: flex;
  align-items: center;
}

.logo-navbar-comp {
  width: 60px;
  height: auto;
  margin: 0 auto 0;
}

.sidebar > .sidebar-item-nav:first-child {
  transition: background-color 0.3s ease;
  margin-top: 0;
}

.sidebar > .sidebar-item-nav:first-child:hover {
  background-color: #f8f9fa;
}

.dashboard-header {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}

.dashboard-title {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  font-size: 24px;
  font-weight: 600;
}

.greeting-container {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.greeting {
  display: none;
  margin-bottom: 4px;
  color: #4a5568;
  font-size: 0.875rem;
  margin-right: 10px;
}

.panel-link {
  color: #0056b3;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
}

.panel-link:hover {
  background-color: #d0d0d0;
}

.right-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.custom-button {
  background-color: #4299e1;
  color: white;
  padding: 8px 16px;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.custom-button:hover {
  background-color: #7380EC; 
}

.custom-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5);
}

/* Sidebar */
.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 250px;
  height: 100vh;
  background-color: #f8f9fa;
  padding: 20px;
  box-sizing: border-box;
  z-index: 1000;
  overflow-y: auto;
  flex-direction: column;
  transition: transform 0.3s ease-in-out;
}

.sidebar-item-nav {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  color: #333;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
  font-weight: 500;
  gap: 0.5rem;
  width: 100%;
  font-size: 15px;
  margin: 20px 0px 20px;
}

.sidebar-item-nav:hover {
  background-color: #3f51b5;
  color: white;
}

.sidebar-item-nav.active {
  background-color: #3f51b5;
  color: white;
}

.content {
  margin-left: 250px;
}

.hamburger {
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
}

/* Ajustes para el submenú */
.with-submenu {
  position: relative; /* Ya no es necesario que sea absolute */
}

.submenu {
  position: relative; /* Cambiar de absolute a relative */
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0.1px;
  padding-bottom: 0.1px;
  margin-top: 10px; /* Espaciado opcional entre el menú principal y el submenú */
  display: none; /* Oculta el submenú por defecto */
  left: 0;
  z-index: 1000;
}

.submenu.open {
  display: block; /* Mostrar el submenú cuando está abierto */
}

.sidebar-item-nav {
  cursor: pointer;
}

.submenu .sidebar-item-nav {
  display: block;
  color: #333;
  text-decoration: none;
  white-space: nowrap;
  transition: background-color 0.3s, color 0.3s;
}

.submenu .sidebar-item-nav:hover {
  background-color: #3f51b5;
  color: white;
}

.submenu .sidebar-item-nav.active {
  background-color: #3f51b5;
  color: white;
}

/* Responsive styles */
@media (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .sidebar {
    transform: translateX(-100%);
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .content {
    margin-left: 0;
  }

  .panel-link, .logout-link {
    display: none;
  }

  .submenu {
    position: static;
    box-shadow: none;
  }

  .submenu .sidebar-item-nav {
    padding-left: 30px;
  }

  .left-section {
    padding-left: 0;
  }
}

/* Show hidden elements on larger screens */
@media (min-width: 640px) {
  .dashboard-title {
    display: block;
  }
}

@media (min-width: 768px) {
  .greeting {
    display: block;
  }
}