:root {
    --color-background: #f8f9fa; /* Color de fondo para la barra lateral */
    --color-primary: #6c63ff; /* Color principal para botones y enlaces */
    --color-text: #495057; /* Color del texto */
    --color-border: #ced4da; /* Color de los bordes */
    --spacing-small: 0.5rem; /* Espaciado pequeño */
    --spacing-medium: 1rem; /* Espaciado medio */
    --spacing-large: 2rem; /* Espaciado grande */
  }
  
  .map {
    flex-grow: 1;
    height: 503px;
    width: 100%;
    overflow: hidden;
    margin-top: 5%;
  }
  
  .containerMap {
    position: relative;
    display: flex;
    margin-left: 15.5%;
    margin-top: 1%;
    flex-direction: row;
    max-width: 100%;
    height: 100%; /* Asumiendo que quieres que ocupe toda la altura de la vista */
  }
  
  /* Estilos de la barra lateral */
  .sidebarMap {
    position: absolute;
    top: 0;
    left: 0;
    width: 35%; /* Ajusta este valor según el ancho deseado */
    height: 100%; /* Ocupa toda la altura del contenedor */
    padding: 2rem;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05); /* Sombra suave para la elevación */
    background-color: #ffffff; /* Fondo blanco para la barra lateral */
    z-index: 2; /* Asegura que la barra lateral se sobreponga al mapa */
    overflow-y: auto; /* Permitir desplazamiento vertical si el contenido excede la altura */
    border-radius: 8px;
  }
  .sidebarMap h2 {
    color: var(--color-text);
    margin-bottom: var(--spacing-large);
  }
  
  .sidebarMap .button.add-order {
    background-color: var(--color-primary);
    color: #fff;
    border: none;
    border-radius: 0.25rem;
    padding: var(--spacing-small) var(--spacing-medium);
    margin-bottom: var(--spacing-medium);
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 1rem;
    /* Asegúrate de aplicar la clase 'add-order' en tu JSX para este botón */
  }
  
  .button-group .button {
    padding: 10px 20px; /* Espaciado interno */
    background-color: #ffffff; /* Fondo blanco para botones inactivos */
    color: #495057; /* Color de texto para botones inactivos */
    border: none; /* No hay bordes para los botones inactivos */
    outline: none; /* Elimina el contorno que aparece al hacer clic */
    cursor: pointer; /* Cambia el cursor a una mano al pasar sobre el botón */
    font-size: 16px; /* Tamaño de texto */
    margin: 0; /* No hay margen entre los botones */
    transition: background-color 0.3s; /* Transición suave para efectos de hover y active */
  }
  
  .button-group .button:hover {
    background-color: #e2e6ea; /* Color de fondo cuando se pasa el cursor sobre el botón */
  }
  /* Input de búsqueda */
  .sidebarMap input[type="text"] {
    width: 100%;
    padding: var(--spacing-small);
    box-sizing: border-box;
    margin-bottom: 1rem;
    border: 1px solid var(--color-border);
    border-radius: 0.25rem;
  }
  /* Botones de la barra lateral */
  .button-group button {
    background-color: var(--color-button);
    border: none;
    padding: var(--spacing-small) var(--spacing-medium);
    margin-right: var(--spacing-small);
    border-radius: var(--spacing-small);
    cursor: pointer;
    color: var(--color-button-text);
  }
  .button-group {
    display: flex;
    justify-content: space-between; /* Esto distribuirá los botones de manera equitativa */
  }
  
  .button-group button.active {
    background-color: var(--color-button-active);
    color: var(--color-button-text-active);
  }
  .status-messages {
    text-align: center; /* Centra todos los elementos dentro del contenedor */
    position: relative; /* Para la posición absoluta del logo de mapa */
    padding-top: 60px; /* Espacio para el logo de mapa */
  }
  
  .no-orders {
    font-size: 1.5rem; /* Tamaño de fuente más grande para el mensaje */
    margin: 20px 0; /* Espacio arriba y abajo del mensaje */
  }
  
  .button-group .button.active {
    background-color: #e9ecef; /* Fondo gris claro para el botón activo */
    color: #6c63ff; /* Color de texto azul para el botón activo */
    border-bottom: 3px solid #6c63ff; /* Borde superior azul para el botón activo */
    border-radius: 0; /* Sin bordes redondeados */
  }
  
  .sidebarMap > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .button-group .button:last-child {
    margin-right: 0; /* El último botón no necesita margen a la derecha */
  }
  .template-link {
    background: none; /* Sin fondo */
    border: none; /* Sin bordes */
    color: #6c63ff; /* Color azul para el texto del enlace, ajusta según sea necesario */
    padding: 0; /* Sin relleno */
    margin-top: var(
      --spacing-small
    ); /* Mantén algo de espacio encima del texto */
    cursor: pointer; /* Indica que es clickeable */
    text-decoration: underline; /* Subraya para indicar que es un enlace */
    font-size: 1rem; /* Tamaño de fuente estándar */
    line-height: 1.5; /* Espaciado de línea para que no esté demasiado apretado */
    display: inline; /* Para que tome el tamaño de su contenido */
    margin-bottom: var(--spacing-large); /* Espacio debajo del texto */
  }
  
  /* Mensajes y enlaces */
  .no-orders,
  .import-link,
  .template-link {
    margin-bottom: var(--spacing-small);
  }
  
  .map-icon {
    position: absolute; /* Posiciona el logo respecto a su contenedor */
    top: 0; /* En la parte superior del contenedor */
    left: 50%; /* Centrado horizontalmente */
    transform: translateX(-50%); /* Alineación precisa horizontalmente */
    width: 50px; /* Ancho del logo, ajustar según sea necesario */
    height: 50px; /* Altura del logo, mantener proporcional al ancho */
  }
  
  .import-link {
    /* Asume que import-link es una clase aplicada al botón o enlace "Importar pedidos" */
    background-color: #6c63ff; /* Color azul como el botón "Asignar" */
    color: #050505; /* Texto blanco para contraste */
    border: none;
    border-radius: 0.25rem; /* Bordes redondeados levemente */
    padding: var(--spacing-small) var(--spacing-medium);
    text-align: center;
    display: block; /* Hacer que el enlace llene el ancho de su contenedor */
    margin-top: var(--spacing-medium); /* Espacio encima del botón */
    cursor: pointer;
    text-decoration: none; /* Si es un enlace, quitar el subrayado */
  }
  
  .import-link,
  .template-link {
    /* Estilos para que parezcan botones o enlaces clicables */
  
    padding: var(--spacing-small) var(--spacing-medium);
    border-radius: var(--spacing-small);
    border: 1px solid var(--color-border);
    margin: var(--spacing-small) 0; /* Espacio vertical entre ellos */
    cursor: pointer;
    text-decoration: none; /* Quitar subrayado si son enlaces */
  }
  
  /* Estilos para el botón de asignación */
  .assign-button {
    position: absolute;
    bottom: 1%;
    right: 20px;
    padding: 10px 24px;
    background-color: #6c63ff;
    margin-top: var(--spacing-medium);
    border: none;
    border-radius: var(--spacing-small);
    cursor: pointer;
    color: var(--color-button-text);
    border: 1px solid var(--color-border); /* Añade un borde si es necesario */
  }
  .assign-button {
    margin: 0;
  }
  
  .import-link,
  .template-link {
    display: inline-block;
    border: 1px solid var(--color-border);
    border-radius: 0.25rem;
    padding: var(--spacing-small) var(--spacing-medium);
    text-align: center;
    margin-bottom: var(--spacing-small);
    /* Añade cursor pointer si deseas que parezcan clicables */
    cursor: pointer;
  }
  
  .button-group .button:not(.active) {
    margin-top: 3px; /* Alinea verticalmente con el botón activo */
  }
  
  /* Estilo del navbar*/
  
  .navbarMap {
    position: absolute;
    top: 0; /* Posiciona en la parte superior del contenedor */
    left: 0;
    z-index: 10; /* Asegura que la barra de navegación se sitúe sobre otros elementos */
    width: 100%; /* Ocupa el ancho completo */
    display: flex; /* Utiliza flexbox para organizar los elementos internos */
    justify-content: flex-end; /* Alinea los elementos al final (derecha) */
    padding-right: 1rem; /* Añade un espaciado a la derecha */
    padding: 1rem;
    margin-top: -1%;
    background-color: #fff;
    border-bottom: 1px solid #e2e2e2;
    border-radius: 6px;
  }
  
  .navbar-items {
    display: flex;
    align-items: center; /* Centrar los elementos verticalmente */
    background-color: #6c63ff; /* Color de fondo de la barra */
    color: white;
    padding: 0.5rem 1rem; /* Ajusta el espaciado interno */
    margin: 0 0.25rem; 
    border-radius: 0.25rem; /* Ajusta para bordes redondeados */
    cursor: pointer; /* Para que el usuario sepa que puede hacer clic */
    transition: background-color 0.3s; /* Transición suave para el hover */
    margin-left: -3%;
    padding-bottom: 0px;
  }
  
  .navbar-item {
    padding: 0.5rem 1rem; /* Espaciado interno */
    color: black; /* Color de texto predeterminado */
    /* ... otros estilos ... */
  }
  
  .navbar-item.active {
    background-color: #6c63ff; /* Color de fondo para el ítem activo */
    border-bottom: 3px solid white;
    color: white;
  }
  
  .navbar-item:hover {
    background-color: #6c63ff; /* Color ligeramente más claro para el hover */
  }
  
  .navbar-date {
    margin-right: 24%; /* Empuja los items de la navbar a la derecha */
    left: 1rem; /* Alinea a la izquierda dentro del navbar */
    color: rgb(14, 13, 13); /* Asegúrate de que el color del texto sea visible contra el fondo */
  }


  /* Estilos para el dropdown */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-top: 10px; /* Ajusta el margen superior del dropdown según sea necesario */
}

.dropdown-content button {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    background-color: #fff;
    border: none;
    width: 100%;
    text-align: left;
}

.dropdown-content button:hover {
    background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.button.add-order {
    background-color: var(--color-primary);
    color: #fff;
    border: none;
    border-radius: 0.25rem;
    padding: var(--spacing-small) var(--spacing-medium);
    margin-bottom: var(--spacing-medium);
    /* position: absolute; */ /* Elimina la posición absoluta para mantener la ubicación del botón */
    /* top: 0;
    right: 0; */
    /* margin-top: 1rem; */
    /* Asegúrate de aplicar la clase 'add-order' en tu JSX para este botón */
}

/* Estilos para el dropdown */
.dropdown {
  position: static; 
  display: inline-flex
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  margin-top: 10px; /* Ajusta el margen superior del dropdown según sea necesario */
}

.dropdown-content button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background-color: #fff;
  border: none;
  width: 100%;
  text-align: left;
}

.dropdown-content button:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Estilos para el botón "Agregar Pedido" */
.button.add-order {
  background-color: var(--color-primary);
  color: #fff;
  border: none;
  border-radius: 0.25rem;
  padding: var(--spacing-small) var(--spacing-medium);
  margin-bottom: var(--spacing-medium);
  cursor: pointer; /* Cambia el cursor al pasar sobre el botón */
}

/* Ajusta la posición del dropdown */
.dropdown-content {
  right: 0;
  margin-top: 0.5rem;
}

.edit-order-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.edit-order-form label {
  font-weight: bold;
  margin-bottom: 5px;
}

.edit-order-form input[type="text"],
.edit-order-form input[type="email"],
.edit-order-form input[type="tel"],
.edit-order-form input[type="time"],
.edit-order-form input[type="number"],
.edit-order-form select,
.edit-order-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-sizing: border-box;
  font-size: 16px;
  transition: box-shadow 0.2s ease;
}

.edit-order-form textarea {
  height: 100px;
}

.edit-order-form .time-window {
  display: flex;
  gap: 10px;
  align-items: center;
}

.edit-order-form .form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.edit-order-form button {
  cursor: pointer;
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  background-color: #5c6bc0;
  color: white;
  text-transform: uppercase;
  transition: background-color 0.3s, transform 0.2s ease;
}

.btnp{
  cursor: pointer;
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  background-color: #1f1f20;
  color: white;
  text-transform: uppercase;
  transition: background-color 0.3s, transform 0.2s ease;
}

.edit-order-form button:hover,
.btnp:hover {
  background-color: #3f51b5;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transform: scale(1.02);
}

.edit-order-form .form-field {
  display: flex;
  flex-direction: column;
}

.edit-order-form .form-field label {
  margin-bottom: 5px;
}

.edit-order-form .form-field input,
.edit-order-form .form-field select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  transition: box-shadow 0.2s ease;
}

/* Estilo del botón para guardar cambios */
.edit-order-form button[type="submit"] {
  background-color: #4CAF50; /* Verde */
  color: white;
}

/* Estilo para botones de cancelar y guardar en acciones del formulario */
.edit-order-form .form-actions button {
  background-color: #7380EC; /* Rojo */
  color: white;
  border: none;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.edit-order-form .form-actions button:hover {
  background-color: #4CAF50;
  color: black;
  border: 1px solid #3cff00; /* Rojo */
}

.visitas-container {
  background: linear-gradient(to bottom right, #fafaff, #f4f4fa);
  padding: 2rem;
  border-radius: 12px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.visitas-container:hover {
  transform: scale(1.01);
  box-shadow: 0 4px 12px rgba(0,0,0,0.08);
}

.inputs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.autocomplete-input {
  width: 300px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.route-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.route-button:hover {
  background-color: #45a049;
}

.order-details {
  margin-top: 20px;
  text-align: center;
}

.visitas-header {
  text-align: center;
  padding: 1rem;
  color: #6c63ff;
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 1rem;
  background: #f9f9fc;
  border-radius: 8px;
}

@media (max-width: 480px) {
  .containerMap {
      flex-direction: column;
      margin-left: 0;
      margin-top: 0;
  }

  .sidebarMap {
      position: relative;
      width: 100%;
      height: auto;
      padding: 10px;
      box-shadow: none;
  }

  .navbarMap {
      flex-direction: column;
      align-items: flex-start;
  }

  .navbar-items {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
  }

  .map {
      height: 300px; /* Ajustar la altura del mapa para pantallas móviles */
      margin-top: 0;
  }

  .status-messages {
      padding-top: 20px;
  }

  .navbar-date {
      margin-right: 0;
      margin-bottom: 10px;
  }

  .navbar-item {
      width: 100%;
      text-align: left;
      padding: 10px 0;
      border-bottom: 1px solid #ddd;
  }

  .navbar-item.active {
      border-bottom: 3px solid #6c63ff;
  }
}