.whatsapp-admin-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
}

.whatsapp-values-card {
  background: white;
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.whatsapp-values-card h2 {
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.input-group {
  margin: 0;
}

.input-group label {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
  margin-bottom: 8px;
}

.input-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.update-button {
  width: 100%;
  padding: 15px;
  background: linear-gradient(135deg, #25d366 0%, #128c7e 100%);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.update-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(37, 211, 102, 0.2);
}

.update-button:disabled {
  background: #cccccc;
  transform: none;
  cursor: not-allowed;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #25d366;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

.error-message {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  animation: slideIn 0.3s ease;
  background-color: #fff3f3;
  color: #dc3545;
  border-left: 4px solid #dc3545;
}

.success-message {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  animation: slideIn 0.3s ease;
  background-color: #f0fff0;
  color: #28a745;
  border-left: 4px solid #28a745;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.whatsapp-dashboard {
  display: grid;
  gap: 20px;
  margin-top: 20px;
}

.whatsapp-stats-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.whatsapp-stat-card {
  background: linear-gradient(135deg, #25d366 0%, #128c7e 100%);
  color: white;
  padding: 20px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.whatsapp-stat-card:hover {
  transform: translateY(-5px);
}

.whatsapp-stat-card i {
  font-size: 40px;
  opacity: 0.9;
}

.stat-info h3 {
  margin: 0;
  font-size: 0.9rem;
  opacity: 0.9;
}

.stat-value {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 5px 0 0;
}

.card-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 25px;
}

.card-header i {
  font-size: 28px;
  color: #128c7e;
}

.card-header h2 {
  margin: 0;
  color: #333;
  font-size: 1.5rem;
}

.input-groups-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 25px;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.currency-symbol {
  position: absolute;
  left: 10px;
  color: #666;
  margin-left: 15%;
}

.input-wrapper input {
  width: 100%;
  padding: 12px;
  padding-left: 25px;
  border: 2px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  margin-left: 15%;
}

.input-wrapper input:focus {
  border-color: #25d366;
  outline: none;
}

.whatsapp-info-card {
  background: white;
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.whatsapp-info-card ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.whatsapp-info-card li {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
  color: #666;
}

.whatsapp-info-card li i {
  color: #25d366;
}

@media (max-width: 768px) {
  .input-groups-container {
    grid-template-columns: 1fr;
  }
  
  .whatsapp-stats-container {
    grid-template-columns: 1fr;
  }

  .input-wrapper {
    width: 100%;
    position: relative;
  }

  .input-wrapper input {
    margin-left: 0;
    width: 100%;
    padding-left: 25px; /* Espacio para el símbolo $ */
  }

  .currency-symbol {
    margin-left: 0;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  .card-header h2 {
    font-size: 1.2rem;
  }

  .whatsapp-values-card {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .whatsapp-admin-container {
    padding: 10px;
  }

  .whatsapp-stat-card {
    flex-direction: column;
    text-align: center;
    padding: 15px 10px;
  }

  .stat-info {
    width: 100%;
  }

  .stat-value {
    font-size: 1.3rem;
  }

  .card-header {
    flex-direction: column;
    text-align: center;
    gap: 5px;
  }

  .update-button {
    padding: 12px;
    font-size: 14px;
  }

  .error-message,
  .success-message {
    padding: 10px;
    font-size: 14px;
  }

  .input-group label {
    font-size: 14px;
  }

  .input-wrapper input {
    font-size: 14px;
    padding: 8px;
    padding-left: 20px;
  }

  .currency-symbol {
    left: 8px;
  }

  .whatsapp-info-card li {
    font-size: 13px;
  }
}

/* Nuevas reglas responsive */
@media (max-width: 1200px) {
  .whatsapp-admin-container {
    max-width: 95%;
    padding: 15px;
  }
}

@media (max-width: 992px) {
  .whatsapp-stats-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .whatsapp-stat-card {
    padding: 15px;
  }

  .stat-value {
    font-size: 1.5rem;
  }
}

@media (max-width: 768px) {
  .whatsapp-stats-container {
    grid-template-columns: 1fr;
  }

  .input-groups-container {
    grid-template-columns: 1fr;
  }

  .input-wrapper {
    width: 100%;
  }

  .input-wrapper input {
    margin-left: 0;
    width: 100%;
  }

  .currency-symbol {
    margin-left: 10px;
  }

  .card-header h2 {
    font-size: 1.2rem;
  }

  .whatsapp-values-card {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .whatsapp-admin-container {
    padding: 10px;
  }

  .whatsapp-stat-card {
    flex-direction: column;
    text-align: center;
    padding: 15px 10px;
  }

  .stat-info {
    width: 100%;
  }

  .stat-value {
    font-size: 1.3rem;
  }

  .card-header {
    flex-direction: column;
    text-align: center;
    gap: 5px;
  }

  .update-button {
    padding: 12px;
    font-size: 14px;
  }

  .error-message,
  .success-message {
    padding: 10px;
    font-size: 14px;
  }

  .input-group label {
    font-size: 14px;
  }

  .input-wrapper input {
    font-size: 14px;
    padding: 8px;
    padding-left: 20px;
  }

  .currency-symbol {
    left: -3px;
  }

  .whatsapp-info-card li {
    font-size: 13px;
  }
}

/* Ajustes adicionales para pantallas muy pequeñas */
@media (max-width: 480px) {
  .input-wrapper input {
    padding-left: 20px;
  }

  .currency-symbol {
    left: -3px;
  }
}

/* Ajuste específico para dispositivos plegables y pantallas muy pequeñas */
@media (max-width: 280px) {
  .whatsapp-admin-container {
    padding: 5px;
  }

  .whatsapp-stat-card i {
    font-size: 30px;
  }

  .stat-value {
    font-size: 1.1rem;
  }

  .input-wrapper input {
    padding-left: 16px;
  }

  .currency-symbol {
    left: 6px;
    font-size: 12px;
  }

  .input-wrapper input {
    padding: 6px;
    padding-left: 18px;
    font-size: 13px;
  }
}

/* Mejoras de accesibilidad táctil */
@media (hover: none) and (pointer: coarse) {
  .input-wrapper input,
  .update-button {
    min-height: 44px; /* Mejor área táctil */
  }

  .whatsapp-info-card li {
    padding: 12px 0;
  }
}

/* Soporte para pantallas de alta resolución */
@media (min-width: 1400px) {
  .whatsapp-admin-container {
    max-width: 1028px;
  }

  .whatsapp-stats-container {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

/* Soporte para modo landscape en móviles */
@media (max-height: 500px) and (orientation: landscape) {
  .whatsapp-dashboard {
    gap: 10px;
  }

  .whatsapp-stat-card {
    padding: 10px;
  }

  .whatsapp-values-card,
  .whatsapp-info-card {
    padding: 15px;
  }
}

/* Soporte para dispositivos plegables */
@media (max-width: 280px) {
  .whatsapp-admin-container {
    padding: 5px;
  }

  .whatsapp-stat-card i {
    font-size: 30px;
  }

  .stat-value {
    font-size: 1.1rem;
  }

  .input-wrapper input {
    padding: 6px;
    padding-left: 18px;
    font-size: 13px;
  }
}

/* Mejoras de contraste y legibilidad */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
  }
}

/* Optimizaciones para pantallas de PC pequeñas y medianas */
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .whatsapp-admin-container {
    max-width: 90%;
    padding: 15px;
  }

  .whatsapp-dashboard {
    gap: 15px;
  }

  .whatsapp-stats-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .whatsapp-stat-card {
    padding: 15px;
  }

  .stat-value {
    font-size: 1.4rem;
  }

  .input-groups-container {
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }

  .input-wrapper input {
    margin-left: 8%;
  }

  .currency-symbol {
    margin-left: 8%;
  }
}

/* Pantallas de PC pequeñas (1024px - 1280px) */
@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .whatsapp-admin-container {
    max-width: 95%;
    padding: 20px;
  }

  .whatsapp-dashboard {
    gap: 20px;
  }

  .whatsapp-values-card,
  .whatsapp-info-card {
    padding: 20px;
  }

  .input-wrapper input {
    margin-left: 10%;
  }

  .currency-symbol {
    margin-left: 10%;
  }
}

/* Ajustes específicos para resoluciones comunes de laptops */
@media screen and (min-width: 1280px) and (max-width: 1366px) {
  .whatsapp-admin-container {
    max-width: 1200px;
  }

  .whatsapp-stats-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .stat-value {
    font-size: 1.6rem;
  }
}

/* Ajustes para monitores con altura reducida */
@media screen and (min-height: 600px) and (max-height: 800px) {
  .whatsapp-dashboard {
    gap: 15px;
  }

  .whatsapp-values-card,
  .whatsapp-info-card {
    padding: 15px;
  }

  .card-header {
    margin-bottom: 15px;
  }

  .input-groups-container {
    margin-bottom: 15px;
  }

  .whatsapp-info-card li {
    padding: 8px 0;
  }
}

/* Optimizaciones para pantallas de 4:3 */
@media screen and (min-width: 1024px) and (max-width: 1280px) and (aspect-ratio: 4/3) {
  .whatsapp-stats-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .input-groups-container {
    grid-template-columns: 1fr 1fr;
  }

  .whatsapp-info-card {
    margin-top: 15px;
  }
}

/* Ajustes para pantallas con baja resolución pero proporción 16:9 */
@media screen and (min-width: 1024px) and (max-width: 1366px) and (aspect-ratio: 16/9) {
  .whatsapp-admin-container {
    padding: 15px;
  }

  .input-wrapper input {
    padding: 10px;
    padding-left: 25px;
  }

  .update-button {
    padding: 12px;
  }

  .whatsapp-stat-card {
    padding: 15px;
  }
}

/* Optimizaciones para pantallas con DPI alto */
@media screen and (min-resolution: 120dpi) and (max-width: 1280px) {
  .whatsapp-stat-card i {
    font-size: 32px;
  }

  .card-header i {
    font-size: 24px;
  }

  .input-group label {
    font-size: 14px;
  }

  .input-wrapper input {
    font-size: 14px;
  }
}
