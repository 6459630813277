/* Navbar.css */
body {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .navbara {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    color: #000;
    padding: 10px 20px;
    position: fixed;
    width: 100%;
    z-index: 1000;
  }
  
  .navbara-logo {
    color: rgb(0, 0, 0);
    font-size: 20px;
    font-weight: bold;
  }
  
  .navbara-toggle {
    display: none;
    color: rgb(0, 0, 0);
    font-size: 24px;
    cursor: pointer;
  }
  
  .navbara-menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .navbara-item {
    margin-left: 20px;
  }
  
  .navbara-link {
    color: rgb(0, 0, 0);
    text-decoration: none;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 5px 10px; /* Add padding for better spacing */
    transition: background-color 0.3s ease, border-radius 0.3s ease; /* Smooth transition for hover effects */
  }
  
  .navbara-link:hover {
    color: #000000;
    background-color: #ad8888; /* Add a background color on hover */
    border-radius: 10px; /* Round the corners on hover */
  }
  
  .navbara-icon {
    margin-right: 8px;
    color: rgb(92, 79, 79); /* Change the icon color to white */
  }
  
  @media (max-width: 768px) {
    .navbara-toggle {
      display: block;
    }
  
    .navbara-menu {
      flex-direction: column;
      width: 100%;
      background-color: #333;
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      height: 0;
      overflow: hidden;
      transition: height 0.3s ease;
    }
  
    .navbara-menu.open {
      height: calc(100vh - 60px);
    }
  
    .navbara-item {
      margin: 10px 0;
      text-align: center;
    }
  
    .navbara-link {
      width: 100%;
      padding: 10px 0;
    }
  }
  