/* UnauthorizedPage.css */

.unauthorized-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f8f9fa;
    color: #212529;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .unauthorized-header {
    font-size: 6em;
    margin-bottom: 0.5em;
  }
  
  .unauthorized-text {
    font-size: 1.5em;
    margin-bottom: 1em;
  }
  
  .unauthorized-link {
    display: inline-block;
    padding: 10px 30px;
    font-size: 1em;
    text-decoration: none;
    background-color: #28a745;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    transition: background-color 0.2s ease-in-out;
  }
  
  .unauthorized-link:hover {
    background-color: #218838;
  }
  