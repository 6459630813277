.euser-liminar-guia-tracking-container-container {
  margin-top: 20px;
  margin-left: 265px;
  margin-right: 20px;
}

.user-eliminar-guia-tracking-container {
  text-align: center;
  padding: 40px;
  background-color: #e9e6e6;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 550px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

/* Barra de búsqueda y sus elementos */
.user-eliminar-guia-search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.user-eliminar-guia-search-input {
  width: 250px;
  padding: 10px;
  border: 1px solid #ddd;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.user-eliminar-guia-search-input:focus {
  border-color: #3f51b5;
  box-shadow: 0 0 10px rgba(63, 81, 181, 0.2);
}

.user-eliminar-guia-carrier-select {
  width: 180px;
  padding: 10px;
  border-radius: 0;
  border: 1px solid #ddd;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.user-eliminar-guia-carrier-select:focus {
  border-color: #3f51b5;
  box-shadow: 0 0 10px rgba(63, 81, 181, 0.2);
}

.user-eliminar-guia-search-button {
  background-color: #3f51b5;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  margin-top: -30px;
  height: 45px;
}

.user-eliminar-guia-search-button:hover {
  background-color: #303f9f;
}

.user-eliminar-guia-search-button .user-eliminar-guia-material-icons {
  font-size: 24px;
}

.user-eliminar-guia-tracking-info {
  margin-top: 20px;
}

.user-eliminar-guia-tracking-image {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
}

.user-eliminar-guia-error-message {
  color: red;
  font-weight: bold;
}

.user-eliminar-guia-tracking-results {
  text-align: left;
  margin-top: 20px;
}

.user-eliminar-guia-tracking-results p {
  margin: 5px 0;
}

.user-eliminar-guia-tracking-results ul {
  list-style-type: none;
  padding: 0;
}

.user-eliminar-guia-tracking-results li {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

/* Media Queries para pantallas más pequeñas */
@media (max-width: 768px) {
  .user-eliminar-guia-tracking-container-container {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .user-eliminar-guia-tracking-container {
    padding: 20px;
  }

  .user-eliminar-guia-search-bar {
    flex-direction: column; /* Apilar elementos en columna */
    gap: 10px; /* Separación entre los elementos */
  }

  .user-eliminar-guia-search-input {
    width: 100%; /* Ocupa todo el ancho disponible */
    font-size: 14px;
  }

  .user-eliminar-guia-carrier-select {
    width: 100%; /* Ocupa todo el ancho disponible */
    font-size: 14px;
    margin-top: -20px;
  }

  .user-eliminar-guia-search-button {
    font-size: 16px;
  }
}

/* Media Queries para tablets (max-width: 1024px) */
@media (max-width: 1024px) {
  .user-eliminar-guia-tracking-container {
    padding: 30px;
    max-width: 600px; /* Ajustar ancho máximo */
  }

  .user-eliminar-guia-search-input {
    width: 220px;
    font-size: 15px;
  }

  .user-eliminar-guia-carrier-select {
    width: 160px;
    font-size: 15px;
  }

  .user-eliminar-guia-search-button {
    font-size: 16px;
    padding: 10px 18px;
  }
}
