/* NavbarDropshipping.css */
.NavbarDropshipping-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #ffffff;
    color: black;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: fixed; /* Cambiado a fixed */
    top: 0;
    left: 0;
    right: 0;
    z-index: 1001; /* Asegurarnos que esté por encima de todo */
    height: 60px; /* Altura fija para el navbar superior */
}

.NavbarDropshipping-tittle {
    flex-grow: 1;
    display: flex;
    align-items: center;
    margin-left: 250px;
}

.NavbarDropshipping-tittle p {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin: 0;
}

.NavbarDropshipping-logo {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.NavbarDropshipping-logo p {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin: 0;
}

.NavbarDropshipping-userInfo {
    display: flex;
    align-items: center;
}

.NavbarDropshipping-userInfo p {
    margin: 0 10px;
}

.NavbarDropshipping-panelLink {
    margin-right: 15px;
    text-decoration: underline;
    cursor: pointer;
    color: black;
}

.NavbarDropshipping-userAvatar img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.NavbarDropshipping-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8f9fa;
    padding: 20px;
    width: 250px;
    height: 100vh;
    overflow-y: auto;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000; /* Un nivel menos que el top navbar */
    transition: transform 0.3s ease-in-out; /* Add smooth transition */
    padding-top: 80px; /* Aumentar el padding-top para evitar que el contenido quede debajo del navbar */
}

.NavbarDropshipping-container::-webkit-scrollbar {
    width: 8px;
}

.NavbarDropshipping-container::-webkit-scrollbar-track {
    background: #f8f9fa;
}

.NavbarDropshipping-container::-webkit-scrollbar-thumb {
    background-color: #3f51b5;
    border-radius: 20px;
    border: 3px solid #f8f9fa;
}

.NavbarDropshipping-title {
    margin-bottom: 20px;
}

.NavbarDropshipping-logo {
    width: 60px;
    margin-bottom: 20px;
    height: auto;
}

.NavbarDropshipping-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.NavbarDropshipping-menu li {
    margin-bottom: 15px;
}

.NavbarDropshipping-menu a {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    text-decoration: none;
    color: #333;
    border-radius: 8px;
    transition: background-color 0.3s, color 0.3s;
}

.NavbarDropshipping-menu a .material-icons {
    margin-right: 10px;
}

.NavbarDropshipping-menu a:hover,
.NavbarDropshipping-menu a.NavbarDropshipping-active {
    background-color: #3f51b5;
    color: white;
}

.NavbarDropshipping-active {
    background-color: #3f51b5;
    color: white;
}

.content-container {
    margin-left: 250px; /* El mismo ancho que la barra de navegación */
    padding: 20px;
    transition: margin-left 0.3s ease-in-out; /* Add smooth transition */
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
  position: absolute;
  left: 20px;
}

.hamburger-menu div {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 5px;
  transition: 0.3s;
}

@media (max-width: 768px) {
    .NavbarDropshipping-container {
        transform: translateX(-100%);
        padding-top: 70px; /* Ajustar para móviles */
    }
    
    .NavbarDropshipping-top {
        justify-content: space-between;
        width: 100%;
        padding-left: 60px;
        height: 50px; /* Altura más pequeña en móviles */
    }
    
    .NavbarDropshipping-logo {
        justify-content: center;
    }

    .NavbarDropshipping-tittle {
        justify-content: center;
        margin-left: 0;
    }

    .NavbarDropshipping-top .NavbarDropshipping-userInfo {
        display: none;
    }

    .hamburger-menu {
        display: flex;
    }
    
    .close-btn {
        display: block; /* Mostrar el botón de cierre en móviles */
    }
    
    .content-container {
        margin-left: 0;
        padding: 20px;
    }
    
    .NavbarDropshipping-container.show {
        transform: translateX(0);
    }
    
    .NavbarDropshipping-container .NavbarDropshipping-userInfo {
        display: flex;
        margin-top: 20px;
    }
}