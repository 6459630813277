.enviosDrop {
  display: grid;
  grid-template-columns: 30% 20% 50%;
  border: 1px solid rgba(128,128,128, 0.5);
  border-radius: 10px;
  padding: 2rem;
  position: relative;
  height: 90vh;
  margin-top: -5%;
  margin-bottom: 1%;
  overflow-y: auto;
}

.pdfShopy{
  margin-top: 2%;
  margin-left: 18%;
}

.enviosDrop div {
  width: 100%;
}

.close-modal-btn {
  position: absolute;
  top: 10px;  /* Ajusta esta distancia según lo necesario */
  right: 10px;  /* Ajusta esta distancia según lo necesario */
  background-color: transparent;  /* Fondo transparente para que no tape el contenido */
  border: none;  /* Sin borde */
  cursor: pointer;  /* Cambia el cursor al pasar sobre el botón */
  z-index: 1100;  /* Asegura que el botón esté por encima de otros elementos */
}

.close-modal-btn svg {
  width: 24px; /* Tamaño del ícono */
  height: 24px; /* Tamaño del ícono */
  color: #000; /* Color del ícono */
}

.modal-content {
  padding: 20px;
  padding-top: 60px; /* Deja espacio para el botón de cerrar */
  position: relative;
}

.transportadora{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  margin: 0 1rem;
  border-left: 1px solid rgba(128,128,128, 0.5);
}

.transportadora .flete{
  border-top-right-radius:15%;
  border-bottom-left-radius: 15%;
  width: 8rem;
  height: 8rem;
  padding: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.transportadora img{
  width: 60%;
  height: 60%;
}
.pedidoEnvio{
  padding: 0 1rem;
  margin: 0 1rem;
  border-left: 1px solid rgba(128,128,128, 0.5);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.order-summary {
  max-width: 600px;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  background-color: #f7f7f7;
  padding: 20px;
  margin: 20px auto;
  font-family: Arial, sans-serif;
}
  
.order-summary-header {
  height: 30px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #eaeaea;
  font-weight: bold;
  font-size: 16px;
  padding: 5px;
}
  
.order-summary-content {
  padding: 15px 0;
}

.order-summary-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;
}

.item-description {
  width: 70%;
  height: auto;
  border-radius: 5px;
}

.item-price {
  width: 20%;
  height: auto;
  text-align: right;
}

.order-summary-total {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  border-top: 1px solid #eaeaea;
  margin-top: 20px;
  font-size: 14px;
  font-weight: bold;
}

.total-label {
  width: 70%;
  height: auto;
}

.total-amount {
  width: 20%;
  height: auto;
  text-align: right;
}

.order-summary-footer {
  height: 30px;
  background-color: #f0f0f0;
  border-top: 1px solid #eaeaea;
  margin-top: 20px;
}

.cart-product-item {
  padding: 15px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  margin-bottom: 10px;
  background: #f8f9fa;
}

.cart-product-item p {
  margin: 5px 0;
}

/* Estilos para el aviso de múltiples proveedores */
.multi-provider-alert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.alert-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.alert-content h4 {
  color: #f5a623;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.alert-content p {
  margin-bottom: 0.8rem;
  color: #4a4a4a;
  line-height: 1.4;
}

.alert-understand-btn {
  background: #4035df;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 4px;
  margin-top: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  width: 100%;
  max-width: 200px;
}

.alert-understand-btn:hover {
  background: #332bb0;
  transform: translateY(-1px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Media queries para el aviso */
@media (max-width: 768px) {
  .alert-content {
    width: 95%;
    padding: 1.5rem;
    margin: 1rem;
  }

  .alert-content h4 {
    font-size: 1.1rem;
  }

  .alert-content p {
    font-size: 0.9rem;
  }

  .alert-understand-btn {
    padding: 0.6rem 1.2rem;
  }
}

/* Media Queries para Responsive Design */
@media (width <= 1024px) {
  .modal-overlay {
    margin: 0 1rem;
  }
  .enviosDrop {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .pedidoEnvio, .transportadora {
    margin: 0;
    padding: 0;
    border-left: none;
  }
  .order-summary {
    max-width: 100%;
  }
  .transportadora {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .transportadora .flete {
    width: calc(25% - 1rem);
    height: auto;
    aspect-ratio: 1 / 1;
  }
}

@media (width <= 768px) {
  .transportadora .flete {
    width: 6rem;  /* Reducido de 8rem */
    height: 6rem; /* Reducido de 8rem */
    padding: 0.75rem; /* Reducido de 1rem */
  }
  .transportadora img {
    width: 70%; /* Aumentado ligeramente para mantener la proporción */
    height: 70%;
  }
}

@media (width <= 503px) {
  .enviosDrop {
    padding: 0.3rem;
  }
  .transportadora {
    gap: 0.5rem;
  }
  .transportadora .flete {
    width: calc(50% - 0.5rem);
    height: auto;
    padding: 0.5rem;
  }
  .transportadora img {
    width: 70%;
    height: 70%;
  }
  .order-summary {
    padding: 10px;
  }
  .order-summary-item {
    flex-direction: column;
  }
  .item-description, .item-price {
    width: 100%;
    text-align: left;
  }
  .item-price {
    margin-top: 5px;
  }
  .order-summary-total {
    flex-direction: column;
  }
  .total-label, .total-amount {
    width: 100%;
    text-align: left;
  }
  .total-amount {
    margin-top: 5px;
  }
}

.provider-navigation {
  background-color: #f8f9fa;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 8px;
  border: 1px solid #e9ecef;
}

.provider-nav-header {
  text-align: center;
  margin-bottom: 1rem;
}

.provider-nav-header h4 {
  color: #4035df;
  margin-bottom: 0.5rem;
}

.provider-progress {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin: 1rem 0;
}

.progress-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #dee2e6;
  transition: all 0.3s ease;
}

.progress-dot.active {
  background-color: #4035df;
  transform: scale(1.2);
}

.provider-nav-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 34px;
}

.provider-nav-buttons button {
  padding: 0.5rem 1rem;
  border: 1px solid #4035df;
  border-radius: 4px;
  background-color: white;
  color: #4035df;
  cursor: pointer;
  transition: all 0.3s ease;
}

.provider-nav-buttons button:hover:not(:disabled) {
  background-color: #4035df;
  color: white;
}

.provider-nav-buttons button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.total-prices {
  display: block;
  margin: 15px 0;
  padding: 10px;
  background: #f8f9fa;
  border-radius: 8px;
  color: #4035df;
  font-weight: 600;
  font-size: 1.1em;
}