.bulk-shop-page {
    padding: 24px;
    background-color: #ffffff;
    width: 98%;
    margin: 0 auto;
}

.bulk-shop-table-wrapper {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    padding: 16px;
    margin-bottom: 16px;
    height: auto;
}

.bulk-shop-table {
    width: 100%;
}

/* Column width adjustments */
.bulk-shop-col-id { width: 120px; }
.bulk-shop-col-order { width: 140px; }
.bulk-shop-col-product { width: 250px; }
.bulk-shop-col-price { width: 140px; }
.bulk-shop-col-status { width: 160px; }
.bulk-shop-col-client { width: 300px; }
.bulk-shop-col-contact { width: 200px; }
.bulk-shop-col-city { width: 250px; }
.bulk-shop-col-quotes { width: 200px; }
.bulk-shop-col-carrier { width: 180px; }
.bulk-shop-col-quantity { width: 120px; }
.bulk-shop-col-notes { width: 250px; }
.bulk-shop-col-weight { width: 140px; }

.bulk-shop-client-form {
    display: flex;
    gap: 8px;
    min-width: 250px;
}

.bulk-shop-input-name {
    width: 120px !important;
}

.bulk-shop-select-city,
.bulk-shop-select-carrier {
    width: 150px !important;
}

.bulk-shop-btn-quote {
    height: 40px;
    padding: 0 24px;
    font-size: 16px;
    border-radius: 6px;
    margin-top: 24px;
    margin-right: 12px;
    background-color: #1677ff;
    border-color: #1677ff;
    color: #fff;
}

.bulk-shop-btn-generate {
    height: 40px;
    padding: 0 24px;
    font-size: 16px;
    border-radius: 6px;
    margin-top: 24px;
    margin-right: 12px;
    background-color: #52c41a;
    border-color: #52c41a;
    color: #fff;
}

.bulk-shop-btn-quote:hover,
.bulk-shop-btn-generate:hover {
    opacity: 0.85;
    transform: translateY(-1px);
    transition: all 0.2s;
}

/* Table styles overrides */
.bulk-shop-table .ant-table-wrapper {
    overflow: hidden;
}

.bulk-shop-table .ant-table-header {
    position: sticky;
    top: 0;
    z-index: 2;
    background: #f0f2f5;
}

/* Update table body styles */
.bulk-shop-table .ant-table-body {
    min-height: auto;
    max-height: none;
}

/* Add proper spacing for empty state */
.bulk-shop-table .ant-table-placeholder {
    padding: 16px;
}

/* Remove all other overflow properties */
.bulk-shop-table-wrapper, 
.bulk-shop-table, 
.ant-table, 
.ant-table-container {
    overflow: visible;
}

.order-table .ant-table {
    font-size: 14px;
}

.order-table .ant-table-thead > tr > th {
    background-color: #f0f2f5;
    font-weight: 600;
    padding: 16px 12px;
    color: #1f1f1f;
    border-bottom: 2px solid #e8e8e8;
}

.order-table .ant-table-tbody > tr > td {
    padding: 16px 12px;
    vertical-align: middle;
}

.order-table .ant-table-tbody > tr:hover > td {
    background-color: #f5f8ff;
}

.ant-select-selector {
    border-radius: 6px !important;
}

.ant-input {
    border-radius: 6px;
    height: 32px;
    font-size: 14px;
}

.ant-select {
    height: 32px;
    font-size: 14px;
}

/* Improve table cell content alignment */
.order-table .ant-table-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px; /* prevents cells from getting too wide */
}

/* ...existing code for other styles... */