.main-content {
  padding-top: 70px; /* Ajusta según la altura de tu barra superior */
  padding-left: 250px; /* Ajusta según el ancho de tu menú lateral */
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #f4f4f4 0%, #e0e0e0 100%);
}

.shopify-container {
  background: linear-gradient(to bottom, #ffffff 0%, #fafafa 100%);
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid rgba(111, 49, 245, 0.1);
  transition: transform 0.3s ease;
}

.shopify-container:hover {
  transform: translateY(-5px);
}

.form-container {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 25px;
  position: relative;
}

label {
  font-weight: 600;
  margin-bottom: 10px;
  display: block;
  color: #2c3e50;
  font-size: 0.95rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

input {
  padding: 15px;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  transition: all 0.3s ease;
  font-size: 1rem;
  color: #2c3e50;
}

input:focus {
  border-color: #6f31f5;
  box-shadow: 0 0 0 3px rgba(111, 49, 245, 0.2);
  outline: none;
}

.submit-button {
  background: linear-gradient(45deg, #6f31f5 0%, #8355f7 100%);
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 100%;
}

.submit-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(111, 49, 245, 0.3);
}

.error-message {
  color: #f44336;
  margin-bottom: 10px;
}

.success-message {
  color: #4caf50;
  margin-bottom: 10px;
}

.orders-table {
  width: 100%;
  margin-top: 20px;
}

/* Estilo para la fila de botones */
.button-group {
  display: flex;
  justify-content: space-between; /* Coloca los botones espaciados uniformemente */
  margin-bottom: 20px;
}

.button-group .ant-btn {
  flex: 1; /* Hace que los botones ocupen el mismo espacio */
  margin-right: 10px; /* Espacio entre los botones */
  background-color: rgba(0, 0, 255, 0.164);
}

.button-group .ant-btn:last-child {
  margin-right: 0; /* Elimina el margen derecho del último botón */
}
.orders-container {
  padding: 20px;
  background: linear-gradient(to bottom, #ffffff 0%, #fafafa 100%);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  margin: 30px auto;
  max-width: 95%;
  border: 1px solid rgba(111, 49, 245, 0.1);
}

.centered-container {
  margin-left: 13%;
}
/* Estilo de la tabla de Ant Design */

/* Alinear la tabla correctamente */
.ant-table {
  margin-top: 20px; /* Añadir separación entre los botones y la tabla */
  border-radius: 15px; /* Borde redondeado para darle un mejor acabado */
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.ant-table-thead > tr > th {
  background: linear-gradient(45deg, #6f31f5 0%, #8355f7 100%);
  color: white;
  font-weight: 600;
  padding: 16px;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-align: center;
}

.ant-table-tbody > tr > td {
  text-align: center;
  padding: 16px;
  font-size: 0.95rem;
  border-bottom: 1px solid rgba(111, 49, 245, 0.1);
}

.ant-table-tbody > tr:hover > td {
  background-color: rgba(111, 49, 245, 0.05) !important;
}

/* Botones personalizados para Ant Design */
.ant-btn-primary {
  background: linear-gradient(45deg, #6f31f5 0%, #8355f7 100%);
  border: none;
  box-shadow: 0 5px 15px rgba(111, 49, 245, 0.2);
}

.ant-btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(111, 49, 245, 0.3);
}

.ant-btn-danger {
  background-color: #f44336;
  border-color: #f44336;
}

.ant-btn-danger:hover {
  background-color: #d32f2f;
  border-color: #d32f2f;
}

.filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  background: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(10px);
}

.filter-group {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.filter-group p {
  margin-right: 8px;
  font-weight: bold;
  color: #333;
  font-size: 12px;
}

.filter-group .ant-select {
  margin-right: 20px;
  min-width: 150px;
}

.ant-select-selector {
  border-radius: 10px !important;
  padding: 5px 15px !important;
  height: 40px !important;
  border: 2px solid #e0e0e0 !important;
}

/* Estilo para filas seleccionadas */
.ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: #e6f7ff !important;
  border-bottom: 1px solid #91d5ff;
}

/* Contenedor de acciones masivas */
.bulk-actions {
  position: fixed; /* Cambiado de sticky a fixed para mejor control */
  bottom: -100px; /* Inicialmente fuera de la vista */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  border-radius: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  min-width: 300px;
  border: 1px solid rgba(111, 49, 245, 0.2);
  opacity: 0;
  visibility: hidden;
  margin: 0; /* Eliminar margen */
}

/* Estado activo cuando hay elementos seleccionados */
.bulk-actions.has-selected {
  bottom: 20px; /* Posición final cuando está visible */
  opacity: 1;
  visibility: visible;
}

/* Eliminar la transformación Y anterior */
.bulk-actions:not(.has-selected) {
  /* No necesitamos definir transform aquí ya que usamos bottom */
  pointer-events: none; /* Deshabilitar interacciones cuando está oculto */
}

/* Contador de selección */
.selection-counter {
  background: linear-gradient(45deg, #6f31f5 0%, #8355f7 100%);
  color: white;
  padding: 4px 12px;
  border-radius: 25px;
  margin-right: 15px;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 28px; /* Igualamos altura al botón */
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0 20px;
  box-shadow: 0 5px 15px rgba(111, 49, 245, 0.2);
}

/* Botón de acción masiva */
.bulk-actions .ant-btn-primary {
  height: 40px;
  padding: 0 25px;
  font-size: 15px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
  margin: 0;
  display: inline-flex;
  justify-content: center;
}

.bulk-actions .ant-btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(111, 49, 245, 0.3);
}

/* Animación para el contenedor cuando hay elementos seleccionados */
.bulk-actions.has-selected {
  transform: translateY(0);
  opacity: 1;
}

.bulk-actions:not(.has-selected) {
  transform: translateY(100px);
  opacity: 0;
}

/* Ajuste del checkbox en la tabla */
.ant-checkbox-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  background: none;
  margin: 0;
}

.ant-checkbox {
  transform: scale(1.2);
  margin: 0;
  padding: 0;
}

.ant-checkbox-inner {
  width: 18px;
  height: 18px;
  border: 2px solid #e0e0e0;
  border-radius: 4px;
  background-color: white;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #6f31f5;
  border-color: #6f31f5;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #6f31f5;
}

.ant-table-selection-column {
  width: 40px !important;
  padding: 8px !important;
  background: none !important;
  border: none !important;
}

.ant-table-selection-column .ant-checkbox-wrapper {
  box-shadow: none !important;
  outline: none !important;
}

/* Ajuste del checkbox en la tabla */
.ant-checkbox-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.ant-checkbox {
  transform: scale(1.5);  /* Aumentar el tamaño del checkbox */
}

.ant-table-selection-column {
  width: 50px; /* Aumenta el ancho de la columna si es necesario */
  padding: 0 16px; /* Asegura un espacio suficiente alrededor del checkbox */
  text-align: center;
}

/* Ajuste de la alineación del checkbox en las filas */
.ant-table-row .ant-table-selection-column {
  vertical-align: middle;
  padding-left: 17px; /* Ajusta según necesites */
  width: 50px;
}

/* Ajuste del header del checkbox */
.ant-table-thead .ant-table-selection-column {
  padding-left: 17px; /* Mismo padding que las filas */
}

/* Ajuste de la columna de selección */
.ant-table-selection-column {
  width: 100px !important;  /* Aumentado de 60px a 100px */
  text-align: center;
  padding: 16px !important;
}

/* Ajustes refinados para los checkboxes */
.ant-table-selection-column {
  width: 60px !important;
  padding: 16px 24px !important; /* Aumentar padding horizontal */
  text-align: center;
  background: none !important;
  border: none !important;
}

.ant-table-thead .ant-table-selection-column {
  background: linear-gradient(45deg, #6f31f5 0%, #8355f7 100%) !important;
  padding: 16px 24px !important;
}

.ant-table-tbody .ant-table-selection-column {
  padding: 16px 24px !important;
}

.ant-checkbox-wrapper {
  margin-right: 12px !important; /* Añadir espacio a la derecha */
  position: relative;
  left: 4px; /* Ajustar posición ligeramente hacia la derecha */
}

.ant-table-thead .ant-checkbox-wrapper {
  margin-right: 12px !important;
  position: relative;
  left: 4px;
}

/* Asegurar que el contenido siguiente no se solape */
.ant-table-cell {
  white-space: nowrap;
  padding-left: 16px !important;
}

.ant-table-selection-column .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Alinear al inicio en lugar de centro */
  width: 100%;
  padding-left: 8px; /* Añadir padding izquierdo */
}

.ant-table-thead .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.ant-table-thead .ant-checkbox-inner {
  width: 18px;
  height: 18px;
  border: 2px solid #ffffff;
  border-radius: 4px;
  background-color: transparent;
}

.ant-table-thead .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ffffff;
  border-color: #ffffff;
}

.ant-table-thead .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #6f31f5;
  width: 6px;
  height: 11px;
}

.ant-table-thead .ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #ffffff;
}

.ant-table-thead .ant-checkbox {
  transform: scale(1.2);
  top: 0;
}

.ant-table-thead .ant-table-selection-column {
  background: linear-gradient(45deg, #6f31f5 0%, #8355f7 100%);
  padding: 16px !important;
}

.ant-btn {
  border-radius: 10px;
  height: 40px;
  padding: 0 25px;
  font-weight: 600;
  transition: all 0.3s ease;
}

/* Ajustes para el espaciado entre checkbox y contenido */
.ant-table-selection-column {
  width: 70px !important;
  padding: 16px 8px 16px 24px !important;
}

.ant-table-cell {
  padding-left: 24px !important;
}

/* Ajuste específico para la primera columna después del checkbox */
.ant-table-cell:first-of-type:not(.ant-table-selection-column) {
  padding-left: 32px !important;
}

/* Asegurar que el checkbox esté bien posicionado */
.ant-table-selection-column .ant-checkbox-wrapper {
  padding-right: 16px !important;
  margin-right: 0 !important;
}

/* Ajuste para el contenedor del checkbox */
.ant-table-selection-column {
  position: relative;
}

.ant-table-selection-column::after {
  content: '';
  position: absolute;
  right: 0;
  top: 25%;
  height: 50%;
  width: 1px;
  background: rgba(111, 49, 245, 0.1);
}

/* Resetear estilos previos de checkbox */
.ant-table-selection-column {
  width: 85px !important;
  padding: 0 !important;
  background: none !important;
  border: none !important;
  position: relative;
}

/* Contenedor del checkbox */
.ant-table-selection-column .ant-checkbox-wrapper {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  height: 100% !important;
  padding: 16px 8px !important;
  margin: 0 !important;
  cursor: pointer !important;
}

/* Ajuste del checkbox mismo */
.ant-checkbox {
  transform: scale(1.2);
  position: relative;
  margin: 0;
  top: 0;
}

/* Separador visual */
.ant-table-selection-column::after {
  content: '';
  position: absolute;
  right: 0;
  top: 20%;
  height: 60%;
  width: 1px;
  background: rgba(111, 49, 245, 0.1);
}

/* Estilo específico para el checkbox del encabezado */
.ant-table-thead .ant-table-selection-column {
  background: linear-gradient(45deg, #6f31f5 0%, #8355f7 100%) !important;
}

.ant-table-thead .ant-checkbox-wrapper {
  padding: 16px 8px !important;
}

/* Ajuste del área clickeable */
.ant-table-selection-column .ant-checkbox-wrapper {
  position: relative;
  z-index: 1;
}

/* Ajuste del espacio entre el checkbox y la siguiente columna */
.ant-table-cell:not(.ant-table-selection-column) {
  padding-left: 20px !important;
}

/* Mejorar la visibilidad del estado checked */
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #6f31f5 !important;
  border-color: #6f31f5 !important;
}

.ant-checkbox-inner {
  width: 20px !important;
  height: 20px !important;
  border: 2px solid #e0e0e0 !important;
  border-radius: 4px !important;
  background-color: white !important;
  transition: all 0.2s !important;
}

/* Estado hover del checkbox */
.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #6f31f5 !important;
}

/* Ajustes para el espaciado de las columnas */
.ant-table-cell {
  padding: 16px 24px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 120px; /* Ancho mínimo para cada columna */
}

/* Ajustes específicos para columnas con contenido más largo */
.ant-table-cell[data-column="email"],
.ant-table-cell[data-column="product_title"] {
  min-width: 200px;
}

/* Ajuste para la columna de acciones que está fija a la derecha */
.ant-table-cell.ant-table-cell-fix-right {
  min-width: 150px;
  text-align: center;
  background: #fff;
}

/* Asegurar que el contenido de las celdas no se rompa */
.ant-table-tbody > tr > td {
  word-break: keep-all;
  text-align: left; /* Alinear el texto a la izquierda para mejor legibilidad */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Ajuste del ancho de la tabla y scroll horizontal */
.ant-table-content {
  overflow-x: auto;
  width: 100%;
}

/* Mejorar la visualización del scroll horizontal */
.ant-table-body {
  overflow-x: auto !important;
  scrollbar-width: thin;
}

/* Estilos para el scrollbar en navegadores webkit */
.ant-table-body::-webkit-scrollbar {
  height: 8px;
}

.ant-table-body::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.ant-table-body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.ant-table-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Estilo para el encabezado de la columna de acciones fija */
.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
  background: linear-gradient(45deg, #6f31f5 0%, #8355f7 100%) !important;
  color: white !important;
}

/* Asegurar que el gradiente se mantenga al hacer scroll */
.ant-table-thead .ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
  background: linear-gradient(45deg, #6f31f5 0%, #8355f7 100%) !important;
  color: white !important;
}

/* Ajustes para hacer la tabla más compacta */
.ant-table-small {
  font-size: 12px;
}

.ant-table-small .ant-table-thead > tr > th {
  padding: 8px 16px;
  font-size: 12px;
}

.ant-table-small .ant-table-tbody > tr > td {
  padding: 8px 16px;
}

/* Reducir el espacio de los filtros */
.filter-container {
  padding: 10px;
  margin-bottom: 15px;
}

.filter-group {
  margin-right: 10px;
}

.filter-group p {
  font-size: 12px;
  margin: 0 8px 0 0;
}

.filter-group .ant-select {
  min-width: 150px;
}

/* Hacer los botones más pequeños */
.ant-btn-sm {
  height: 24px;
  padding: 0 8px;
  font-size: 12px;
}

/* Ajustar el espacio del checkbox */
.ant-table-selection-column {
  width: 40px !important;
  padding: 8px !important;
}

/* Reducir el espacio del bulk actions */
.bulk-actions {
  padding: 8px 20px;
  min-width: 300px;
}

.selection-counter {
  font-size: 12px;
  padding: 4px 12px;
  height: 28px;
}

/* Ajustar el tamaño de los elementos de la paginación */
.ant-pagination-item {
  min-width: 28px;
  height: 28px;
  line-height: 26px;
}

.ant-pagination-item a {
  font-size: 12px;
}

/* Hacer la tabla más compacta verticalmente */
.ant-table-tbody > tr > td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Ajustar el espacio del contenedor principal */
.orders-container {
  padding: 20px;
}

/* Optimización de la tabla */
.ant-table-small {
  font-size: 11px !important;
}

.ant-table-small .ant-table-thead > tr > th {
  padding: 6px 8px !important;
  font-size: 11px !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
}

.ant-table-small .ant-table-tbody > tr > td {
  padding: 4px 8px !important;
  font-size: 11px !important;
}

/* Ajuste de tooltips para texto truncado */
.ant-table-cell-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* Ajuste del checkbox */
.ant-table-selection-column {
  width: 28px !important;
  padding: 4px !important;
  min-width: 28px !important;
}

.ant-checkbox {
  transform: scale(0.8) !important;
}

/* Ajuste de los botones */
.ant-btn-sm {
  height: 22px !important;
  padding: 0 8px !important;
  font-size: 11px !important;
  min-width: 45px !important;
}

/* Optimizar contenedor principal */
.orders-container {
  padding: 12px !important;
  
}

/* Ajuste de los filtros */
.filter-container {
  padding: 8px !important;
  margin-bottom: 12px !important;
}

.filter-group p {
  font-size: 11px !important;
}

.filter-group .ant-select {
  min-width: 120px !important;
}

/* Ajuste de la paginación */
.ant-pagination.ant-pagination-small .ant-pagination-item {
  min-width: 24px !important;
  height: 24px !important;
  line-height: 22px !important;
  margin: 0 2px !important;
}

.ant-pagination.ant-pagination-small .ant-pagination-item a {
  font-size: 11px !important;
}

/* Optimización del espacio entre columnas */
.ant-table-cell {
  padding: 4px 8px !important;
}

/* Ajuste del área de scroll */
.ant-table-body {
  min-height: 300px !important;
  max-height: 500px !important;
}
