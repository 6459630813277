/* Estilos específicos para el componente ListarCuenta */
.listar-cuenta-wrapper {
    padding: 20px;
   
    border-radius: 12px;
    max-width: 1100px;
    margin: 30px auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.558);
    margin-top: 1%;
    margin-left: 19%;
    max-width: 80%;
  }
  
  .listar-cuenta-title {
    font-size: 26px;
    text-align: center;
    margin-bottom: 24px;
    color: #1d39c4;
    font-weight: 600;
  }
  
  .listar-cuenta-table {
    background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .listar-cuenta-table-column-sucursal {
    background-color: #fafafa;
    font-weight: bold;
  }
  
  .listar-cuenta-table-column-codigo {
    background-color: #fafafa;
  }
  
  .listar-cuenta-table-column-payments {
    background-color: #e6f7ff;
  }
  
  .listar-cuenta-table-column-saldo {
    background-color: #f6ffed;
  }
  
  .listar-cuenta-table-column-envios {
    background-color: #fff7e6;
  }
  
  .listar-cuenta-table-column-retiros {
    background-color: #fff1f0;
  }
  
  .ant-table {
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
  }
  
  .ant-table-pagination {
    margin: 20px 0;
  }
  