/* Contenedor principal de la barra de navegación superior */
.navbar-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  color: black;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-logo {
  display: flex;
  justify-content: center;
  flex: 1;  /* This will make the logo container take available space */
}

.navbar-logo p {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin: 0;
  text-align: center;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-info p {
  margin: 0 10px;
}

.panel-link {
  margin-right: 15px;
  text-decoration: underline;
  cursor: pointer;
  color: black;
}

.user-avatar img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

/* Contenedor principal de la barra de navegación lateral */
.navbar-container {
  flex-direction: column;
  align-items: center;
  background-color: #f8f9fa;
  padding: 20px;
  width: 250px;
  height: 100vh; /* Asegura que la barra ocupe toda la altura de la pantalla */
  overflow-y: auto; /* Añade scroll si el contenido es demasiado grande */
  position: fixed; /* Fija la barra lateral */
  left: 0;
  top: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

/* Estilos personalizados para la scrollbar */
.navbar-container::-webkit-scrollbar {
  width: 8px;
}

.navbar-container::-webkit-scrollbar-track {
  background: #f8f9fa; /* Color del fondo de la scrollbar */
}

.navbar-container::-webkit-scrollbar-thumb {
  background-color: #3f51b5; /* Color de la barra de desplazamiento */
  border-radius: 20px;
  border: 3px solid #f8f9fa; /* Espacio alrededor de la barra de desplazamiento */
}

.navbar-title {
  margin-bottom: 20px;
}

.logo {
  width: 60px;
  margin-bottom: 20px;
  height: auto;
  margin-left: 35%;
}

.navbar-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.navbar-menu li {
  margin-bottom: 15px;
}

.navbar-menu a {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  text-decoration: none;
  color: #333;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
}

.navbar-menu a .material-icons {
  margin-right: 10px;
}

.navbar-menu a:hover,
.navbar-menu a.active {
  background-color: #3f51b5;
  color: white;
}

/* Ajustes para el contenido principal */
.content-container {
  margin-left: 250px; /* El mismo ancho que la barra de navegación */
  padding: 20px;
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
  position: absolute;
  left: 20px;
}

.hamburger-menu div {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 5px;
  transition: 0.3s;
}

@media (max-width: 768px) {
  .navbar-container {
    transform: translateX(-100%);
  }
  
  .navbar-top {
    justify-content: space-between;
    width: 100%;
    padding-left: 60px;
  }
  
  .navbar-logo {
    justify-content: center;
  }

  .navbar-top .user-info {
    display: none;
  }
  
  .hamburger-menu {
    display: flex;
  }
  
  .close-btn {
    display: block; /* Mostrar el botón de cierre en móviles */
  }
  
  .content-container {
    margin-left: 0;
    padding: 20px;
  }
  
  .navbar-container.show {
    transform: translateX(0);
  }
  
  .navbar-container .user-info {
    display: flex;
    margin-top: 20px;
  }
}