.payments-module-container {
  margin-top: 20px;
  margin-left: 285px;
  margin-right: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
}

.payments-module {
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
}

/* Estilos para las pestañas */
.ant-tabs {
  width: 100%;
}

.ant-tabs-content {
  width: 100%;
}

.ant-tabs-tabpane {
  width: 100%;
}

.module-title {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.card-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.card {
  flex: 1;
  margin: 0 10px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 15px;
}

.total-available {
  background-color: #e3f2fd;
}

.withdrawals {
  background-color: #ffebee;
}

.card i.material-icons {
  font-size: 48px;
  color: #333;
}

.card-content {
  flex: 1;
}

.card-content p {
  margin: 0;
  color: #333;
  font-weight: bold;
}

.card-content h2 {
  margin: 5px 0 0 0;
  color: #333;
}

.info-text {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

.alert {
  background-color: #ffccbc;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 10px;
  color: #d32f2f;
  text-align: center;
  font-weight: bold;
}

.alert strong {
  color: #b71c1c;
}

.withdrawal-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.withdrawal-form label {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.withdrawal-form input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
}

.withdrawal-form button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #7380ec;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.withdrawal-form button:hover {
  background-color: #5b6bdf;
}

.bank-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f1f1f1;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.bank-list h3 {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.update-info {
  margin-bottom: 20px;
  font-size: 16px;
  color: #333;
}

.update-info strong {
  font-weight: bold;
}

.withdrawals-table {
  width: 100%;
}

.withdrawals-table h3 {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.withdrawals-table table {
  width: 100%;
  border-collapse: collapse;
}

.withdrawals-table th,
.withdrawals-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.withdrawals-table th {
  background-color: #7380ec;
  color: white;
}

.withdrawals-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.withdrawals-table tr:hover {
  background-color: #f1f1f1;
}

.table-controls-retiros-realizados {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.export-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 10px;
}

.export-button:hover {
  background-color: #45a049;
}

/* Ajustes para pantallas medianas (tablets, pantallas medianas) */

@media (max-width: 1024px) {
  .card-container {
    flex-direction: column;
    align-items: center;
  }

  .card {
    margin: 10px 0;
    width: 90%;
  }

  .withdrawal-form input {
    max-width: 80%;
  }

  .payments-module {
    width: 95%;
    max-width: 800px;
  }
}

/* Ajustes para pantallas pequeñas (móviles) */
@media (max-width: 768px) {
  .payments-module-container {
    margin-left: 40px;
    margin-right: 20px;
  }

  .module-title {
    font-size: 20px;
  }

  .card-container {
    flex-direction: column;
  }

  .card {
    margin: 10px 0;
    width: 100%;
  }

  .card i.material-icons {
    font-size: 36px;
  }

  .card-content h2 {
    font-size: 18px;
  }

  .withdrawal-form input {
    max-width: 100%;
  }

  .withdrawals-table th,
  .withdrawals-table td {
    padding: 10px;
    font-size: 14px;
  }

  .payments-module {
    width: 100%;
    padding: 15px;
  }
}

/* Ajustes para pantallas extra pequeñas (móviles) */
@media (max-width: 480px) {
  .payments-module-container {
    margin: 10px;
  }

  .module-title {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .card-container {
    flex-direction: column;
  }

  .card {
    margin: 10px 0;
    width: 100%;
    padding: 15px;
  }

  .card i.material-icons {
    font-size: 32px;
  }

  .card-content h2 {
    font-size: 16px;
  }

  .withdrawal-form input {
    font-size: 14px;
    padding: 8px;
  }

  .withdrawal-form button {
    padding: 8px 16px;
  }

  .bank-info {
    font-size: 14px;
    padding: 10px;
  }

  .withdrawals-table th,
  .withdrawals-table td {
    padding: 8px;
    font-size: 12px;
  }

  .table-controls-retiros-realizados {
    flex-direction: column;
      align-items: flex-start;
  }
}

.status-filter {
  min-width: 150px;
}

@media (max-width: 768px) {
  .status-filter {
    width: 100%;
  }
}

/* Estilos para las pestañas */
.ant-tabs-nav {
    margin-bottom: 20px;
}

.ant-tabs-tab {
    font-size: 16px;
    padding: 12px 24px !important;
}

.ant-tabs-tab.ant-tabs-tab-active {
    background-color: #7380ec;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
}

.ant-tabs-ink-bar {
    background-color: #7380ec !important;
}

/* Ajustes responsive para las pestañas */
@media (max-width: 768px) {
    .ant-tabs-tab {
        padding: 8px 16px !important;
        font-size: 14px;
    }
}