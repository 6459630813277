.footer {
    background: linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%);
    padding: -1.5rem 0;
    position: relative;
    box-shadow: 0 -3px 10px rgba(115, 128, 236, 0.1);
}

.footer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0; /* Asegura que comience desde el borde izquierdo */
    right: 0; /* Asegura que llegue hasta el borde derecho */
    height: 2px;
    background: linear-gradient(90deg, #7380EC 0%, #5d6be6 100%);
    margin: 0; /* Elimina cualquier margen */
    padding: 0; /* Elimina cualquier padding */
}

.footer__container {
    width: 90%;
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.footer__contactanos, .footer__ubicados, .footer__links {
    padding: 20px;
    transition: transform 0.3s ease;
}

.footer__contactanos:hover, .footer__ubicados:hover, .footer__links:hover {
    transform: translateY(-5px);
}

.titulos {
    font-size: 1.1rem;
    font-weight: 600;
    color: #7380EC;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #7380EC;
    display: inline-block;
}

.descripcion {
    font-size: 0.9rem;
    color: #555;
    line-height: 1.5;
    margin: 0.5rem 0;
}

.footer__links {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.footer__links a {
    color: #555;
    text-decoration: none;
    font-size: 0.9rem;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
}

.footer__links a:before {
    content: '›';
    margin-right: 0.5rem;
    color: #7380EC;
    font-size: 1.2rem;
    opacity: 0;
    transition: opacity 0.2s ease;
}

.footer__links a:hover {
    color: #7380EC;
    transform: translateX(5px);
}

.footer__links a:hover:before {
    opacity: 1;
}

.footer__copyright {
    margin-top: 2rem;
    padding-top: 1rem;
    text-align: center;
    font-size: 0.8rem;
    color: #777;
    border-top: 1px solid #eee;
}

@media (max-width: 768px) {
    .footer__container {
        grid-template-columns: 1fr;
        text-align: center;
    }

    .titulos {
        display: block;
        text-align: center;
        border-bottom: none;
    }

    .titulos:after {
        content: '';
        display: block;
        width: 50px;
        height: 2px;
        background: #7380EC;
        margin: 0.5rem auto;
    }

    .footer__links {
        align-items: center;
    }

    .footer__links a:before {
        display: none;
    }

    .footer__links a:hover {
        padding-left: 0;
    }
}

@media (max-width: 480px) {
    .footer {
        padding: 30px 0;
    }

    .footer__container {
        gap: 30px;
    }

    .descripcion {
        font-size: 0.9rem;
    }
}