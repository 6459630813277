

/* Contenedor principal */
.order-summary {
  max-width: 600px;
  margin: 20px auto;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 4px;
  background: #f3f3f3;
}

/* Tarjetas */
.card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.644);
  margin-bottom: 20px;
  padding: 20px;
}

.card h2 {
  margin: 0;
  padding-bottom: 10px;
  border-bottom: 2px solid #eee;
}

.card p {
  line-height: 1.4;
  margin: 10px 0;
}

/* Lista de productos */
.product-list div, .product-item {
  background: #f7f7f7;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
}

.product-subtotal {
  text-align: right;
  margin-top: 10px;
  font-weight: bold;
}

/* Resumen total */
.total-summary p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  font-size: 16px;
}

.red-text {
  color: #dc3545;
}

.net-total {
  font-weight: bold;
  color: #28a745;
}

/* Botones */
.button, .map-button, .navigate-button {
  display: inline-block; /* Cambia a inline-block */
  padding: 10px 15px;
  margin: 5px; /* Margen uniforme para todos los lados */
  border: none;
  border-radius: 4px;
  color: #fff;
  background-color: #7380EC;
  text-decoration: none;
  font-size: 14px;
  transition: background-color 0.3s;
  cursor: pointer;
}

.button:hover, .map-button:hover, .navigate-button:hover {
  background-color: #0056b3;
}

.button-red {
  background-color: #d9534f;
}

.unassign-button {
  background-color: #9e1212;
}

.unassign-button:hover {
  background-color: #3c3d3d;
}

.deliver-button {
  background-color: #28a745;
}

.deliver-button:hover {
  background-color: #1d632d;
}

.delivery-person-butto {
  display: flex;
  justify-content: center; /* Centra los elementos horizontalmente */
  align-items: center; /* Centra los elementos verticalmente */
  gap: 10px; /* Mantiene un espacio entre los botones */
  flex-wrap: wrap; /* Permite que los elementos se ajusten en líneas múltiples si es necesario */
}