.configuracion-transportadora-table-container {
  margin-top: 20px;
  margin-left: 265px;
  margin-right: 20px;
  padding: 20px;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.configuracion-transportadora-table-wrapper {
  width: 100%;
  overflow-x: auto;
}

.configuracion-transportadora-table-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.configuracion-transportadora-table-controls label {
  font-size: 14px;
  margin-right: 10px;
  color: #666;
  font-weight: 500;
}

.configuracion-transportadora-table-controls select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  color: #333;
  background-color: #fff;
  transition: border-color 0.3s ease;
}

.configuracion-transportadora-table-controls select:focus {
  border-color: #7380EC;
  outline: none;
}

.configuracion-transportadora-search-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 200px;
  font-size: 14px;
  color: #333;
  transition: border-color 0.3s ease;
}

.configuracion-transportadora-search-input:focus {
  border-color: #7380EC;
  outline: none;
}

.configuracion-transportadora-add-button {
  margin-top: -10px;
  margin-bottom: 20px;
}

.configuracion-transportadora-add-button,
.configuracion-transportadora-edit-button {
  padding: 10px 15px;
  background-color: #7380EC;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.configuracion-transportadora-add-button:hover,
.configuracion-transportadora-edit-button:hover {
  background-color: #3F51B5;
  transform: translateY(-2px);
}

.configuracion-transportadora-custom-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 1200px;
}

.configuracion-transportadora-custom-table thead {
  background-color: #7380EC;
  color: white;
}

.configuracion-transportadora-custom-table th,
.configuracion-transportadora-custom-table td {
  padding: 12px;
  text-align: left;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s, color 0.3s;
}

.configuracion-transportadora-custom-table th {
  cursor: pointer;
  font-weight: bold;
}

.configuracion-transportadora-custom-table th:hover {
  background-color: #3F51B5;
}

.configuracion-transportadora-custom-table tbody tr {
  background-color: #fff;
  transition: background-color 0.3s;
}

.configuracion-transportadora-custom-table tbody tr:nth-of-type(even) {
  background-color: #f9f9f9;
}

.configuracion-transportadora-custom-table tbody tr:hover {
  background-color: #f1f1f1;
}

.configuracion-transportadora-custom-table tbody td {
  cursor: pointer;
  transition: background-color 0.3s;
}

.configuracion-transportadora-custom-table tbody td:hover {
  background-color: #e0e0e0;
}

.configuracion-transportadora-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.configuracion-transportadora-page-button {
  background-color: #7380EC;
  color: white;
  border: none;
  padding: 8px 12px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.configuracion-transportadora-page-button:hover {
  background-color: #303f9f;
  transform: translateY(-2px);
}

.configuracion-transportadora-page-button.active {
  background-color: #ff4081;
}

.configuracion-transportadora-modal .ant-modal-content {
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.configuracion-transportadora-modal .ant-modal-header {
  background-color: #7380EC;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 16px 24px;
}

.configuracion-transportadora-modal .ant-modal-title {
  color: white;
  font-size: 18px;
  font-weight: 600;
}

.configuracion-transportadora-modal .ant-modal-body {
  padding: 24px;
}

.configuracion-transportadora-modal .ant-modal-footer {
  border-top: none;
  padding: 10px 24px 20px;
}

.configuracion-transportadora-form .ant-form-item-label > label {
  font-size: 14px;
  color: #333;
  font-weight: 500;
}

.configuracion-transportadora-input,
.configuracion-transportadora-select .ant-select-selector,
.configuracion-transportadora-input-number {
  border-radius: 8px;
  border: 1px solid #ccc;
  transition: all 0.3s ease;
  height: 32px;
}

.configuracion-transportadora-input:hover,
.configuracion-transportadora-input:focus,
.configuracion-transportadora-select:hover .ant-select-selector,
.configuracion-transportadora-select.ant-select-focused .ant-select-selector,
.configuracion-transportadora-input-number:hover,
.configuracion-transportadora-input-number:focus {
  border-color: #7380EC;
  box-shadow: 0 0 0 2px rgba(115, 128, 236, 0.2);
}

.configuracion-transportadora-input-number .ant-input-number-input {
  height: 30px;
}

.configuracion-transportadora-checkbox {
  display: flex;
  align-items: center;
  height: 32px;
}

.configuracion-transportadora-checkbox .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
}

.configuracion-transportadora-checkbox .ant-checkbox {
  top: 0;
}

.configuracion-transportadora-checkbox .ant-checkbox + span {
  padding-left: 8px;
  padding-right: 8px;
  line-height: 1;
}

.configuracion-transportadora-checkbox .ant-checkbox-inner {
  width: 18px;
  height: 16px;
  margin-top: -35px;
  margin-left: 1%;
}

.configuracion-transportadora-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #7380EC;
  border-color: #7380EC;
}

.ant-form-item-control-input-content {
  display: flex;
  align-items: center;
}

.configuracion-transportadora-modal-button,
.configuracion-transportadora-modal .ant-btn {
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  padding: 0 20px;
  transition: all 0.3s ease;
  background-color: #7380EC;
  border-color: #7380EC;
  color: white;
  line-height: 32px;
}

.configuracion-transportadora-modal-button:hover,
.configuracion-transportadora-modal-button:focus,
.configuracion-transportadora-modal .ant-btn:hover,
.configuracion-transportadora-modal .ant-btn:focus {
  background-color: #3F51B5;
  border-color: #3F51B5;
  color: white;
}

.configuracion-transportadora-modal .ant-modal-close {
  color: white;
}

.configuracion-transportadora-modal .ant-modal-close:hover {
  color: rgba(255, 255, 255, 0.8);
}

@media screen and (max-width: 768px) {
  .configuracion-transportadora-table-container {
    margin-left: 20px;
  }
}