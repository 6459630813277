/* Estilos generales */
.masivoFinal-containerMasivos {
    margin-top: 30px;
    margin-left: 280px; /* Centrar el contenedor */
    margin-right: 30px; /* Centrar el contenedor */
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Contenedor de la tabla */
.masivoFinal-table-container {
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
}

/* Elementos del formulario */
.ant-form-item {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.ant-btn {
    margin-right: 10px;
    border-radius: 5px;
}

.ant-btn-primary {
    background-color: #4caf50;
    border-color: #4caf50;
    color: white;
}

.ant-btn-primary:hover {
    background-color: #45a049;
    border-color: #45a049;
}

.ant-table {
    font-size: 14px;
    width: 100%;
}

/* Cabeceras de la tabla */
.ant-table-thead > tr > th {
    padding: 12px;
    background-color: #f1f1f1;
    text-align: center;
    border-bottom: 2px solid #e0e0e0;
}

/* Celdas de la tabla */
.ant-table-tbody > tr > td {
    padding: 12px;
    text-align: center;
    border-bottom: 1px solid #e0e0e0;
}

.ant-upload {
    display: block;
    margin-top: -5px;
}

.btnDescargar{
    
    margin-top: 1%;
    max-height: 80%;
    margin-left: 8%;
    background: #4CAF50;
}

/* Media Queries para dispositivos móviles */
@media screen and (max-width: 768px) {
    .masivoFinal-containerMasivos {
        max-width: 95%;
        padding: 15px;
        margin-left: auto;
        margin-right: 25%
    }

    .masivoFinal-table-container {
        overflow-x: auto;
        margin-right: 25%
    }

    .ant-btn, .ant-btn-primary {
        width: 100%;
        margin-bottom: 10px;
        margin-right: 25%
    }

    .ant-form-item {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 57px;
    }

    .ant-form-item .ant-form-item-label > label {
        margin-left: 57px;
    }

    .ant-select, .ant-upload, .ant-table {
        width: 100%;
        margin-right: 25%
    }

    /* Centramos los elementos del formulario */
    .ant-form-item, .ant-select, .ant-upload {
        margin-right: 25%
    }
    
}

/* Media Queries para dispositivos ultra pequeños (móviles muy pequeños) */
@media screen and (max-width: 480px) {

    .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
        font-size: 12px;
        padding: 8px;
    }

    .ant-btn, .ant-btn-primary {
        font-size: 12px;
        padding: 10px 20px;
        width: 213px;
        height: 40px;
    }
    .btnDescargar{
        margin-left: 2%;
        width: 211px;
        margin-top: -22%;
    }
    .ant-row, .ant-col {
        flex-direction: column;
        margin-left: 5%;
    }

    /* Alinear botones y elementos en una columna */
    .masivoFinal-containerMasivos {
        margin: 2% auto;
        padding: 10px;
        box-shadow: none;
    }

    /* Ajustes específicos para elementos pequeños */
    .ant-form-item, .ant-select, .ant-upload {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .ant-btn-primary {
        margin-top: 10px;
        background-color: #2196F3;
        border-color: #2196F3;
    }

    .ant-btn-primary:hover {
        background-color: #0b7dda;
        border-color: #0b7dda;
    }
}

/* Asegurarse de que la tabla sea responsive */
.masivoFinal-table-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.masivoFinal-table-container .ant-table {
    width: 100%;
}
.error-modal{
    z-index: 10000;
}

.masivoFinal-table-container .ant-table-wrapper {
    width: 100%;
}

/* Añadir estos estilos específicos para el Select de transportadora */
.transportadora-select-container {
  width: 100%;
  margin-bottom: 8px;
}

.transportadora-select-container .ant-form-item-label {
  white-space: normal;
  line-height: 1.2;
  margin-bottom: 8px;
}

.transportadora-select-container .ant-select {
  width: 100%;
  min-width: 250px;
}
