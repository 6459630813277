.app-header {
  background-color: #ffffff;
  color: black; /* El color del texto cambiado a negro para que sea visible */
  display: flex;
  justify-content: space-between; /* Distribuye el espacio por igual entre los elementos */
  align-items: center;
  padding: 0.5rem 1rem; /* Ajusta el relleno según sea necesario */
  margin-bottom: 2%;
}

.back-button,
.menu-button {
  flex: 0 0 auto; /* No permitas que los botones crezcan o se encojan */
}

.app-header h1 {
  text-align: center;
  margin: 0; /* Remove default margin */
  flex: 2; /* Give title more space if needed */
  max-width: 100%;
}

.app-header button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0; /* Remove padding if any */
}

.back-button {
  background: none;
  border: none;
  color: rgb(0, 0, 0);
  padding: 0.5rem;
  font-size: 1.5rem;
  cursor: pointer; /* Hace que el botón sea más interactivo */
}

.menu-button {
  background: none;
  border: none;
  cursor: pointer;
}

.menu-responsive {
  display: block; /* Permitir la transición */
  position: fixed; /* Posición fija en la pantalla */
  top: 0; /* Iniciar desde la parte superior de la pantalla */
  right: -30%; /* Iniciar oculto a la derecha */
  height: 100%; /* Altura completa de la pantalla */
  width: 15%; /* Ancho fijo para el menú desplegable */
  background-color: white; /* Fondo blanco para el menú */
  z-index: 1000; /* Asegurarse de que esté sobre otros elementos */
  overflow-y: auto; /* Permitir desplazamiento vertical si es necesario */
  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.2); /* Sombra para dar profundidad */
  transition: right 0.3s ease-in-out; /* Transición suave para desplegar */
  
}

.menu-responsive a {
  display: block;
  padding: 1rem;
  text-decoration: none; /* Elimina el subrayado de los enlaces */
  color: #333; /* Color del texto */
  font-weight: bold;
  border-bottom: 1px solid #eee; /* Línea divisora entre opciones */
  transition: background-color 0.3s; /* Transición suave para el hover */
  display: flex; /* Cambio a flex para mejor alineación de elementos */
  align-items: center; /* Centra los elementos verticalmente */
  gap: 0.8rem;
}

.menu-responsive a:hover,
.menu-responsive a:focus { /* Añade estado focus para accesibilidad */
    background-color: #f5f5f5;
    color: #000; /* Cambia también el color del texto si es necesario */
}



.menu-responsive .active {
  background-color: #e8e8e8; 
  right: 0;
}

.menu-responsive.open {
  transform: translateX(0); /* Desliza en la pantalla */
  visibility: visible; /* Ahora es visible */
  right: 0;
}

.menu-button.active {
  color: rgb(
    45,
    44,
    124
  ); /* Cambia el color si deseas indicar que el menú está activo */
}

.menu-responsive .close-menu {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .menu-responsive {
    width: 60%;
  }
  .menu-responsive a {
    padding: 10px 15px; /* Padding más pequeño para tablets */
  }
  .menu-responsive a:hover,
  .menu-responsive .close-menu:hover {
    background-color: #f2f2f2;
  }
}

/* Media query para teléfonos móviles */
@media (max-width: 480px) {
  .menu-responsive {
    width: 100%; /* El menú ocupa toda la anchura en móviles */
    transform: translateY(
      -100%
    ); /* Se esconde hacia arriba fuera de la pantalla */
    transition: transform 0.3s ease-in-out;
  }

  .menu-responsive.open {
    transform: translateY(0); /* Se muestra deslizando desde arriba */
  }

  .menu-responsive a {
    padding: 10px; /* Padding ajustado para móviles */
  }
}
