/* Contenedor principal del componente PanelUsuario */
.panel-usuario-wrapper {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: 'Roboto', sans-serif;
  }
  
  /* Estilos para el título del formulario */
  .panel-usuario-title {
    font-size: 26px;
    font-weight: bold;
    color: #333;
    margin-bottom: 24px;
    text-align: center;
  }
  
  /* Estilo para la fila del logo */
  .panel-usuario-logo-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  }
  
  /* Botón para cambiar el logo */
  .panel-usuario-logo-upload {
    width: 120px;
    height: 120px;
    background-color: #f0f0f0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 2px dashed #d9d9d9;
    transition: all 0.3s ease;
  }
  
  .panel-usuario-logo-upload:hover {
    border-color: #1890ff;
  }
  
  .panel-usuario-logo-upload img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  
  /* Ajustes generales del formulario */
  .panel-usuario-form {
    width: 100%;
  }
  
  /* Filas del formulario con separación uniforme */
  .panel-usuario-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  
  /* Estilos para columnas dentro de las filas */
  .panel-usuario-col {
    width: 48%;
  }
  
  /* Estilo de las etiquetas */
  .panel-usuario-label {
    font-weight: 600;
    color: #555;
    margin-bottom: 8px;
    display: block;
  }
  
  /* Estilo de los campos de entrada */
  .panel-usuario-input {
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #d9d9d9;
    font-size: 14px;
    transition: border-color 0.3s ease;
  }
  
  .panel-usuario-input:focus {
    border-color: #40a9ff;
    outline: none;
    box-shadow: 0 0 5px rgba(24, 144, 255, 0.2);
  }
  
  /* Estilo para los campos de texto más grandes */
  .panel-usuario-textarea {
    width: 100%;
    min-height: 100px;
    padding: 10px;
    resize: none;
    border-radius: 6px;
    border: 1px solid #d9d9d9;
    font-size: 14px;
    transition: border-color 0.3s ease;
  }
  
  .panel-usuario-textarea:focus {
    border-color: #40a9ff;
    outline: none;
    box-shadow: 0 0 5px rgba(24, 144, 255, 0.2);
  }
  
  /* Botón de subir archivos */
  .panel-usuario-upload-button {
    width: 100%;
    background-color: #f0f0f0;
    border: 1px solid #d9d9d9;
    padding: 10px;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    transition: border-color 0.3s ease;
  }
  
  .panel-usuario-upload-button:hover {
    border-color: #40a9ff;
  }
  
  /* Sección del botón de guardar */
  .panel-usuario-button-section {
    text-align: right;
    margin-top: 24px;
  }
  
  /* Botón primario (guardar) */
  .panel-usuario-submit-button {
    background-color: #1890ff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .panel-usuario-submit-button:hover {
    background-color: #40a9ff;
    box-shadow: 0 2px 8px rgba(24, 144, 255, 0.4);
  }
  
  /* Mensajes de error */
  .panel-usuario-error {
    color: #ff4d4f;
    font-size: 12px;
    margin-top: 4px;
  }
  