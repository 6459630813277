/* Container Layout */
.eliminar-guia-tracking-container-container-admin {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
  margin: 20px 20px 20px 265px;
  padding: 20px;
}

.eliminar-guia-tracking-container-admin {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
  padding: 25px;
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  transition: transform 0.2s;
}

.eliminar-guia-tracking-container-admin:hover {
  transform: translateY(-5px);
}

/* Headers and Titles */
.eliminar-guia-tracking-container-admin h2 {
  color: #2c3e50;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.eliminar-guia-tracking-container-admin p {
  color: #666;
  font-size: 0.95rem;
  margin-bottom: 20px;
}

/* Search Bar Styles */
.search-bar {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.search-input,
.carrier-select {
  height: 42px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 0 15px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.search-input {
  flex: 2;
  min-width: 150px;
}

.carrier-select {
  flex: 1;
  min-width: 120px;
  background: white;
}

.search-button {
  height: 42px;
  min-width: 42px;
  border: none;
  border-radius: 6px;
  background: #3f51b5;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.3s ease;
}

.search-button:hover {
  background: #303f9f;
}

/* Tracking Results Styles */
.tracking-info {
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
  margin-top: 20px;
}

.tracking-image {
  width: 120px;
  height: auto;
  margin: 0 auto 20px;
  display: block;
}

.tracking-results {
  background: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.tracking-results li {
  background: #f8f9fa;
  margin-bottom: 10px;
  padding: 12px;
  border-radius: 6px;
  border-left: 4px solid #3f51b5;
}

/* Detail Container Styles */
.eliminar-guia-detail-container-admin {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin-top: 10px;
}

.eliminar-guia-detail-title-admin {
  color: #2c3e50;
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.eliminar-guia-detail-subtitle-admin {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 20px;
}

/* Error Messages */
.error-message {
  color: #dc3545;
  background: #ffe6e6;
  padding: 10px;
  border-radius: 6px;
  margin-top: 10px;
}

/* Media Queries */
@media (max-width: 1200px) {
  .eliminar-guia-tracking-container-container-admin {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .eliminar-guia-tracking-container-container-admin {
    grid-template-columns: 1fr;
    margin: 20px;
  }

  .search-bar {
    flex-direction: column;
  }

  .search-input,
  .carrier-select,
  .search-button {
    width: 100%;
  }

  .eliminar-guia-tracking-container-admin {
    min-height: auto;
  }
}

@media (max-width: 1024px) {
  .eliminar-guia-tracking-container-container-admin {
    grid-template-columns: repeat(2, 1fr);
  }

  .eliminar-guia-tracking-container-admin {
    padding: 30px;
    max-width: 600px;
  }
}
