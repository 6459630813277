.cardPQ-principal {
  margin-left: 270px;
  margin-right: 20px;
  margin-top: 20px;
}

.cardPQ {
  background-color: #e4e4e4;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 500px;
  width: 100%;
  margin: 20px auto 0 auto;
  margin-bottom: 20px;
}

.cardPQ h1 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 10px;
  font-weight: bold;
  color: #555;
}

input,
textarea,
select {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

.office-buttons {
  background-color: #e4e4e4;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 500px;
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
}

.office-buttons h3 {
  margin-bottom: 20px;
  font-size: 1.2rem;
  color: #333;
}

.buttons-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.office-buttons {
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 20px auto 0 auto;
  margin-bottom: 20px;
}

.office-button-common {
  width: 200px; /* Ajusta el ancho a lo que prefieras */
  height: 50px; /* Ajusta la altura a lo que prefieras */
  display: flex;
  align-items: center;
  justify-content: center;
}

.office-button.interrapidisimo {
  background-color: #2c1a7c;
  color: white;
}

.office-button.tcc{
  background-color: #bda333;
  color: white;
}

/* coordinadora */
.office-button.coordinadora {
  background-color: #456ae2;
  color: white;
}

.office-button.interrapidisimo:hover {
  background-color: #2c1a7c;
}

.office-button.servientrega {
  background-color: #4caf50;
  color: white;
  max-width: 500px;
}

.office-button.servientrega:hover {
  background-color: #388e3c;
}

.tabla-tickets-pqr {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
}

@media (max-width: 768px) {
  .cardPQ-principal {
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .tabla-tickets-pqr {
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
  }
  
  .tabla-tickets-pqr .ant-table-wrapper {
    width: 100%;
  }
  
  .tabla-tickets-pqr .ant-table {
    width: 100%;
  }
}


.compact-table .ant-table-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px 8px !important;
  font-size: 0.9em;
}

.fixed-column {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
