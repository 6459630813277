.fail-animation-container {
    text-align: center;
    max-width: 60%;
    margin: 50px auto;
    padding: 40px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: "Arial", sans-serif;
    animation: fadeIn 0.5s ease-in-out both;
  }
  
  .error-circle {
    width: 100px;
    height: 100px;
    display: inline-block;
    background: #d9534f;
    border-radius: 50%;
    margin: 20px auto;
  }
  
  .error {
    fill: none;
  }
  
  .error-x {
    stroke: #ffffff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    animation: stroke 0.6s ease-in-out forwards;
  }
  
  .titulosF {
    color: #d9534f;
    margin: 20px 0;
    font-size: 24px;
    font-weight: bold;
    animation: fadeInUp 0.8s ease-in-out forwards;
    animation-delay: 1.2s;
  }
  
  .SubTitulo {
    color: #d9534f;
    margin: 20px 0;
    font-weight: normal;
    animation: fadeInUp 0.8s ease-in-out forwards;
    animation-delay: 1.4s;
  }
  
  .btn-primary {
    background-color: #7380EC;
    color: white;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 1rem;
    border: none;
    cursor: pointer;
    outline: none;
    margin-top: 20px;
    text-transform: uppercase;
    animation: fadeInUp 0.8s ease-in-out forwards;
    animation-delay: 1.6s;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes stroke {
    to {
      stroke-dashoffset: 0;
    }
  }
  