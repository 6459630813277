.misPedidos {
  padding: 30px;
  margin-left: 250px;
  margin-right: 20px;
  position: relative;
  margin-top: 5%;
  background: linear-gradient(145deg, #f6f8fc 0%, #ffffff 100%);
  border-radius: 20px;
  box-shadow: 0 8px 30px rgba(0,0,0,0.05);
}

.misPedidos .scroll-container {
  overflow-x: scroll;
  overflow-y: hidden;
  height: 16px;
  margin-bottom: 10px;
}

.misPedidos .scroll-container .scroll-bar {
  height: 16px;
}

.misPedidos .misPedidos-tabla {
  overflow-x: auto;
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0,0,0,0.03);
}

.misPedidos .ant-table {
  min-width: 1000px; /* Ajusta el ancho mínimo para la tabla */
  border-radius: 12px;
}

.misPedidos .ant-table-pagination.ant-pagination {
  display: flex;
  justify-content: center;
  float: none;
  margin: 16px 0;
}

.misPedidos .filters {
  display: flex;
  gap: 15px;
  padding: 20px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0,0,0,0.03);
  margin-bottom: 25px;
  flex-wrap: wrap;
  align-items: center;
}

.misPedidos .filters .ant-btn {
  background: linear-gradient(145deg, #2196f3, #1976d2);
  border: none;
  color: white;
  height: 40px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.misPedidos .filters .ant-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(33,150,243,0.3);
}

.misPedidos .filters .ant-input-affix-wrapper {
  border-radius: 8px;
  height: 40px;
  border: 1px solid #e8e8e8;
  transition: all 0.3s ease;
}

.misPedidos .filters .ant-input-affix-wrapper:hover,
.misPedidos .filters .ant-input-affix-wrapper:focus {
  border-color: #2196f3;
  box-shadow: 0 0 0 2px rgba(33,150,243,0.1);
}

.misPedidos .filters .ant-select {
  min-width: 200px;
}

.misPedidos .filters .ant-select-selector {
  border-radius: 8px !important;
  height: 40px !important;
  display: flex;
  align-items: center;
}

.misPedidos .filters > * {
  margin-bottom: 10px;
}

/* Ajustes para dispositivos de tamaño mediano (tabletas) */
@media (max-width: 1024px) {
  .misPedidos {
    margin-left: 0;
    margin-right: 0;
  }

  .misPedidos .misPedidos-tabla {
    min-width: 100%;
    max-width: 100%;
  }
}

/* Ajustes para dispositivos móviles */
@media (max-width: 768px) {
  .misPedidos {
    padding: 10px;
    margin-left: 0;
    margin-right: 0;
  }

  .misPedidos .filters {
    flex-direction: column;
    align-items: stretch;
  }

  .misPedidos .filters > * {
    width: 100%;
  }

  .misPedidos .misPedidos-tabla {
    overflow-x: scroll;
  }

  .misPedidos .ant-dropdown-trigger {
    width: 100%;
  }

  .misPedidos .ant-table {
    overflow-x: scroll;
    width: 100%;
  }
}

/* Ajustes para pantallas muy pequeñas (menos de 480px de ancho) */
@media (max-width: 480px) {
  .misPedidos .filters {
    flex-direction: column;
    align-items: stretch;
  }

  .misPedidos .filters > * {
    width: 100%;
  }

  .misPedidos .misPedidos-tabla {
    overflow-x: scroll;
  }

  .misPedidos .ant-table {
    width: 100%;
    overflow-x: scroll;
  }

  .misPedidos .ant-table-pagination.ant-pagination {
    display: flex;
    justify-content: center;
  }
}

.misPedidos .ant-table-thead > tr > th {
  background: #f8fafd;
  color: #2c3e50;
  font-weight: 600;
  padding: 16px;
}

.misPedidos .ant-table-tbody > tr > td {
  padding: 16px;
  transition: all 0.3s ease;
}

.misPedidos .ant-table-tbody > tr:hover > td {
  background: #f8fafd !important;
}

.misPedidos .ant-table-row {
  transition: all 0.3s ease;
}

.misPedidos .ant-table-row:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0,0,0,0.05);
}

/* Modal styling */
.ant-modal-content {
  border-radius: 12px;
  overflow: hidden;
}

.ant-modal-header {
  background: linear-gradient(145deg, #2196f3, #1976d2);
  border-bottom: none;
  padding: 20px 24px;
}

.ant-modal-title {
  color: white !important;
  font-size: 1.2em;
}

.ant-modal-body {
  padding: 24px;
}

.ant-modal-body p {
  margin-bottom: 15px;
  padding: 12px;
  border-radius: 8px;
  background: #f8fafd;
  transition: all 0.3s ease;
}

.ant-modal-body p:hover {
  background: #f0f4f8;
  transform: translateX(5px);
}

.ant-modal-body strong {
  color: #2196f3;
}

.ant-modal-footer {
  border-top: none;
  padding: 16px 24px;
}

.ant-modal-footer .ant-btn-primary {
  background: linear-gradient(145deg, #2196f3, #1976d2);
  border: none;
  height: 40px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.ant-modal-footer .ant-btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(33,150,243,0.3);
}

/* Scroll styling */
.scroll-container::-webkit-scrollbar,
.misPedidos-tabla::-webkit-scrollbar {
  height: 8px;
}

.scroll-container::-webkit-scrollbar-track,
.misPedidos-tabla::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.scroll-container::-webkit-scrollbar-thumb,
.misPedidos-tabla::-webkit-scrollbar-thumb {
  background: #2196f3;
  border-radius: 4px;
}

/* Status badges */
.status-badge {
  padding: 6px 12px;
  border-radius: 20px;
  font-weight: 500;
  font-size: 0.9em;
  display: inline-block;
}

.status-creado { background: #e3f2fd; color: #1976d2; }
.status-distribucion { background: #fff3e0; color: #f57c00; }
.status-entregado { background: #e8f5e9; color: #2e7d32; }
.status-rechazado { background: #ffebee; color: #c62828; }
.status-devolucion { background: #fce4ec; color: #c2185b; }
.status-anulado { background: #f3e5f5; color: #7b1fa2; }
.status-otro { background: #f5f5f5; color: #616161; }
.status-error { background: #fff3e0; color: #e65100; }

.export-button {
  background: linear-gradient(145deg, #4caf50, #388e3c) !important;
  border: none !important;
  height: 40px;
  border-radius: 8px !important;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 20px;
  transition: all 0.3s ease !important;
}

.export-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(76,175,80,0.3) !important;
}

.export-button .anticon {
  font-size: 16px;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .misPedidos {
    margin-left: 200px;
    margin-right: 15px;
    padding: 20px;
  }
}

@media (max-width: 1024px) {
  .misPedidos {
    margin-left: 0;
    margin-right: 0;
    margin-top: 80px;
    padding: 15px;
  }

  .misPedidos .filters {
    gap: 10px;
    padding: 15px;
  }

  .misPedidos .filters > * {
    flex: 1 1 calc(50% - 10px);
    min-width: 180px;
  }

  .export-button {
    flex: 0 0 auto !important;
    width: auto !important;
  }
}

@media (max-width: 768px) {
  .misPedidos {
    margin-top: 70px;
    padding: 10px;
    border-radius: 12px;
  }

  .misPedidos .filters {
    flex-direction: column;
    padding: 12px;
  }

  .misPedidos .filters > * {
    width: 100%;
    margin-bottom: 8px;
  }

  .misPedidos-tabla {
    padding: 10px;
  }

  /* Table Responsive Improvements */
  .ant-table {
    font-size: 14px;
  }

  .ant-table-thead > tr > th {
    padding: 12px 8px;
    white-space: nowrap;
  }

  .ant-table-tbody > tr > td {
    padding: 12px 8px;
  }

  /* Modal Responsive Improvements */
  .ant-modal {
    max-width: 95%;
    margin: 10px;
  }

  .ant-modal-body {
    padding: 15px;
  }

  .order-section {
    margin-bottom: 20px;
  }

  .order-section h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .status-badge {
    font-size: 0.8em;
    padding: 4px 8px;
  }
}

@media (max-width: 480px) {
  .misPedidos {
    margin-top: 60px;
    padding: 8px;
  }

  .filters {
    padding: 10px;
  }

  .misPedidos .ant-table {
    font-size: 13px;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 8px 4px;
  }

  /* Hide less important columns on mobile */
  .ant-table-cell:nth-child(1), /* checkbox */
  .ant-table-cell:nth-child(2) { /* ID */
    display: none;
  }

  /* Make modal full width on mobile */
  .ant-modal {
    width: 100% !important;
    margin: 0;
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    border-radius: 0;
  }

  .ant-modal-content {
    border-radius: 0;
  }

  .ant-modal-body p {
    padding: 8px;
    margin-bottom: 8px;
  }

  /* Improve touch targets */
  .ant-btn,
  .ant-select-selector,
  .ant-input-affix-wrapper {
    min-height: 44px !important;
  }
}

/* Add smooth transitions for better UX */
.misPedidos * {
  transition: all 0.3s ease;
}

/* Improve table loading state */
.ant-table-placeholder {
  padding: 48px 0;
}

.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: transparent;
}

/* Add container shadow on scroll */
.misPedidos-tabla.scrolled {
  box-shadow: inset 0 -10px 10px -10px rgba(0,0,0,0.1);
}
