.promo-banner {
  width: 100%;
  max-width: none;
  margin: 0 auto 2rem auto;
  background: linear-gradient(135deg, #25D366 0%, #128C7E 100%);
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(37, 211, 102, 0.15);
  overflow: hidden;
  animation: slideDown 0.3s ease-out;
  height: fit-content;
  min-height: 80px;
  margin-bottom: 1rem;
  padding: 0rem;
}

.promo-banner-content {
  padding: 0.5rem;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: center;
  height: 100%;
}

.promo-banner-header {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.emoji {
  font-size: 1.4rem;
  vertical-align: middle;
  margin: 0 0.3rem;
}

.promo-benefits-compact {
  display: flex;
  gap: 3rem;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  align-items: center;
}

.benefit {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  white-space: nowrap;
}

.check {
  font-size: 1.2rem;
  color: #FFEB3B;
}

@media (max-width: 1366px) {
  .promo-banner {
    padding: 1rem;
    max-width: 80%;
    margin-left: 15%;
    height: fit-content;
    min-height: 70px;
  }

  .cards-container-wha {
    max-width: 85%; /* Aumentado de 80% */
    margin-left: 12%; /* Reducido de 15% para mover todo a la derecha */
    gap: 1.2rem;
  }

  .card-wha, .facturacion-card {
    min-height: 180px;
    padding: 1.2rem;
  }
  .facturacion-card {
    
    margin-left: 66%;
  }

  /* Ajustes específicos para el Historial Mensual */
  .facturacion-card:last-child {
    padding: 1.5rem;
    margin-top: 1.5rem;
    width: 480px; /* Ajustar el ancho */
  }

  .mensajes-table {
    margin: 1rem 0;
    font-size: 0.9rem; /* Aumentar tamaño de fuente */
  }

  .mensajes-table .ant-table-thead > tr > th,
  .mensajes-table .ant-table-tbody > tr > td {
    padding: 12px 16px; /* Más padding para las celdas */
  }

  .cards-container-wha {
    max-width: 80%;
    margin-left: 15%;
    gap: 1.2rem;
  }

  .card-wha, .facturacion-card {
    min-height: 180px;
    padding: 1rem;
  }

  .content-wha, .facturacion-content {
    padding: 1.5rem;
  }

  .icon-wha {
    font-size: 3.5rem;
    margin-bottom: 1rem;
  }

  .title-wha, .facturacion-title {
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
  }

  .facturacion-stats {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 0.8rem;
  }

  .mensajes-table {
    margin: 1rem 0;
  }

  .button-wha {
    padding: 0.6rem 1.2rem;
    min-width: 160px;
  }

  .historial-card {
    margin-top: 1rem;
  }
}

@media (max-width: 1024px) {
  .promo-banner {
    height: fit-content;
    min-height: 65px;
    padding: 1rem 0;
  }

  .promo-banner-content {
    flex-direction: column;
    padding: 1rem;
    text-align: center;
    gap: 0.5rem;
  }

  .promo-benefits-compact {
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }

  .promo-banner-header {
    font-size: 1.1rem;
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .promo-banner {
    width: 90%;
    margin-bottom: 1.5rem;
    height: fit-content;
    min-height: 60px;
  }

  .promo-banner-content {
    padding: 1.2rem;
  }

  .promo-banner-header {
    font-size: 1.2rem;
  }

  .emoji {
    font-size: 1.4rem;
  }

  .promo-benefits {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    padding: 0.8rem;
  }

  .promo-description {
    font-size: 0.9rem;
  }

  .promo-benefits-compact {
    gap: 1rem;
  }

  .benefit {
    font-size: 0.9rem;
  }
}

.page-wha {
  min-height: calc(100vh - 64px);
  background: linear-gradient(135deg, #f0f2f5 0%, #e6e9ec 100%);
  padding: 2rem;
  display: flex;
  align-items: flex-start;  /* Cambiado de center a flex-start */
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  margin-left: 100px; /* Agregar margen izquierdo por defecto */
}

/* Actualizar el contenedor principal de tarjetas */
.cards-container-wha {
  display: grid;
  grid-template-columns: repeat(2, minmax(250px, 1fr)); /* Volver al tamaño original */
  grid-template-areas: 
    "whatsapp historial"
    "facturacion historial";
  gap: 1.5rem;  /* Aumentar el espacio entre tarjetas */
  max-width: 1000px;  /* Volver al tamaño original */
  width: 95%;        /* Reducido de 100% */
  margin: 0 auto;     /* Centrado horizontal */
  padding: 1rem;
}

.card-wha, .facturacion-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: transform 0.2s ease;
  height: auto;
  min-height: 200px;
  width: 100%;
  min-height: auto;
  margin-bottom: 1rem;
}

.card-wha {
  grid-area: whatsapp;
}

.facturacion-card:first-of-type {
  grid-area: facturacion;
}

.facturacion-card {
  min-height: auto;
  overflow: hidden;
  height: fit-content;
  
}

.facturacion-card:last-child {
  grid-area: historial;
  grid-column: unset;
  margin-top: 0;
}

.historial-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: transform 0.2s ease;
  grid-column: span 3;
}

.card-wha:hover, .facturacion-card:hover {
  transform: translateY(-5px);
}

.content-wha, .facturacion-content {
  padding: 2rem;
  text-align: center;
}

.facturacion-content {
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.historial-content {
  padding: 1.25rem;
}

.icon-wha, .facturacion-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.historial-icon {
  font-size: 2rem;
  color: #1890ff;
  margin-bottom: 0.75rem;
}

.icon-wha {
  color: #25D366;
}

.facturacion-icon {
  color: #1890ff;
}

.title-wha, .facturacion-title {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #333;
  font-weight: 500;
}

.historial-title {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  color: #333;
  font-weight: 500;
  text-align: center;
}

.date-picker {
  width: 100%;
  margin-bottom: 0.5rem;
}

.facturacion-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 0.5rem;
  margin-top: 0.5rem;
  padding: 0 0.5rem;
  font-size: 0.8rem;
}

.facturacion-stats .ant-statistic {
  margin-bottom: 0;
}

.facturacion-stats .ant-statistic-title {
  font-size: 0.7rem;
  margin-bottom: 0.25rem;
}

.facturacion-stats .ant-statistic-content {
  font-size: 0.9rem;
}

/* Mantener los estilos existentes de los botones pero hacerlos más pequeños */
.button-wha {
  padding: 0.3rem 0.8rem;
  font-size: 0.8rem;
  min-width: 80px;
  border-radius: 8px;
}

@media (max-width: 1200px) {
  .cards-container-wha {
    grid-template-columns: repeat(2, minmax(180px, 280px)); /* Reducido aún más para pantallas más pequeñas */
    width: 90%;
    margin: 0 auto;
    padding: 1rem;
  }

  .historial-card {
    grid-column: span 2;
  }
  
  .facturacion-card:last-child {
    grid-column: span 2;
  }
}

@media screen and (max-width: 1200px) {
  .promo-banner {
    width: 95%;
    padding: 1.25rem;
  }
}

/* Ajustes para pantallas medianas */
@media (max-width: 1024px) {
  .cards-container-wha {
    width: 95%;
    gap: 1rem;
  }

  .card-wha, .facturacion-card {
    min-height: 180px;  /* Reduced height */
  }

  .facturacion-content {
    padding: 1rem;
  }

  .icon-wha {
    font-size: 3rem;  /* Reduced from 4rem */
  }

  .total-mensajes-wha .ant-statistic-content {
    font-size: 1.2rem;  /* Reduced from 1.5rem */
  }
}

/* Ajustes para pantallas más pequeñas */
@media (max-width: 900px) {
  .cards-container-wha {
    width: 98%;
    gap: 0.8rem;
  }

  .date-picker {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .page-wha {
    padding: 1rem;
  }

  .cards-container-wha {
    grid-template-columns: minmax(250px, 400px); /* Una columna con ancho máximo */
    grid-template-areas: 
      "whatsapp"
      "historial"
      "facturacion";
    max-width: 500px; /* Límite máximo en móviles */
    width: 85%; /* Reducido para móviles */
    margin: 0 auto;
    padding: 0.5rem;
    gap: 2rem;
  }
  
  .card-wha, .facturacion-card {
    width: 100%;
    margin-bottom: 0;  /* Quitar margen bottom ya que usamos gap */
  }

  .historial-card {
    grid-column: span 1;
  }
  
  .facturacion-card:last-child {
    grid-column: 1;
    margin-top: 0;
  }

  .mensajes-table {
    margin: 1rem 0;
    overflow-x: auto;
  }

  .content-wha, .facturacion-content {
    padding: 1.5rem;
  }

  /* Ajustar el tamaño de la tabla en móviles */
  .ant-table-wrapper {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .ant-table {
    min-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .promo-banner {
    width: 90%;
    padding: 1rem;
    min-height: 60px;
    margin-bottom: 1.5rem;
    height: fit-content;
  }
}

@media (max-width: 600px) {
  .page-wha {
    padding: 2rem 1rem;
  }

  .whatsapp-container {
    width: 95%;
  }

  .content-wha {
    padding: 1.5rem;
  }

  .title-wha {
    font-size: 1.5rem;
  }

  .button-wha {
    width: 100%;
    margin-bottom: 1rem;
    min-width: 150px;
  }

  .content-wha {
    padding: 20px;
  }

  .activate-whatsapp-button,
  .deactivate-whatsapp-button {
    padding: 10px 20px;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .cards-container-wha {
    width: 100%;
    padding: 0.8rem;
    gap: 1.5rem;
  }

  .date-picker {
    width: 100% !important;
  }

  .mensajes-table {
    max-height: 250px;
  }

  .facturacion-content {
    padding: 1rem;
  }

  .mensajes-table .ant-table-thead > tr > th,
  .mensajes-table .ant-table-tbody > tr > td,
  .mensajes-table .ant-table-summary > tr > td {
    padding: 8px 4px;
    font-size: 0.85rem;
  }
  
  .content-wha, .facturacion-content {
    padding: 1.5rem;
  }
  
  .cards-container-wha {
    width: 100%;
    padding: 1rem;
    gap: 1.5rem;
  }
  
  .card-wha, .facturacion-card {
    min-height: auto;
  }
}

@media screen and (max-width: 480px) {
  .promo-banner {
    width: 95%;
    padding: 0.75rem;
    min-height: 50px;
    margin: 1rem auto;
    border-radius: 8px;
    height: fit-content;
  }
}

.whatsapp-container {
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 50%;
    max-width: 521px; 
    animation: slideUp 0.5s ease-out;
    margin-top: -6%;
    margin-left: 19%;
}

.content-wha {
  text-align: center;
  background: white;
  padding: 2rem; /* Reducido de 3rem */
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.content-wha:hover {
  transform: translateY(-5px);
}

.icon-wha {
  font-size: 4rem; /* Reducido de 5rem */
  color: #25D366;
  margin-bottom: 1.5rem;
  animation: bounce 2s infinite;
}

.title-wha {
  color: #1a1a1a;
  font-size: 1.5rem; /* Reducido de 2rem */
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.whatsapp-description {
  color: #666;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  line-height: 1.6;
  padding: 0 1rem;
}

.buttons-container-wha {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 0.5rem;
}

.button-wha {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem; /* Reducido de 1rem 2rem */
  font-size: 1.1rem;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;
  min-width: 180px; /* Reducido de 200px */
}

.button-wha.activate-wha {
  background-color: #25D366;
}

.button-wha.deactivate-wha {
  background-color: #FF3B30;
}

.button-wha:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.loading-wha {
  opacity: 0.7;
  cursor: not-allowed;
}

.button-wha:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none !important;
  box-shadow: none !important;
}

.custom-message {
  font-size: 1rem;
  padding: 1rem;
}

.activate-whatsapp-button,
.deactivate-whatsapp-button {
  background-color: #25D366;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  margin: 10px;
  transition: background-color 0.3s, transform 0.3s;
}

.activate-whatsapp-button:hover,
.deactivate-whatsapp-button:hover {
  background-color: #128C7E;
  transform: scale(1.05);
}

.deactivate-whatsapp-button {
  background-color: #FF3B30;
}

.deactivate-whatsapp-button:hover {
  background-color: #C1271D;
}

.mensajes-mensuales {
  margin-top: 1.5rem;
  width: 100%;
}

.mensajes-mensuales h3 {
  font-size: 1rem;
  color: #333;
  margin-bottom: 1rem;
}

/* Ajustar la tabla */
.mensajes-table {
  margin: 1.5rem 0;
  width: 100%;
  font-size: 0.8rem;
  margin-top: 1rem;
  overflow: auto;
  max-height: none;  /* Quitar altura máxima */
  overflow-x: auto;
  max-width: 100%;
  overflow: visible; /* Cambiar de auto a visible */
}

.mensajes-table .ant-table-wrapper {
  margin: 0;
  padding: 0;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.mensajes-table .ant-table {
  background: white;
  min-width: 100%;  /* Ajustar ancho mínimo */
  width: 100%;
  table-layout: fixed; /* Añadir layout fijo */
}

.mensajes-table .ant-table-thead > tr > th {
  background: #fafafa;
  color: #333;
  font-weight: 600;
  padding: 12px 8px;
  border-bottom: 2px solid #f0f0f0;
}

.mensajes-table .ant-table-tbody > tr > td {
  padding: 12px 8px;
  color: #666;
  border-bottom: 1px solid #f0f0f0;
}

.mensajes-table .ant-table-tbody > tr:hover > td {
  background: #f8f8f8;
}

.mensajes-table .ant-table-summary {
  background: #fafafa;
  font-weight: 600;
  color: #333;
}

.mensajes-table .ant-table-summary > tr > td {
  padding: 12px 8px;
  border-top: 2px solid #f0f0f0;
}

.total-mensajes-wha {
  margin: 1rem 0;
  padding: 0.5rem;
  background: #f0f8ff;
  border-radius: 8px;
}

.total-mensajes-wha .ant-statistic-title {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
}

.total-mensajes-wha .ant-statistic-content {
  color: #25D366;
  font-size: 1.5rem;
  font-weight: bold;
}

.total-mensajes-wha .ant-statistic-content-prefix {
  margin-right: 0.5rem;
}

/* Ajustes específicos para la tabla */
.mensajes-table {
  margin: 1.5rem 0;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.mensajes-table .ant-table {
  width: 100%;
  min-width: unset; /* Quitar el min-width fijo */
}

.mensajes-table .ant-table-thead > tr > th,
.mensajes-table .ant-table-tbody > tr > td {
  white-space: nowrap;
  min-width: 80px; /* Ancho mínimo para cada columna */
}

/* Ajustes específicos para móviles */
@media (max-width: 768px) {
  .mensajes-table .ant-table-wrapper {
    margin: 0;
    width: 100%;
    overflow-x: auto;
  }

  .mensajes-table .ant-table {
    table-layout: auto;
  }

  .mensajes-table .ant-table-thead > tr > th,
  .mensajes-table .ant-table-tbody > tr > td,
  .mensajes-table .ant-table-summary > tr > td {
    padding: 8px 12px;
    font-size: 0.85rem;
  }

  /* Asignar anchos específicos a cada columna */
  .mensajes-table .ant-table-thead > tr > th:nth-child(1),
  .mensajes-table .ant-table-tbody > tr > td:nth-child(1) {
    min-width: 100px; /* Mes */
  }

  .mensajes-table .ant-table-thead > tr > th:nth-child(2),
  .mensajes-table .ant-table-tbody > tr > td:nth-child(2) {
    min-width: 90px; /* Mensajes */
  }

  .mensajes-table .ant-table-thead > tr > th:nth-child(3),
  .mensajes-table .ant-table-tbody > tr > td:nth-child(3) {
    min-width: 100px; /* Valor Total */
  }
  
  /* Asegurar que el contenedor de la tabla permita scroll */
  .facturacion-card:last-child .facturacion-content {
    overflow: visible;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

@media (max-width: 480px) {
  .title-wha, .facturacion-title {
    font-size: 1rem;
  }

  .facturacion-stats .ant-statistic-title {
    font-size: 0.7rem;
  }

  .facturacion-stats .ant-statistic-content {
    font-size: 0.9rem;
  }
  
  .ant-table {
    font-size: 0.8rem;
  }
  
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 8px 4px;
    white-space: nowrap;
  }
}

.ant-table-container {
  overflow-x: auto;
}

.ant-table {
  min-width: 500px;
}

/* Ajustar columnas para móviles */
@media (max-width: 768px) {
  .mensajes-table .ant-table {
    table-layout: auto; /* Cambiar a auto en móviles */
  }

  .mensajes-table .ant-table-wrapper {
    margin: 0 -1rem; /* Dar espacio negativo para permitir scroll */
    padding: 0 1rem;
    width: calc(100% + 2rem);
  }

  .mensajes-table .ant-table-content {
    overflow-x: auto;
  }

  .mensajes-table .ant-table-thead > tr > th,
  .mensajes-table .ant-table-tbody > tr > td {
    white-space: nowrap;
    padding: 12px 16px;
    min-width: 100px; /* Ancho mínimo para las columnas */
  }

  /* Ajustar el contenedor de la tabla */
  .facturacion-card:last-child .facturacion-content {
    padding: 1rem;
    overflow: hidden; /* Contener el scroll dentro de la card */
  }

  /* Asegurar que la tabla ocupe todo el ancho disponible */
  .ant-table-container {
    overflow-x: auto;
    width: 100%;
  }
}

/* Ajustes adicionales para pantallas muy pequeñas */
@media (max-width: 480px) {
  .mensajes-table .ant-table-thead > tr > th,
  .mensajes-table .ant-table-tbody > tr > td {
    padding: 8px 12px;
    font-size: 0.75rem;
  }

  .mensajes-table .ant-table-summary > tr > td {
    padding: 8px 12px;
    font-size: 0.75rem;
    font-weight: bold;
  }
}

@media (max-width: 480px) {
  .ant-picker-panels {
    flex-direction: column;
  }
  
  .ant-picker-panel {
    width: 100%;
  }

  .date-picker {
    max-width: 100%;
  }
}

/* Ajuste específico para portátiles pequeños */
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .cards-container-wha {
    grid-template-columns: repeat(2, minmax(200px, 300px));
    max-width: 800px;
    width: 90%;
  }
  
  .page-wha {
    margin-left: 50px; /* Menor margen para portátiles pequeños */
  }
}

/* Para pantallas más pequeñas que 1024px, quitar el margen izquierdo */
@media screen and (max-width: 1023px) {
  .page-wha {
    margin-left: 0;
  }
}

/* Ajustar el breakpoint principal para cambiar a vista móvil */
@media screen and (max-width: 1112px) {
  .page-wha {
    padding: 1rem;
    margin-left: 0;
    align-items: center;
    margin-top: 1rem; /* Agregar margen superior para separar de la navbar */
  }

  .cards-container-wha {
    grid-template-columns: minmax(250px, 400px);
    grid-template-areas: 
      "whatsapp"
      "historial"
      "facturacion";
    max-width: 500px;
    width: 95%;
    margin: 0 auto;
    padding: 0.5rem;
    gap: 1.5rem;
  }

  .card-wha, .facturacion-card {
    width: 100%;
    margin-bottom: 0;
  }

  .historial-card {
    grid-column: span 1;
  }
  
  .facturacion-card:last-child {
    grid-column: 1;
    margin-top: 0;
  }
}

/* Eliminar o ajustar los media queries anteriores que se solapan */
/* Mantener solo los ajustes específicos de tamaños más pequeños */
@media screen and (max-width: 480px) {
  .cards-container-wha {
    width: 100%;
    padding: 0.5rem;
  }
  
  /* ...mantener otros ajustes específicos para móviles... */
}

/* Estilos para el modal */
.modal-wha .ant-modal-content {
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

.modal-wha .ant-modal-header {
  padding: 24px;
  border-bottom: 1px solid #f0f0f0;
  background: #25D366;
  color: white;
}

.modal-title-wha {
  display: flex;
  align-items: center;
  gap: 12px;
  color: white;
  font-size: 1.2rem;
}

.modal-icon-wha {
  font-size: 24px;
}

.modal-content-wha {
  padding: 24px 0;
}

.modal-info-wha {
  background: #f8f8f8;
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.price-icon-wha {
  font-size: 24px;
  color: #1890ff;
}

.price-info-wha {
  flex: 1;
}

.price-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.price-details:last-child {
  margin-bottom: 0;
}

.price-label {
  color: #666;
  font-size: 0.9rem;
}

.price-wha {
  font-weight: 600;
  color: #1890ff;
  font-size: 1rem;
}

.modal-benefits-wha {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding: 0 24px;
}

.benefit-item-wha {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
  font-size: 0.9rem;
}

.benefit-item-wha .anticon {
  color: #25D366;
  font-size: 1.1rem;
}

.modal-wha .ant-modal-footer {
  border-top: 1px solid #f0f0f0;
  padding: 16px 24px;
  display: flex;
  justify-content: center; /* Center the buttons */
}

.modal-wha .ant-modal-footer .ant-btn {
  border-radius: 8px;
  height: 40px;
  padding: 0 24px;
}

.modal-wha .ant-modal-footer .ant-btn-primary {
  background: #25D366;
  border-color: #25D366;
}

.modal-wha .ant-modal-footer .ant-btn-primary:hover {
  background: #1fa855;
  border-color: #1fa855;
}

/* Responsive adjustments */
@media (max-width: 576px) {
  .modal-benefits-wha {
    grid-template-columns: 1fr;
  }
  
  .modal-info-wha {
    flex-direction: column;
    text-align: center;
  }
  
  .price-details {
    flex-direction: column;
    gap: 4px;
  }
  
  .modal-wha .ant-modal-footer .ant-btn {
    width: 100%;
    margin-bottom: 8px;
  }
  
  .modal-wha .ant-modal-footer .ant-btn:last-child {
    margin-bottom: 0;
  }
}

/* ...existing code... */

/* Remove the banner styles from the main page */
.free-service-banner {
  display: none;
}

/* ...existing code... */

.free-service-modal {
  margin-top: 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  text-align: center;
}

/* ...existing code... */

.whatsapp-layout {
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 1rem;
}

/* ...existing code... */

.price-info-banner {
  background: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
  width: 100%;
  max-width: 500px;
}

.price-details-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.price-details-banner:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.price-label-banner {
  color: white;
  font-size: 1rem;
  font-weight: 500;
}

.price-value-banner {
  color: #FFEB3B;
  font-size: 1.1rem;
  font-weight: 600;
}

@media (max-width: 768px) {
  .price-info-banner {
    padding: 0.8rem;
  }

  .price-details-banner {
    flex-direction: column;
    gap: 0.3rem;
    text-align: center;
  }

  .price-label-banner, .price-value-banner {
    font-size: 0.9rem;
  }
}

/* ...existing code... */
