.pqrfinalizado-container {
  margin-left: 270px;
  margin-right: 20px;
}

.pqrfinalizado-title {
  text-align: center;
  margin-top: 2%;
}

.pqrfinalizado-subtitle {
  text-align: left;
}

.pqrfinalizado-button {
  background-color: #3F51B5;
  border-color: #3F51B5;
  color: white;
  font-size: 16px;
  height: 40px;
  padding: 0 20px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.pqrfinalizado-button:hover,
.pqrfinalizado-button:focus,
.pqrfinalizado-button:active {
  background-color: #303F9F;
  border-color: #303F9F;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.pqrfinalizado-export-button {
  margin-bottom: 20px;
  margin-right: 20px;
  background-color: #4CAF50;
  border-color: #4CAF50;
}

.pqrfinalizado-export-button:hover {
  background-color: #45A049;
  border-color: #45A049;
}

.pqrfinalizado-manage-button {
  margin-bottom: 20px;
}

.pqrfinalizado-table {
  margin-top: 20px;
}

.pqrfinalizado-filter-dropdown {
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.pqrfinalizado-filter-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.pqrfinalizado-filter-input {
  margin-right: 8px;
}

.pqrfinalizado-filter-search-button {
  padding: 0;
  width: auto;
  background-color: #3F51B5;
  border-color: #3F51B5;
  color: white;
}

.pqrfinalizado-filter-reset-button {
  width: 100%;
  background-color: #3F51B5;
  border-color: #3F51B5;
  color: white;
}

.pqrfinalizado-filter-search-button:hover,
.pqrfinalizado-filter-search-button:focus,
.pqrfinalizado-filter-search-button:active,
.pqrfinalizado-filter-reset-button:hover,
.pqrfinalizado-filter-reset-button:focus,
.pqrfinalizado-filter-reset-button:active {
  background-color: #303F9F;
  border-color: #303F9F;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Estilos específicos para el icono de la lupa */
.pqrfinalizado-filter-search-button .anticon {
  color: white;
}

@media (max-width: 768px) {
  .pqrfinalizado-container {
    margin-left: 20px;
    margin-right: 20px;
  }
}


/* Asegúrate de que el texto en las celdas esté truncado */
.compact-table .ant-table-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px 8px !important;
  font-size: 0.9em;
  line-height: 1.5; /* Ajusta la altura de las celdas */
}

/* Asegura que las columnas fijas tengan el mismo efecto */
.fixed-column {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Ajusta los bordes para darle un estilo más limpio */
.ant-table-thead > tr > th {
  background-color: #f0f0f0;
  color: #333;
  font-weight: bold;
  border-bottom: 2px solid #e4e4e4;
}

/* Estilo para las filas */
.ant-table-row {
  transition: background-color 0.3s;
}

.ant-table-row:hover {
  background-color: #f9f9f9;
}
