/* Contenedor principal */
.tutoriales-container {
    padding: 20px;
    background: linear-gradient(to right, #ece9e6, #ffffff);
    min-height: 100vh;
    font-family: 'Arial', sans-serif;
    margin-left: 250px; /* Ajuste para compensar la barra de navegación lateral */
  }
  
  .tutoriales-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-size: 2.5em;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }
  
  /* Grid de videos */
  .videos-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 20px;
    justify-content: center;
  }
  
  /* Contenedor de cada video */
  .video-item {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .video-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  /* Estilos del iframe */
  .video-item iframe {
    width: 100%;
    height: 180px;
    border-bottom: 2px solid #3f51b5;
  }
  
  /* Título del video */
  .video-title {
    padding: 10px 15px;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
    color: #333;
    text-transform: uppercase;
    background: #f1f1f1;
    width: 100%;
  }
  
  @media (max-width: 768px) {
    .tutoriales-container {
      margin-left: 0; /* Para dispositivos móviles */
    }
  
    .video-item iframe {
      height: 200px; /* Ajuste para pantallas más pequeñas */
    }
  }
  