.formulario-bodega-container {
    display: flex;
    flex-direction: column; /* Ensure the cards are displayed in a column */
    align-items: center; /* Align items to the top */
    min-height: 100vh; /* Ensure it takes the full height of the viewport */
    width: 100%;
    background-color: #f0f2f5;
    padding: 20px;
    box-sizing: border-box;
}

.formulario-bodega-card {
    width: 100%;
    max-width: 800px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: 20px;
}

.ant-card-head-title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

.formulario-bodega-submit-button {
    width: 100%;
    height: 40px;
    background-color: #0056b3;
    border-color: #0056b3;
    font-size: 16px;
    font-weight: bold;
}

.formulario-bodega-submit-button:hover {
    background-color: #003f7f;
    border-color: #003f7f;
}

/* For mobile devices */
@media (max-width: 576px) {
    .formulario-bodega-card {
        margin: 10px;
    }

    .formulario-bodega-container {
        align-items: center; /* Center content on small screens */
    }
}
