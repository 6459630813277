.table-containerEC {
    margin-top: 20px;
    margin-left: 270px;
    margin-right: 20px;
    padding: 20px;
    background: #ffffff;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  }
  
  .table-title {
    font-size: 22px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
  }
  
  .table-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .table-controls-fecha {
    display: flex;
    justify-content: left;
  }

  .table-controls label {
    font-size: 14px;
    margin-right: 10px;
    color: #666;
    font-weight: 500;
  }
  
  .table-controls select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 14px;
    color: #333;
    background-color: #fff;
    transition: border-color 0.3s ease;
  }
  
  .table-controls select:focus {
    border-color: #3f51b5;
    outline: none;
  }
  
  .search-input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 200px;
    font-size: 14px;
    color: #333;
    transition: border-color 0.3s ease;
  }
  
  .search-input:focus {
    border-color: #3f51b5;
    outline: none;
  }
  
  .custom-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .custom-table thead {
    background-color: #3f51b5;
    color: white;
  }
  
  .custom-table th, .custom-table td {
    padding: 12px;
    text-align: left;
    font-size: 14px;
    border-bottom: 1px solid #ddd;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .custom-table th {
    cursor: pointer;
    font-weight: bold;
  }
  
  .custom-table th:hover {
    background-color: #303f9f;
  }
  
  .custom-table tbody tr {
    background-color: #fff;
    transition: background-color 0.3s;
  }
  
  .custom-table tbody tr:nth-of-type(even) {
    background-color: #f9f9f9;
  }
  
  .custom-table tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  .custom-table tbody td {
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .custom-table tbody td:hover {
    background-color: #e0e0e0;
  }
  
  /* Paginación mejorada */
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 5px;
  }
  
  .page-button {
    background-color: #3f51b5;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    min-width: 35px;
  }
  
  .page-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
    transform: none;
  }
  
  .page-button.active {
    background-color: #ff4081;
  }
  
  .pagination-ellipsis {
    margin: 0 5px;
    color: #666;
  }

.controls-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 20px;  /* Add this line to move controls to the left */
}

.controls-group {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 20px; /* Add some space between groups */
}

.date-controls {
  display: flex;
  gap: 10px;
}

.filter-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.export-button {
  padding: 8px 16px;
  background-color: #3f51b5;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.export-button:hover {
  background-color: #303f9f;
}

.historicos-button {
  padding: 8px 16px;
  background-color: #3f51b5;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.historicos-button:hover {
  background-color: #303f9f;
}

@media (max-width: 768px) {
  .table-containerEC {
    margin-left: 20px;
    margin-right: 20px;
  }

  .controls-container {
    flex-direction: column;
  }
  
  .controls-group {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .table-controls-fecha {
    display: block;
  }
}