.dashboard-containerM {
  padding: 20px;
  text-align: center;
  margin-left: 250px;
  background-color: #F6F5F7;
  margin-bottom: -20px;
}

.date-pickerM-containerM {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #E1E1EF;
  padding: 20px;
  border-radius: 12px;
  width: 100%;
  margin: auto;
  margin-bottom: 20px;
}

.date-pickerM-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  width: 100%;
}

.date-pickerM {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.date-pickerM label {
  margin-right: 10px;
  margin-bottom: 20px;
  font-weight: bold;
  color: #333;
}

.date-pickerM input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 10px;
  transition: border-color 0.2s, box-shadow 0.2s;
  width: 180px;
  text-align: center;
  height: 40px;
  background-color: #E1E1EF;
}

.date-pickerM input#startDate {
  border-color: #4caf50;
}

.date-pickerM input#endDate {
  border-color: #f44336;
}

.date-pickerM input:focus {
  border-color: #3f51b5;
  outline: none;
  box-shadow: 0 0 5px rgba(63, 81, 181, 0.5);
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.search-buttonM {
  background-color: #757ee6;
  color: white;
  border: none;
  padding: 9px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.2s, transform 0.2s, box-shadow 0.2s;
  margin-top: -2%;
}

.search-buttonM:hover {
  background-color: #303f9f;
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(48, 63, 159, 0.5);
}

.cards-containerUM {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.rowUM {
  display: flex;
  justify-content: center; /* Cambiado de center a space-between */
  width: 100%;
}

.cardUM {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
  transition: transform 0.2s;
  width: calc(33% - 20px); /* Ajustado para dejar espacio entre las tarjetas */
  box-sizing: border-box;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  margin: 0 10px; /* Añadido margen horizontal */
}

.cardUM:hover {
  transform: translateY(-5px);
}

.icon-circle-UM {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-bottom: 10px;
  padding-top: 8px;
  position: relative;
}

.local-shipping-icon {
  background-color: #41f1b6;
  color: white;
}

.check-circle-icon {
  background-color: #7380ec;
  color: white;
}

.report-icon {
  background-color: #ff7782;
  color: white;
}

.material-icons-sharp {
  font-size: 28px;
}

.cardUM h3 {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #363949;
  text-align: left;
}

.card-table-UM {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
}

.card-table-UM td {
  padding: 0px 10px;
  color: #757577;
}

.cardUM p {
  margin: 5px 0;
  color: #333;
  font-weight: bold;
}

.cardUM .main-number {
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0;
}

.rate-container {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Alinea a la izquierda */
  margin-top: auto; /* Empuja el contenedor de tasa hacia abajo */
}

.rate {
  margin-left: 5px;
}

.success {
  color: #4caf50;
  font-weight: bold;
}

.error {
  color: #f44336;
  font-weight: bold;
}

.success-icon {
  color: #4caf50;
  margin-right: 5px;
}

.error-icon {
  color: #f44336;
  margin-right: 5px;
}

a {
  color: #3f51b5;
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}

/* Estilos para las tablas */
.dashboarUMex-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  font-family: 'Arial', sans-serif;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden; /* Para que el borde sea redondeado */
}

.dashboarUMex-table thead {
  background-color: #3f51b5;
  color: white;
  text-align: left;
  font-weight: bold;
}

.dashboarUMex-table th,
.dashboarUMex-table td {
  padding: 12px 15px;
  text-align: center;
}

.dashboarUMex-table tbody tr {
  border-bottom: 1px solid #ddd;
  transition: background-color 0.2s;
}

.dashboarUMex-table tbody tr:hover {
  background-color: #f1f1f1;
}

.dashboarUMex-table tbody tr:last-child {
  border-bottom: 0;
}

/* Estilos para los botones de las tablas */
.dashboarUMex-button {
  background-color: #3f51b5;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 20px;
  transition: background-color 0.2s, transform 0.2s, box-shadow 0.2s;
}

.dashboarUMex-button:hover {
  background-color: #303f9f;
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(48, 63, 159, 0.5);
}

/* Ajustes adicionales para la tabla */
.dashboarUMex-container {
  margin: 40px 200px 0px;
  overflow-x: auto; /* Permite el desplazamiento horizontal */
  width: 100%;
}

.dashboarUMex-container h3 {
  position: sticky;
  left: 0;
}

.dashboarUMex-table th,
.dashboarUMex-table td {
  border-right: 1px solid #ddd;
}

.dashboarUMex-table th:last-child,
.dashboarUMex-table td:last-child {
  border-right: none;
}

@media (max-width: 1600px) {
  .dashboarUMex-container {
    margin: 40px 150px 0px;
  }
}

@media (max-width: 1400px) {
  .dashboarUMex-container {
    margin: 40px 50px 0px;
  }
}

@media (max-width: 1200px) {
  .dashboarUMex-container {
    margin: 40px 0px 0px;
  }
}

/* Para pantallas medianas (tablets) */
@media (max-width: 1024px) {
  .date-pickerM-row {
    flex-direction: column;
    gap: 20px;
  }

  .date-pickerM {
    flex-direction: column;
  }

  .date-pickerM label {
    margin-bottom: 10px;
  }

  .date-pickerM input {
    width: 100%;
  }

  .cards-containerUM {
    margin-left: 0px;
    margin-right: 0px;
  }

  .rowUM {
    flex-direction: column;
  }

  .cardUM {
    width: calc(100% - 20px);
    margin-bottom: 20px;
  }
}

/* Para pantallas pequeñas (móviles) */
@media (max-width: 768px) {
  .dashboard-containerM {
    margin-left: 0px;
    margin-right: 0px;
  }

  .cards-containerUM {
    margin-left: 0px;
    margin-right: 0px;
  }

  .dashboarUMex-table {
    font-size: 14px; /* Reduce el tamaño de la fuente para móviles */
  }

  .dashboarUMex-table th,
  .dashboarUMex-table td {
    padding: 8px 10px; /* Reduce el padding para móviles */
  }
}