/* Contenedor principal */
.datos-sucursales-container {
    max-width: 1200px;
    margin-left: 18%;
    margin-top: 4%;
    padding: 20px;

  }
  
  /* Encabezado con título y botón de exportación */
  .datos-sucursales-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .datos-sucursales-header h2 {
    font-size: 24px;
    font-weight: bold;
  }
  
  .datos-sucursales-export-btn {
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .datos-sucursales-export-btn:hover {
    background-color: #218838;
    border-color: #218838;
  }
  
  /* Tabla de sucursales */
  .datos-sucursales-table {
    margin-top: 20px;
  }
  
  .ant-table-thead > tr > th {
    background-color: #f5f5f5;
    font-weight: bold;
  }
  
  .ant-table-thead > tr > th:hover {
    background-color: #e0e0e0;
  }
  
  .ant-table {
    border-radius: 8px;
    overflow: hidden;
  }
  
  .ant-table-title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  