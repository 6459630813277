.envios {
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 20px;
  padding: 3rem;
  margin-top: 4%;
  margin-left: 15%;
  max-width: 80%;
  background: linear-gradient(145deg, #ffffff, #f5f5f5);
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
}

.envios h1 {
  text-align: center;
  color: #2c3e50;
  margin-bottom: 2rem;
  font-size: 32px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
}

.envios h1::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: linear-gradient(90deg, #7c74da, #611bc4);
  border-radius: 2px;
}

.envios input {
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 8px;
  border: 1px solid #ccc;
  width: 40%;
  margin-left: 30%;
}

.envios input[type="file"] {
  margin: 20px auto;
  padding: 15px;
  width: 60%;
  border: 2px dashed #7c74da;
  border-radius: 15px;
  background-color: rgba(124, 116, 218, 0.05);
  transition: all 0.3s ease;
  cursor: pointer;
}

.envios input[type="file"]:hover {
  border-color: #611bc4;
  background-color: rgba(124, 116, 218, 0.1);
  transform: translateY(-2px);
}

/* Contenedor para alinear los botones */
.envios .button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 30px 0;
  padding: 0 10%;
}

.envios button.ant-btn-primary {
  background: linear-gradient(145deg, #7c74da, #611bc4);
  color: rgb(0, 0, 0);
  border: none;
  padding: 12px 24px;
  height: auto;
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
  box-shadow: 0 4px 15px rgba(124, 116, 218, 0.3);
  transition: all 0.3s ease;
}

.envios button.ant-btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(124, 116, 218, 0.4);
  background: linear-gradient(145deg, #611bc4, #7c74da);
}

.envios button.ant-btn-primary:disabled {
  background: linear-gradient(145deg, #e0e0e0, #cccccc);
  transform: none;
}

.ant-table {
  margin-top: 30px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.ant-table-thead > tr > th {
  background: linear-gradient(145deg, #7c74da, #611bc4);
  color: rgb(6, 6, 6);
  font-weight: 600;
  text-align: center;
  padding: 16px;
  border: none;
}

.ant-table-tbody > tr > td {
  padding: 16px;
  text-align: center;
  font-size: 14px;
  border-bottom: 1px solid rgba(124, 116, 218, 0.1);
}

.ant-table-tbody > tr:hover > td {
  background-color: rgba(124, 116, 218, 0.05) !important;
}

.ant-radio-wrapper {
  font-size: 16px;
  transition: all 0.3s ease;
}

.ant-radio-wrapper:hover {
  color: #611bc4;
}

.modal-contentMD {
  background: linear-gradient(145deg, #ffffff, #f5f5f5);
  padding: 30px;
  border-radius: 20px;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.ant-select {
  border-radius: 12px;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #7c74da;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #7c74da;
  background-color: #7c74da;
}

/* Animaciones para elementos que aparecen */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.envios h2, .envios h3 {
  animation: fadeInUp 0.5s ease-out;
  color: #2c3e50;
}

.envios h2 {
  font-size: 24px;
  font-weight: 700;
  margin: 30px 0;
  padding-bottom: 10px;
  border-bottom: 3px solid #7c74da;
}

.envios h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0;
  color: #611bc4;
}

/* Media queries para dispositivos móviles */
@media screen and (max-width: 768px) {
  .envios {
    margin: 0;
    padding: 1rem;
    max-width: 100%;
    border-radius: 0;
    margin-top: 13%;
  }

  .envios h1 {
    font-size: 24px;
    margin-bottom: 1.5rem;
  }

  .envios input[type="file"] {
    width: 90%;
    margin: 10px auto;
    padding: 10px;
  }

  .envios .button-container {
    flex-direction: column;
    padding: 0;
    gap: 10px;
  }

  .envios button.ant-btn-primary {
    width: 100%;
    padding: 15px;
    font-size: 14px;
  }

  /* Ajuste para el botón de descarga de plantilla */
  .envios .ant-btn-primary[href="/masivo_drop_ejemplo.xlsx"] {
    width: 90% !important;
    margin: 10px auto !important;
  }

  /* Ajustes para el Select de transportadora global */
  .ant-select {
    width: 90% !important;
    margin: 10px auto !important;
    display: block !important;
  }

  /* Ajustes para la tabla en móvil */
  .ant-table {
    font-size: 12px;
    margin: 10px -15px;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 8px 4px;
    white-space: nowrap;
  }

  /* Hacer la tabla scrolleable horizontalmente */
  .ant-table-container {
    overflow-x: auto;
  }

  /* Ajustes para el modal en móvil */
  .modal-contentMD {
    max-width: 100%;
    margin: 10px;
    padding: 15px;
  }

  /* Ajustes para los radio buttons */
  .ant-radio-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  }

  .ant-radio-wrapper {
    margin: 5px 0;
  }
}

/* Ajustes específicos para pantallas muy pequeñas */
@media screen and (max-width: 375px) {
  .envios h1 {
    font-size: 20px;
  }

  .envios button.ant-btn-primary {
    font-size: 13px;
    padding: 12px;
  }
}

/* Mejoras para la experiencia táctil */
@media (hover: none) {
  .envios button.ant-btn-primary:active {
    transform: scale(0.98);
  }

  .envios input[type="file"] {
    padding: 20px 10px;
  }

  .ant-table-tbody > tr > td {
    padding: 12px 8px;
  }
}
