.modal-overlay-recoleccion {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-recoleccion {
  background: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.modal-close-recoleccion {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: #7380ec;
  border: none;
  cursor: pointer;
}

.formulario {
  background: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  margin: 30px auto;
  font-family: 'Arial', sans-serif;
  color: #333;
  font-size: 14px;
}

.formulario label {
  display: block;
  margin-bottom: 10px;
  color: #333;
  font-weight: bold;
  font-size: 14px;
}

.formulario input,
.formulario select {
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  margin-top: 5px;
  border: 2px solid #ccc;
  border-radius: 5px;
  transition: border 0.3s ease;
  font-size: 14px;
}

.formulario input:focus,
.formulario select:focus {
  border: 2px solid #7380ec;
}

.formulario button {
  width: 100%;
  padding: 12px;
  background-color: #7380ec;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
}

.formulario button:hover {
  background-color: #5b6bdf;
}

.error {
  color: red;
  font-weight: bold;
  text-align: center;
  font-size: 14px;
}

.success {
  color: green;
  font-weight: bold;
  text-align: center;
  font-size: 14px;
}

/* New styles for input group and delete button */
.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.input-group input {
  flex-grow: 1;
  margin-right: 10px;
  margin-bottom: 0;
}

.delete-button {
  padding: 10px 12px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #ff1a1a;
}

/* Adjust the "Add" button for consistency */
.formulario button[type="button"] {
  width: auto;
  margin-top: 14px;
}

/* Ensure consistent spacing for preenvio inputs */
.preenvio-input {
  margin-bottom: 15px;
}