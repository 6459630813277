.table-containerNO {
  margin: 20px;
  font-family: Arial, sans-serif;
  max-width: 75%;
  margin-left: 25%;
  margin-top: 2%;
  overflow-x: auto; /* Permite desplazamiento horizontal */
}

.table-title {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.table-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}

.table-controls label {
  margin-right: 10px;
}

.search-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 200px;
}

.table-wrapper {
  overflow-x: auto; /* Permite desplazamiento horizontal */
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  min-width: 1200px; /* Asegura que la tabla tenga un ancho mínimo */
}

.custom-table th, .custom-table td {
  border: 1px solid #ddd;
  padding: 12px 8px;
  text-align: left;
}

.custom-table th {
  cursor: pointer;
  background-color: #7380ec;
  color: white;
  position: sticky;
  top: 0;
}

.custom-table th:hover {
  background-color: #5b6bdf;
}

.custom-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-button {
  padding: 8px 16px;
  margin: 0 4px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}

.page-button.active {
  background-color: #7380ec;
  color: white;
  border-color: #7380ec;
}

.page-button:hover {
  background-color: #5b6bdf;
  color: white;
  border-color: #5b6bdf;
}

.export-button {
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  background-color: #4CAF50;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.export-button:hover {
  background-color: #45A049;
}
