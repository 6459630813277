/* Estilos para los contenedores generales */
.contenedores {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px; /* Ajuste para una mejor visualización en escritorio */
  margin: 20px auto; /* Margen superior e inferior para un mejor espaciado */
  font-family: "Arial", sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

/* Estilos para la cabecera */
.head {
  width: 100%; /* Usa todo el ancho para mantener consistencia */
  overflow: hidden;
}

/* Estilos para el área de información */
.information {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #e2e1e1;
  color: rgb(0, 0, 0);
  padding: 15px;
  width: 100%; /* Ajustar al ancho total del contenedor para consistencia */
}

.information p {
  margin: 5px 0;
  font-size: 0.9rem; /* Ajuste para una mejor legibilidad */
}

/* Estilos para el contenedor de botones */
.buttons-container {
  width: 100%; /* Ajustar al ancho total del contenedor */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

/* Estilos personalizados para los botones */
.custom-button {
  background: #e2e1e1;
  color: black;
  border: 1px solid #707070;
  padding: 12px 18px; /* Ajuste de padding para un mejor tacto */
  margin: 10px 0; /* Solo margen superior e inferior */
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  width: 80%; /* Ajuste de ancho para botones más grandes y accesibles */
  box-shadow: inset 0 0 0 1px #707070;
}

.custom-button:hover {
  background-color: #333;
  color: #ddd;
}

.custom-button:focus {
  outline: none;
}

@media (max-width: 768px) {
  .contenedores, .head {
    max-width: 95%; /* Más ancho para dispositivos móviles */
  }
}
