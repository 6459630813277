

.order-summary h2 {
  color: #000;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.order-summary p {
  margin: 5px 0;
  line-height: 1.6;
}

.card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.644);
  margin-bottom: 20px;
  padding: 20px;
}

.card h2 {
  color: #333;
  margin: 0;
  padding-bottom: 10px;
  border-bottom: 2px solid #eee;
}

.card p {
  color: #666;
  line-height: 1.4;
  margin: 10px 0;
}

.order-info {
  margin-bottom: 20px;
}

.details-button,
.deliver-button {
  padding: 0.5rem; /* Reduce el padding en pantallas pequeñas */
}

.details-button {
  background-color: #7380ec;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 0.8rem;
  cursor: pointer; /* Hace que el botón sea más interactivo */
  white-space: nowrap; /* Asegura que el texto del botón no se divida en dos líneas */
}

.details-button:hover {
  background-color: rgb(2, 106, 158); /* Oscurece el botón al pasar el mouse */
}

/* Estilos Generales para la Página */
.order-summary {
  font-family: "Arial", sans-serif;
  color: #333;
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background: #f9f9f9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Estilos para los Encabezados dentro de order-summary */
.order-summary h2 {
  color: #0056b3;
  font-size: 1.6rem;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 20px;
  text-align: center;
}

/* Estilos para párrafos dentro de order-summary */
.order-summary p {
  margin: 5px 0;
  line-height: 1.6;
  color: #666;
}

/* Estilos para las tarjetas de pedido */
.card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: translateY(-5px);
}

.card h2 {
  color: #333;
  margin: 0 0 10px;
}

.card p {
  color: #666;
}

/* Estilos para botones de detalles */
.details-button {
  
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.details-button:hover {
  background-color: #208a3b;
}

/* Estilos específicos para Ant Design RangePicker */
.ant-picker {
  width: 100%;  /* Asegura que el picker use todo el ancho disponible */
  margin-bottom: 20px;
}

/* Ajustes de responsividad */
@media (max-width: 768px) {
  .order-summary {
      padding: 15px;
  }

  .card {
      padding: 15px;
  }

  .details-button {
      padding: 8px 16px;
      font-size: 0.8rem;
  }
}
