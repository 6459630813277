.product-layout {
  margin: 60px 20px 20px 270px; /* Aumentado el margin-top para dar espacio al navbar */
  padding-top: 20px;
  min-height: calc(100vh - 60px);
  position: relative;
  z-index: 1;
}

.product-layout::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, #f3f4ff 0%, #ffffff 100%);
  z-index: -1;
}

.product-card {
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  border-radius: 30px;
  padding: 40px;
  box-shadow: 
    0 20px 40px rgba(64, 53, 223, 0.1),
    0 1px 3px rgba(64, 53, 223, 0.05);
  width: 100%;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.product-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 250px;
  background: linear-gradient(135deg, rgba(64, 53, 223, 0.08), rgba(91, 78, 255, 0.08));
  clip-path: polygon(0 0, 100% 0, 100% 70%, 0 100%);
  z-index: 0;
}

.product-card-adicional-group {
  width: 40%;
}

.product-card-adicional {
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 12px;
  padding: 24px;
  background-color: #ffffff;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-bottom: 20px;
}

.product-card-header {
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: minmax(350px, 1fr) 2fr;
  gap: 50px;
  margin-bottom: 40px;
}

.product-image-section {
  position: sticky;
  top: 100px;
  height: fit-content;
}

.product-image {
  width: 100%;
  height: 450px;
  object-fit: contain;
  border-radius: 20px;
  background: white;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease;
  padding: 25px;
  animation: float 6s ease-in-out infinite;
}

.product-image:hover {
  transform: scale(1.02);
}

.product-thumbnails {
  display: flex;
  gap: 12px;
  margin-top: 25px;
  justify-content: center;
  flex-wrap: wrap;
}

.thumbnail-image {
  width: 70px;
  height: 70px;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 2px solid transparent;
  padding: 3px;
  background: white;
}

.thumbnail-image:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(64, 53, 223, 0.2);
}

.thumbnail-image.selected {
  border-color: #4035df;
  box-shadow: 0 0 0 3px rgba(64, 53, 223, 0.1);
}

.product-info,
.product-info-adicional {
  flex: 1;
  padding-right: 30px;
}

.product-title {
  font-size: 2.5em;
  margin: 0 0 20px;
  color: #2d2d2d;
  font-weight: 800;
  line-height: 1.2;
  background: linear-gradient(135deg, #2d2d2d, #4035df);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.product-title-adicional {
  font-size: 20px;
  color: #333;
  font-weight: 300;
}

.product-sku,
.product-id {
  margin: 0 0 16px;
  color: #666;
  font-size: 14px;
}

.product-adicional-info {
  color: #414141;
  font-size: 14px;
  margin: 0;
}

.product-sku-id {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-id, .product-sku {
  margin: 0;
}

.product-tags {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin: 20px 0;
}

.tag {
  background: linear-gradient(135deg, #4035df11, #5b4eff11);
  color: #4035df;
  padding: 8px 16px;
  border-radius: 30px;
  font-size: 0.9em;
  font-weight: 500;
  border: 1px solid rgba(64, 53, 223, 0.2);
  transition: all 0.3s ease;
}

.tag:hover {
  background: linear-gradient(135deg, #4035df, #5b4eff);
  color: white;
  transform: translateY(-2px);
}

.product-type {
  margin: 16px 0;
  color: #444;
  font-size: 16px;
}

.product-pricing {
  background: white;
  padding: 25px;
  border-radius: 20px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
  margin: 30px 0;
}

.product-price-provider,
.product-price-suggested {
  margin: 0;
  color: #333;
  font-size: 18px;
}

.product-stock {
  margin: 16px 0;
  color: #444;
  font-size: 16px;
}

.product-actions {
  display: flex;
  align-items: center;
  margin: 24px 0;
  gap: 16px;
}

.product-button {
  padding: 12px 24px;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
  font-weight: 600;
}

.product-button.primary {
  background: linear-gradient(135deg, #4035df, #5b4eff);
  color: white;
  padding: 15px 30px;
  border-radius: 30px;
  font-size: 1.1em;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease;
  width: 100%;
  max-width: 300px;
  box-shadow: 0 5px 15px rgba(64, 53, 223, 0.3);
}

.product-button.primary:hover {
  background: linear-gradient(135deg, #5b4eff, #6c62ff);
  box-shadow: 0 5px 20px rgba(64, 53, 223, 0.4);
}

.product-card-details {
  background: white;
  padding: 30px;
  border-radius: 20px;
  margin-top: 40px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
}

.product-card-details h3 {
  font-size: 20px;
  margin-bottom: 16px;
  color: #333;
  font-weight: 600;
}

.product-card-details ul {
  list-style-type: none;
  padding-left: 0;
}

.product-card-details ul li {
  margin-bottom: 12px;
  color: #444;
  font-size: 16px;
  padding-left: 24px;
  position: relative;
}

.product-card-details ul li::before {
  content: '•';
  position: absolute;
  left: 0;
  color: #0085FE;
  font-size: 20px;
  line-height: 1;
}

.product-variations {
  margin-top: 32px;
  background-color: #f9f9f9;
  padding: 24px;
  border-radius: 8px;
}

.product-variations h3 {
  font-size: 20px;
  margin-bottom: 16px;
  color: #333;
  font-weight: 600;
}

.product-variations ul {
  list-style-type: none;
  padding-left: 0;
}

.product-variations ul li {
  margin-bottom: 12px;
  color: #444;
  font-size: 16px;
  padding: 12px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

@media (max-width: 768px) {
  .product-layout {
    margin: 60px 0 0 0; /* Ajustado para móviles */
    padding: 15px;
    padding-top: 20px;
    padding-bottom: 100px;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    position: relative;
    overflow-x: hidden;
    z-index: 0; /* Asegurarse de que está por debajo del navbar */
  }

  .product-card {
    padding: 20px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.98);
    margin-top: 0; /* Añadido margen superior */
    margin-top: 20px; /* Dar espacio al botón del menú */
  }

  .product-card-header {
    grid-template-columns: 1fr;
    gap: 25px;
  }

  .product-image-section {
    position: relative;
    top: 0;
    order: -1; /* Asegura que la imagen aparezca primero */
    margin: -20px -20px 0; /* Extiende la imagen al borde */
    padding: 20px;
    background: white;
    border-radius: 20px 20px 0 0;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  }

  .product-image {
    height: 300px;
    padding: 0;
    margin-bottom: 15px;
    border-radius: 15px;
    animation: none;
  }

  .product-thumbnails {
    gap: 10px;
    padding: 0 10px;
    margin-top: 15px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    display: flex;
    justify-content: flex-start; /* Alinea las miniaturas a la izquierda */
    flex-wrap: nowrap; /* Evita que las miniaturas se envuelvan */
  }

  .product-thumbnails::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }

  .thumbnail-image {
    width: 60px;
    height: 60px;
    min-width: 60px; /* Evita que las imágenes se compriman */
    border-radius: 10px;
    margin-right: 5px;
  }

  .product-info {
    padding: 0;
  }

  .product-title {
    font-size: 1.6em;
    margin-bottom: 15px;
    line-height: 1.3;
  }

  .product-sku-id {
    background: #f8f9fa;
    padding: 12px;
    border-radius: 12px;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .product-id, .product-sku {
    font-size: 0.85em;
    color: #666;
  }

  .product-tags {
    display: flex;
    overflow-x: auto;
    padding: 5px 0;
    margin: 10px 0;
    gap: 8px;
  }

  .tag {
    white-space: nowrap;
    padding: 8px 15px;
    font-size: 0.85em;
  }

  .product-pricing {
    margin: 20px 0;
    padding: 20px;
    border-radius: 15px;
    background: #f8f9fa;
  }

  .product-price-provider,
  .product-price-suggested {
    font-size: 1em;
    margin: 8px 0;
  }

  .product-stock {
    background: #e8f5e9;
    color: #2e7d32;
    padding: 10px 15px;
    border-radius: 12px;
    font-size: 0.95em;
    display: inline-block;
    margin: 15px 0;
  }

  .product-button.primary {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 40px);
    max-width: none;
    padding: 16px;
    font-size: 1em;
    border-radius: 15px;
    background: linear-gradient(135deg, #4035df, #5b4eff);
    box-shadow: 0 5px 20px rgba(64, 53, 223, 0.3);
    z-index: 98; /* Por debajo del navbar pero por encima del contenido */
  }

  .product-button.primary:hover,
  .product-button.primary:active {
    background: linear-gradient(135deg, #5b4eff, #6c62ff);
    transform: translateX(-50%); /* Mantener la posición sin animación */
  }

  .product-card-details {
    margin-top: 25px;
    padding: 20px;
    border-radius: 15px;
    background: #f8f9fa;
  }

  .product-card-details:last-child {
    margin-bottom: 80px;
  }

  .product-card-details h3 {
    font-size: 1.1em;
    color: #4035df;
    margin-bottom: 15px;
  }

  .product-card-details ul li {
    font-size: 0.95em;
    line-height: 1.5;
    padding: 12px;
    background: white;
    border-radius: 10px;
    margin-bottom: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }

  .modal-overlay {
    z-index: 999; /* Por encima del navbar */
  }

  /* Ajustar espaciado superior para pantallas muy pequeñas */
  @media (max-width: 360px) {
    .product-layout {
      padding-top: 70px;
    }
  }
}

/* Mejoras para dispositivos más pequeños */
@media (max-width: 360px) {
  .product-layout {
    padding: 10px;
    padding-top: 90px; /* Mantener el mismo padding-top que en tablets */
  }

  .product-card {
    padding: 15px;
  }

  .product-image {
    height: 250px;
  }

  .thumbnail-image {
    width: 50px;
    height: 50px;
  }

  .product-title {
    font-size: 1.4em;
  }

  .product-button.primary {
    padding: 14px;
    font-size: 0.95em;
  }
}

@media (max-width: 768px) {
  .product-layout {
    margin: 60px 0 0 0; /* Ajustado para móviles */
    padding: 10px;
    padding-top: 65px;
  }

  .product-card {
    padding: 15px;
    border-radius: 15px;
  }

  .product-card-header {
    grid-template-columns: 1fr;
    gap: 20px;
    margin-bottom: 20px;
  }

  .product-image-section {
    position: relative;
    top: 0;
  }

  .product-image {
    height: 280px;
    padding: 10px;
    margin-bottom: 10px;
    animation: none; /* Desactivar animación float en móvil */
  }

  .product-thumbnails {
    gap: 8px;
    margin-top: 10px;
  }

  .thumbnail-image {
    width: 50px;
    height: 50px;
    border-radius: 8px;
  }

  .product-info {
    padding-right: 0;
  }

  .product-title {
    font-size: 1.5em;
    margin-bottom: 15px;
  }

  .product-sku-id {
    flex-direction: column;
    gap: 5px;
  }

  .product-tags {
    margin: 15px 0;
  }

  .tag {
    padding: 6px 12px;
    font-size: 0.8em;
  }

  .product-pricing {
    padding: 15px;
    margin: 15px 0;
  }

  .product-price-provider,
  .product-price-suggested {
    font-size: 0.9em;
  }

  .product-button.primary {
    position: fixed;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 30px);
    z-index: 100;
    font-size: 1em;
    padding: 12px 20px;
  }

  .product-card-details {
    padding: 15px;
    margin-top: 20px;
    margin-bottom: 70px; /* Espacio para el botón fijo */
  }

  .product-card-details h3 {
    font-size: 1.2em;
    margin-bottom: 12px;
  }

  .product-card-details ul li {
    font-size: 0.9em;
    padding-left: 20px;
    margin-bottom: 10px;
  }

  /* Modal ajustes */
  .modal-content {
    width: 95%;
    margin: 10px;
    padding: 15px;
  }

  .close-modal-btn {
    top: 10px;
    right: 10px;
  }
}

/* Ajustes específicos para pantallas muy pequeñas */
@media (max-width: 360px) {
  .product-layout {
    padding: 8px;
    padding-top: 60px;
  }

  .product-image {
    height: 240px;
  }

  .product-title {
    font-size: 1.3em;
  }

  .product-button.primary {
    padding: 10px 15px;
    font-size: 0.9em;
  }
}

/* Mejoras para la interacción táctil */
@media (hover: none) {
  .product-button.primary:active {
    transform: translateX(-50%) scale(0.98);
  }

  .thumbnail-image:active {
    transform: scale(0.95);
  }
}

@media (max-width: 480px) {
  .product-card {
    margin: 20px auto 0;
  }
}

/* Animación de carga de imagen */
.image-loading {
  animation: shimmer 1.5s infinite;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
}

@keyframes shimmer {
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
}

@media (max-width: 768px) {
  .product-layout {
    margin: 60px 0 0 0;
    padding: 10px;
    padding-bottom: 80px;
  }

  .product-card {
    padding: 12px;
    border-radius: 15px;
    margin-top: 10px;
  }

  .product-card-header {
    gap: 15px;
    margin-bottom: 15px;
  }

  .product-image-section {
    margin: -12px -12px 0;
    padding: 12px;
  }

  .product-image {
    height: 250px;
    margin-bottom: 10px;
  }

  .product-thumbnails {
    gap: 8px;
    margin-top: 8px;
    padding: 0 5px;
  }

  .thumbnail-image {
    width: 45px;
    height: 45px;
    min-width: 45px;
  }

  .product-info {
    padding: 0;
  }

  .product-title {
    font-size: 1.4em;
    margin-bottom: 10px;
  }

  .product-sku-id {
    padding: 8px;
    margin-bottom: 10px;
  }

  .product-tags {
    margin: 8px 0;
    gap: 6px;
  }

  .tag {
    padding: 6px 12px;
    font-size: 0.8em;
  }

  .product-pricing {
    margin: 15px 0;
    padding: 15px;
  }

  .product-stock {
    padding: 8px 12px;
    margin: 10px 0;
  }

  .product-card-details {
    margin-top: 15px;
    padding: 15px;
    margin-bottom: 15px;
  }

  .product-card-details:last-child {
    margin-bottom: 60px;
  }

  .product-card-details h3 {
    font-size: 1em;
    margin-bottom: 10px;
  }

  .product-card-details ul li {
    padding: 8px;
    margin-bottom: 6px;
    font-size: 0.9em;
  }

  .product-button.primary {
    bottom: 15px;
    padding: 12px;
  }
}