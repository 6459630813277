/* Contenedor principal del formulario */
.shipping-form-container {
  padding: 1%;
  max-width: 50%;
  margin-top: 2%;
  margin-left: 25%;
  margin-right: auto;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}


/* Estilo del formulario */
.shipping-form {
  display: flex;
  flex-direction: column;
}

h2 {
  margin-bottom: 10px;
  color: #3f51b5;
  border-bottom: 1px solid #3f51b5;
  padding-bottom: 5px;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Grupo de formulario */
.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.form-group {
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  margin-right: 5px;
}

label {
  margin-bottom: 4px;
  color: #333;
  font-weight: bold;
  font-size: 12px;
}

.input-field, select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  background-color: #f9f9f9;
}

.input-field:focus, select:focus {
  border-color: #3f51b5;
  box-shadow: 0 0 10px rgba(63, 81, 181, 0.2);
  outline: none;
  background-color: #fff;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 20 20"><polygon points="0,0 20,0 10,10" fill="%23999"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px;
}

/* Estilo del checkbox */
.checkbox-wrapper {
  position: relative;
  display: inline-block;
  margin-top: 10px;
}

.checkbox-label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.custom-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-custom {
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 2px solid #3f51b5;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s, border-color 0.3s;
}

.custom-checkbox:checked ~ .checkbox-custom {
  background-color: #3f51b5;
  border-color: #3f51b5;
}

.checkbox-custom:after {
  content: "";
  position: absolute;
  display: none;
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}


@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.palpitating {
  animation: heartbeat 1.5s infinite;
}

.red-background {
  background-color: red;
  color: white; /* Asegura que el texto sea legible */
}



.custom-checkbox:checked ~ .checkbox-custom:after {
  display: block;
}

.checkbox-text {
  font-size: 14px;
  color: #333;
  pointer-events: none; /* Hace que el texto no sea seleccionable */
}

.checkbox-wrapper {
  width: 100%;
}

.checkbox-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 48%; /* Ajusta este valor según sea necesario */
}

.checkbox-text {
  font-size: 14px;
  color: #333;
  pointer-events: none;
  white-space: nowrap; /* Evita que el texto se divida en varias líneas */
  overflow: hidden;
  text-overflow: ellipsis; /* Añade puntos suspensivos si el texto es demasiado largo */
}

/* Mensaje de error */
.error-message {
  color: #e74c3c;
  font-size: 10px;
  margin-top: 2px;
  position: absolute;
  top: 100%;
  left: 0;
}

/* Estilo del botón de enviar */
.submit-button {
  background-color: #3f51b5;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit-button:hover {
  background-color: #303f9f;
  transform: translateY(-5px);
}

/* Responsivo */
@media (max-width: 768px) {
  .shipping-form-container {
    padding: 10px;
    margin: 10% auto;
  }

  h2 {
    font-size: 18px;
  }

  .input-field, select {
    font-size: 12px;
  }

  .submit-button {
    font-size: 14px;
  }
}

.cotizacion-result {
  margin-top: 10px;
}

.result-table {
  width: 100%;
  border-collapse: collapse;
}

.result-table thead {
  background-color: #f4f4f4;
}

.result-table th, .result-table td {
  border: 1px solid #ddd;
  padding: 5px;
  text-align: center;
}

.result-table th {
  background-color: #007bff;
  color: white;
}

.result-table td img {
  width: 40px;
  height: auto;
}

.result-table td {
  font-weight: bold;
}

.result-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.result-table tbody tr:hover {
  background-color: #f1f1f1;
}

.result-table button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
}

.result-table button:hover {
  background-color: #0056b3;
}

.custom-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease, transform 0.3s ease;
  max-height: 90vh;
  overflow-y: auto;
  z-index: 4050;
}

.custom-overlay {
  background: rgba(0, 0, 0, 0.75);
  transition: opacity 0.3s ease;
  z-index: 1040;
}

.modal-form-group {
  margin-bottom: 10px;
}

.modal-form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.generate-button {
  background-color: #28a745;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.generate-button:hover {
  background-color: #218838;
}

.close-modal-button {
  background-color: #f44336; /* Color de fondo del botón */
  color: white; /* Color del texto del botón */
  padding: 5px 10px; /* Espaciado interno del botón */
  border: none; /* Sin borde */
  border-radius: 5px; /* Bordes redondeados */
  cursor: pointer; /* Cambiar el cursor al pasar sobre el botón */
  margin-top: 10px; /* Espacio superior */
}

.close-modal-button:hover {
  background-color: #d32f2f; /* Color de fondo al pasar el ratón */
}

.loading-spinner {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  color: #333;
}

.shipping-form-main-container {
  display: flex;
  justify-content: space-between;
}

.cotizacion-result-container {
  width: 30%;
  margin-top: 2%;
margin-left: 5%;
margin-right: 10%;
}

.cotizacion-result-container h2 {
  margin-bottom: 10px;
  color: #3f51b5;
  border-bottom: 1px solid #3f51b5;
  padding-bottom: 5px;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.result-table {
  width: 100%;
  border-collapse: collapse;
}

.result-table thead {
  background-color: #f4f4f4;
}

.result-table th, .result-table td {
  border: 1px solid #ddd;
  padding: 5px;
  text-align: center;
}

.result-table th {
  background-color: #007bff;
  color: white;
}

.result-table td img {
  width: 40px;
  height: auto;
}


.custom-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 80%;
  max-width: 400px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease, transform 0.3s ease;
  max-height: 90vh;
  overflow-y: auto;
  z-index: 9999;
}
.custom-modal2 {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 80%;
  max-width: 400px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease, transform 0.3s ease;
  max-height: 90vh;
  overflow-y: auto;
  z-index: 5000;
}

.custom-overlay {
  z-index: 9998;
  background: rgba(0, 0, 0, 0.75);
  transition: opacity 0.3s ease;
}

.modal-form-group {
  margin-bottom: 10px;
}

.modal-form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 12px;
}

.generate-button {
  background-color: #28a745;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
}

.generate-button:hover {
  background-color: #218838;
}

.close-modal-button {
  background-color: #f44336;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 14px;
}

.close-modal-button:hover {
  background-color: #d32f2f;
}

.loading-spinner {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  color: #333;
}

/* Ajustes responsivos para el modal */
@media (max-width: 768px) {
  .custom-modal {
    width: 90%;
    max-width: 350px;
    padding: 15px;
  }

  .generate-button, .close-modal-button {
    padding: 6px 10px;
    font-size: 12px;
  }

  .modal-form-group label {
    font-size: 10px;
  }
}




/* Estilo del formulario */
.shipping-form {
  display: flex;
  flex-direction: column;
}

h2 {
  margin-bottom: 10px;
  color: #3f51b5;
  border-bottom: 1px solid #3f51b5;
  padding-bottom: 5px;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Grupo de formulario */
.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: -15px;
}

.form-group {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  margin-right: 5px;
}

label {
  margin-bottom: 4px;
  color: #333;
  font-weight: bold;
  font-size: 12px;
}

.input-field, select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  background-color: #f9f9f9;
}

.input-field:focus, select:focus {
  border-color: #3f51b5;
  box-shadow: 0 0 10px rgba(63, 81, 181, 0.2);
  outline: none;
  background-color: #fff;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 20 20"><polygon points="0,0 20,0 10,10" fill="%23999"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px;
}

/* Estilo del checkbox */
.checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 5px;
}

input[type="checkbox"] {
  margin-right: 5px;
  transform: scale(1);
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #ddd;
  border-radius: 3px;
  position: relative;
}

input[type="checkbox"]::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #3f51b5;
  border-radius: 2px;
  transform: scale(0) translate(-50%, -50%);
  transition: transform 0.2s ease-in-out;
}

input[type="checkbox"]:checked::before {
  transform: scale(1) translate(-50%, -50%);
}

input[type="checkbox"]:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(63, 81, 181, 0.2);
}

/* Mensaje de error */
.error-message {
  color: #e74c3c;
  font-size: 10px;
  margin-top: 2px;
  position: absolute;
  top: 100%;
  left: 0;
}

/* Estilo del botón de enviar */
.submit-button {
  background-color: #3f51b5;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit-button:hover {
  background-color: #303f9f;
  transform: translateY(-5px);
}



.custom-modal .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
 
}

.custom-modal .close-button {
  position: absolute;
  top: 2px;
  right: 10px;
  background: blueviolet;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}

.custom-modal .close-button:hover {
  color: red; /* Opcional: cambia el color al pasar el cursor */
}

.shipping-form-saldo-disponible {
  background-color: #a1dd69;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  padding: 0.75rem 1rem;
  text-align: right;
  width: max-content;
  margin-left: auto;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: -10px;
}

.shipping-form-saldo-disponible p {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
}


/* responsivo */
/* Responsivo */
/* Responsivo */
@media (max-width: 768px) {
  .shipping-form-container {
    padding: 10px;
    max-width: 90%;
    margin: 10% auto;
  }

  h2 {
    font-size: 18px;
  }

  .form-row {
    flex-direction: column;
  }

  .form-group {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .input-field, select {
    font-size: 14px;
    padding: 10px;
  }

  .submit-button {
    font-size: 20px; /* Tamaño de la fuente del botón */
    padding: 15px; /* Padding del botón */
    width: 100%; /* El botón ocupará todo el ancho disponible */
    max-width: none; /* Asegura que no tenga un límite en el ancho */
    box-sizing: border-box; /* Incluye padding y border en el width */
  }

  .cotizacion-result-container {
    width: 100%;
    margin: 5% auto;
  }

  .modal-form-row {
    flex-direction: column;
  }

  .modal-form-group {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .result-table td {
    font-size: 14px;
  }

  .result-table td img {
    width: 30px;
    height: auto;
  }
}

@media (max-width: 480px) {
  .shipping-form-container {
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 20px;
  }

  h2 {
    font-size: 16px;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .input-field, select {
    font-size: 12px;
    padding: 8px;
  }

  .submit-button {
    font-size: 14px; /* Tamaño de la fuente del botón */
    padding: 10px; /* Padding del botón */
    max-width: none; /* Asegura que no tenga un límite en el ancho */
    box-sizing: border-box; /* Incluye padding y border en el width */
  }

  .cotizacion-result-container {
    margin: 5% auto;
  }

  .result-table td {
    font-size: 12px;
  }

  .result-table td img {
    width: 25px;
    height: auto;
  }
}

.required-label {
  font-weight: bold;
}

.required-asterisk {
  color: red;
}

.required-field {
  border: 2px solid red; /* Borde rojo para resaltar campos obligatorios */
  outline: none;
}

.required-field:focus {
  border-color: darkred; /* Resaltar aún más cuando el campo está enfocado */
}

/* WhatsApp confirmation modal styles */
.ant-modal-content {
  border-radius: 8px;
}

.ant-modal-header {
  border-radius: 8px 8px 0 0;
}

.ant-modal-body p {
  margin: 16px 0;
  font-size: 16px;
  line-height: 1.5;
}

/* Highlight the phone number */
.ant-modal-body strong {
  color: #25D366;  /* WhatsApp green color */
  font-weight: 600;
}
