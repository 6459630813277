.table-container-recoleccion {
  margin-top: 20px;
  margin-left: 270px;
  margin-right: 20px;
  padding: 20px;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.table-title {
  font-size: 22px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
}

.table-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.table-controls label {
  font-size: 14px;
  margin-right: 10px;
  color: #666;
  font-weight: 500;
}

.table-controls select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  color: #333;
  background-color: #fff;
  transition: border-color 0.3s ease;
}

.table-controls select:focus {
  border-color: #3f51b5;
  outline: none;
}

.search-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 200px;
  font-size: 14px;
  color: #333;
  transition: border-color 0.3s ease;
}

.search-input:focus {
  border-color: #3f51b5;
  outline: none;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table thead {
  background-color: #3f51b5;
  color: white;
}

.custom-table th, .custom-table td {
  padding: 12px;
  text-align: left;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s, color 0.3s;
}

.custom-table th {
  cursor: pointer;
  font-weight: bold;
}

.custom-table th:hover {
  background-color: #303f9f;
}

.custom-table tbody tr {
  background-color: #fff;
  transition: background-color 0.3s;
}

.custom-table tbody tr:nth-of-type(even) {
  background-color: #f9f9f9;
}

.custom-table tbody tr:hover {
  background-color: #f1f1f1;
}

.custom-table tbody td {
  cursor: pointer;
  transition: background-color 0.3s;
}

.custom-table tbody td:hover {
  background-color: #e0e0e0;
}

/* Paginación */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-button {
  background-color: #3f51b5;
  color: white;
  border: none;
  padding: 8px 12px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.page-button:hover {
  background-color: #303f9f;
  transform: translateY(-2px);
}

.page-button.active {
  background-color: #ff4081;
}

.recoleccion-button {
  background-color: #3f51b5;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.recoleccion-button:hover {
  background-color: #303f9f;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

/* Para dispositivos con un ancho de pantalla menor a 1024px */
@media (max-width: 1024px) {
  .table-container-recoleccion {
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
  }

  .table-controls {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
  }

  .table-controls select, 
  .table-controls input {
    width: 100%;
    margin-bottom: 10px;
  }

  .search-input {
    width: 100%;
  }
  
  .carrier-select {
    width: 100%;
  }

  .custom-table th, 
  .custom-table td {
    padding: 8px;
  }
}

/* Para dispositivos con un ancho de pantalla menor a 768px */
@media (max-width: 768px) {
  .table-container-recoleccion {
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
  }

  .recoleccion-button, 
  .export-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .pagination {
    flex-wrap: wrap;
    gap: 5px;
  }

  .table-wrapper {
    overflow-x: auto;
  }

  .custom-table thead {
    font-size: 12px;
  }
}

/* Para dispositivos con un ancho de pantalla menor a 480px */
@media (max-width: 480px) {
  .table-container-recoleccion {
    margin-left: 5px;
    margin-right: 5px;
    padding: 5px;
  }

  .table-controls {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }

  .table-controls label {
    font-size: 12px;
  }

  .pagination {
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  .page-button {
    padding: 6px 8px;
  }

  .recoleccion-button {
    width: 100%;
    padding: 8px;
    font-size: 14px;
  }

  .export-button {
    width: 100%;
    padding: 8px;
    font-size: 14px;
  }

  .custom-table th, 
  .custom-table td {
    padding: 6px;
    font-size: 12px;
  }

  .custom-table {
    font-size: 12px;
  }

  .search-input, 
  .carrier-select {
    width: 100%;
  }
}


