.app-container {
  font-family: "Open Sans", sans-serif;
  width: 100%;
  margin: 20px auto;
  max-width: 800px;
  background-color: #f8f9fa;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
}

.app-header {
  background: linear-gradient(135deg, #7380ec 0%, #5a68b1 100%);
  color: white;
  padding: 1rem 1.5rem;
  border-bottom: none;
}

.back-button, .menu-button {
  background: none;
  border: none;
  color: #333;
  font-size: 1.5rem;
  padding: 10px;
  cursor: pointer;
}

.app-header h1 {
  font-size: 1.5rem;
  margin: 0;
  flex: 1;
  text-align: center;
}

.instructions {
  background-color: white;
  padding: 1.2rem;
  font-size: 1.1rem;
  border-radius: 12px;
  text-align: center;
  margin: 15px 5%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border: 1px solid #eaecef;
}

.order-card {
  background-color: white;
  border-radius: 12px;
  margin: 15px 20px;
  padding: 20px;
  transition: transform 0.2s, box-shadow 0.2s;
  border: 1px solid #eaecef;
}

.order-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.order-details {
  padding-bottom: 15px;
}

.order-details h2 {
  color: #2c3e50;
  font-size: 1.3rem;
  margin-bottom: 12px;
  font-weight: 600;
}

.order-details p {
  color: #5f6368;
  margin: 8px 0;
  font-size: 0.95rem;
  line-height: 1.4;
}

.details-button, .deliver-button {
  background-color: #7380ec;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  width: 100%;
}

.details-button:hover {
  background-color: #5a68b1;
  transform: translateY(-1px);
}

.deliver-button {
  background-color: #4caf50;
  margin-top: 10px;
}

.deliver-button:hover {
  background-color: #388e3c;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 8px;
  padding: 20px 0;
}

.pagination button {
  background-color: white;
  border: 1px solid #dee2e6;
  color: #7380ec;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.pagination button.active {
  background-color: #7380ec;
  color: white;
  border-color: #7380ec;
}

.pagination button:hover:not(.active) {
  background-color: #f8f9fa;
  border-color: #7380ec;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

@media (max-width: 768px) {
  .app-container {
    margin: 10px;
    max-width: 95%;
  }

  .order-card {
    margin: 10px;
  }

  .details-button, .deliver-button {
    padding: 10px;
    font-size: 0.8rem;
  }
}

@media (max-width: 500px) {
  .app-header h1 {
    font-size: 1.2rem;
  }

  .details-button, .deliver-button {
    font-size: 0.7rem;
    padding: 8px;
  }
}
