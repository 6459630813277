.login-top-wrapper {
  width: 100%;
  height: 100vh;
  background: url('login-fondo.png') no-repeat center center fixed;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -22px;
  padding: 20px;
    box-sizing: border-box;
}

.login-top-container {
  display: flex;
  width: 800px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  transition: height 0.3s ease;
}

.login-top-container.login-mode {
  height: 440px;
}

.login-top-container.register-mode {
  height: 80vh;
}

.login-top-section {
  flex: 1;
  padding: 20px 40px;
  background-color: white;
  overflow-y: auto;
}

.register-top-section {
  flex: 1;
  background-color: rgba(30, 136, 229, 0.8);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.register-top-section h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: white;
  border-bottom: none;
}

.register-top-section p {
  font-size: 16px;
  margin-bottom: 20px;
}

.info-ingreso-datos {
  margin-top: -10px;
  padding-left: 8px;
  padding-right: 5px;
  padding-bottom: 20px;
  text-align: justify;
}

.register-top-section .login-top-button {
  background-color: rgba(30, 136, 229, 0.8);
  color: white;
  border-color: white;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.register-top-section .login-top-button:hover {
  background-color: white;
  color: rgba(30, 136, 229, 0.8);
  border: 2px solid white;
}

.login-top-input,
.login-top-button,
.login-top-select {
  border-radius: 5px;
  height: 40px;
  width: 100%;
}

.login-top-button {
  background-color: #1e88e5;
  border-color: #1e88e5;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-top-link {
  color: #1e88e5;
  display: block;
  margin-top: 10px;
  text-align: center;
}

.login-top-link:hover {
  color: #1565c0;
}

.login-top-card {
  width: 100%;
  height: 100%;
  box-shadow: none;
  border: none;
}

.login-top-toggle-button {
  color: #1e88e5;
}

.login-top-card .ant-card-head {
  border-bottom: none;
}

.login-top-card .ant-card-head-title {
  color: #1e88e5;
  text-align: center;
  font-size: 30px;
  padding: 16px 0;
}

.login-top-form .ant-input,
.register-top-form .ant-input,
.login-top-form .ant-input-password,
.register-top-form .ant-input-password,
.login-top-form .ant-select-selector,
.register-top-form .ant-select-selector {
  background-color: #F2F2F2;
}

.login-top-form .ant-input:focus,
.register-top-form .ant-input:focus,
.login-top-form .ant-input-password:focus,
.register-top-form .ant-input-password:focus,
.login-top-form .ant-select-selector:focus,
.register-top-form .ant-select-selector:focus {
  border: none;
  box-shadow: none;
}

.login-top-form .ant-input-affix-wrapper,
.register-top-form .ant-input-affix-wrapper {
  border: none;
  background-color: #F2F2F2;
}

.login-top-form .ant-input-affix-wrapper:focus,
.register-top-form .ant-input-affix-wrapper:focus {
  border: none;
  box-shadow: none;
}

.ant-card-bordered {
  border: none;
}

.ant-select-selector {
  background-color: #F2F2F2;
}

.login-top-input,
.login-top-select,
.ant-select-selector {
  width: 255px;
}

.id-number-input {
  width: 162px;
  height: 40px;
  border-radius: 5px;
}

.ant-form-horizontal .ant-form-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  margin-left: -5%;
}

@media screen and (max-width: 900px) {
  .register-top-section {
    display: block;
  }

  .login-top-container {
    width: 400px;
    flex-direction: column;
    max-height: 90vh;
  }

  .login-top-container.login-mode {
    height: auto;
  }
  
  .login-top-container.register-mode {
    height: auto;
  }

  .login-top-section {
    height: auto;
  }

  .register-top-section {
    height: auto;
    order: -1;
  }

  .login-top-input,
  .login-top-select,
  .ant-select-selector,
  .id-number-input {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .register-top-section .login-top-button {
    margin-bottom: 0px;
  }

  .login-top-input {
    margin-top: -5px;
    margin-bottom: -5px;
  }
}

@media screen and (max-width: 480px) {
  .login-top-card .ant-card-head-title {
    font-size: 22px;
    margin-bottom: -20px;
  }

  .register-top-section h2 {
    font-size: 18px;
    margin-bottom: 4px;
  }

  .register-top-section p {
    font-size: 14px;
  }

  .register-top-section .login-top-button {
    font-size: 14px;
    height: 36px;
  }

  .login-top-input,
  .login-top-button,
  .login-top-select,
  .id-number-input {
    height: 36px;
  }

  .login-top-link {
    margin-top: 5px;
  }

  .info-ingreso-datos {
    margin-bottom: -4px;
  }

  .login-top-button {
    margin-top: -2px;
  }

  .login-top-link {
    margin-top: -10px;
    margin-bottom: -30px;
  }
 

}