.landing-drop-container {
  padding: 30px;
  margin-top: 20px;
  margin-left: 270px;
  margin-right: 20px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: margin-right 0.3s ease; /* Añadir transición */
}

.header-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
  padding: 20px 0;
}

.stat-card {
  background: linear-gradient(135deg, #4035df11 0%, #ffffff 100%);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  text-align: center;
}

.stat-card h3 {
  color: #4035df;
  margin: 0;
  font-size: 2em;
  font-weight: 600;
}

.stat-card p {
  color: #666;
  margin: 5px 0 0;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 25px;
}

.product-cardL {
  background: white;
  border-radius: 20px;
  padding: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  height: 700px; /* Aumentado de 650px a 700px */
  display: flex;
  flex-direction: column;
  border: 1px solid #f0f0f0;
  animation: fadeIn 0.5s ease-out;
  position: relative; /* Asegura que el posicionamiento absoluto del botón sea relativo a la tarjeta */
  margin-bottom: 20px;
}

.product-cardL:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(64, 53, 223, 0.1);
}

.product-carousel img {
  width: 100%;
  height: 250px;
  object-fit: contain;
  border-radius: 15px;
  background: #f8f9fa;
}

.slick-prev, .slick-next {
  width: 25px;
  height: 25px;
  z-index: 2;
  background-color: #4035df;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease, background-color 0.3s ease;
}

.product-cardL:hover .slick-prev, 
.product-cardL:hover .slick-next {
  opacity: 1;
}

.slick-prev::before, .slick-next::before {
  font-size: 12px;
  color: #ffffff;
}

.slick-prev:hover, .slick-next:hover {
  background-color: #2a1eb1;
}

.slick-prev {
  left: -12px;
}

.slick-next {
  right: -12px;
}

.slick-dots {
  display: none !important;
}

.product-details {
  margin-top: 15px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  margin-bottom: 100px; /* Aumentar el espacio para los dos botones */
}

.category {
  color: #4035df;
  font-size: 0.85em;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.stock {
  background: #e8f5e9;
  color: #2e7d32;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.85em;
  display: inline-block;
}

.provider {
  color: #3498db;
  font-size: 0.9em;
  margin: 5px 0;
}

.supplier-price {
  color: #888;
  font-size: 0.9em;
  margin: 5px 0;
}

.suggested-price {
  color: #333;
  font-size: 1.1em;
  font-weight: bold;
}

.add-to-cart-btn {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(135deg, #4035df 0%, #5b4eff 100%);
  color: white;
  border: none;
  padding: 12px 25px;
  border-radius: 25px;
  font-weight: 600;
  cursor: pointer;
  font-size: 0.9em;
  width: calc(100% - 40px);
  max-width: 200px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(64, 53, 223, 0.3);
  transition: all 0.3s ease;
}

.add-to-cart-btn:hover {
  animation: pulse 1s infinite ease-in-out;
  background: linear-gradient(135deg, #5b4eff 0%, #6c62ff 100%);
}

.price-input {
  padding: 12px 15px;
  margin-right: 10px;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  width: 150px;
  background: white;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  outline: none;
}

.price-input:hover {
  border-color: #4035df;
}

.price-input:focus {
  border-color: #4035df;
  box-shadow: 0 0 0 3px rgba(64, 53, 223, 0.1);
}

/* Responsividad */
@media (max-width: 768px) {
  .landing-drop-container {
    padding: 10px;
    margin: 0;
    margin-top: 13%;
    border-radius: 0;
  }

  .product-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
  }

  .product-cardL {
    padding: 12px;
    height: auto;
    min-height: 500px;
    margin-bottom: 15px;
    position: relative;
  }

  .category,
  .stock,
  .provider,
  .supplier-price,
  .suggested-price {
    font-size: 0.8em;
  }

  .product-details {
    margin-bottom: 60px; /* Espacio para el botón */
    padding: 10px 0;
  }

  .add-to-cart-btn {
    position: absolute; /* Cambiar de fixed a absolute */
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 30px); /* Ajustar el ancho considerando el padding */
    max-width: none;
    padding: 10px;
    font-size: 0.9rem;
    margin: 0;
    z-index: 1; /* Reducir el z-index */
  }

  .add-to-cart-btn:hover {
    transform: translateX(-50%) translateY(-2px);
  }

  .add-to-cart-btn:active {
    transform: translateX(-50%) translateY(0);
  }

  /* Haciendo los contadores más pequeños */
  .header-stats {
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    padding: 8px;
    margin-bottom: 15px;
  }

  .stat-card {
    padding: 8px;
  }

  .stat-card h3 {
    font-size: 1.2em;
  }

  .stat-card p {
    font-size: 0.7em;
    margin: 2px 0 0;
  }

  /* Ocultar filtros normales en móvil */
  .filters {
    display: none;
  }

  /* Estilos para el botón flotante de filtros */
  .filter-button-mobile {
    position: fixed;
    bottom: 80px;
    right: 20px;
    background: linear-gradient(135deg, #4035df 0%, #5b4eff 100%);
    color: white;
    border: none;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 10px rgba(64, 53, 223, 0.3);
    z-index: 997;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .filter-button-mobile:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(64, 53, 223, 0.4);
    background: linear-gradient(135deg, #5b4eff 0%, #6c62ff 100%);
  }
}

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 12px;
  max-width: 1000px;
  width: 80%;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.close-modal-btn {
  position: absolute;
  margin-top: 2%;
  right: 20px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: black;
}

.close-modal-btn:hover {
  background-color: rgb(167, 167, 167);
  color: #ffffff;
}

.filters {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 30px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.filters select,
.search-input,
.price-input {
  background: white;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  padding: 12px 15px;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  flex: 1;
  min-width: 200px;
  outline: none;
}

.filters select:hover,
.search-input:hover,
.price-input:hover {
  border-color: #4035df;
}

.filters select:focus,
.search-input:focus,
.price-input:focus {
  border-color: #4035df;
  box-shadow: 0 0 0 3px rgba(64, 53, 223, 0.1);
}

.filters select option {
  padding: 10px;
  font-size: 1rem;
}

/* Para pantallas pequeñas */
@media (max-width: 768px) {
  .filters {
    flex-direction: column;
    gap: 0px;
  }
  
  .filters select {
    max-width: 100%;
  }
}

/* Animaciones */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes pulse {
  0% {
    transform: translateX(-50%) scale(1);
    box-shadow: 0 5px 15px rgba(64, 53, 223, 0.3);
  }
  50% {
    transform: translateX(-50%) scale(1.05);
    box-shadow: 0 5px 20px rgba(64, 53, 223, 0.5);
  }
  100% {
    transform: translateX(-50%) scale(1);
    box-shadow: 0 5px 15px rgba(64, 53, 223, 0.3);
  }
}

@keyframes fadeInOut {
  0% { opacity: 0.5; }
  50% { opacity: 1; }
  100% { opacity: 0.5; }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  backdrop-filter: blur(5px);
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
  padding: 30px;
  border-radius: 20px;
  background: white;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.loading-message {
  color: #4035df;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0;
  animation: fadeInOut 2s ease-in-out infinite;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
  padding: 20px 0;
}

.pagination-btn {
  background: white;
  border: 2px solid #4035df;
  color: #4035df;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
}

.pagination-btn:hover:not(:disabled) {
  background: #4035df;
  color: white;
}

.pagination-btn:disabled {
  border-color: #ccc;
  color: #ccc;
  cursor: not-allowed;
}

.page-info {
  color: #666;
  font-size: 0.95rem;
  font-weight: 500;
}

/* Ajuste responsivo para la paginación */
@media (max-width: 768px) {
  .pagination {
    flex-direction: column;
    gap: 10px;
  }
  
  .pagination-btn {
    width: 100%;
    max-width: 200px;
  }
}

.filter-button-mobile {
  position: fixed;
  bottom: 80px;
  right: 20px;
  background: linear-gradient(135deg, #4035df 0%, #5b4eff 100%);
  color: white;
  border: none;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 10px rgba(64, 53, 223, 0.3);
  z-index: 997;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filter-button-mobile:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(64, 53, 223, 0.4);
  background: linear-gradient(135deg, #5b4eff 0%, #6c62ff 100%);
}

.modal-overlay {
  backdrop-filter: blur(8px);
  background: rgba(0, 0, 0, 0.4);
}

.filters-modal {
  background: white;
  width: 90%;
  max-width: 400px;
  border-radius: 20px;
  padding: 25px;
  position: relative;
  animation: slideUp 0.3s ease-out;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.filters-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 2px solid #f0f0f0;
}

.filters-modal-header h3 {
  margin: 0;
  color: #4035df;
  font-size: 1.5em;
  font-weight: 600;
}

.filters-modal-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.filters-modal-content select,
.filters-modal-content input {
  width: 100%;
  padding: 15px;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  font-size: 16px;
  margin: 0;
  background: #f8f9fa;
  transition: all 0.3s ease;
}

.filters-modal-content select:focus,
.filters-modal-content input:focus {
  border-color: #4035df;
  background: white;
  box-shadow: 0 0 0 3px rgba(64, 53, 223, 0.1);
  outline: none;
}

.filters-modal-content .search-input {
  background: white;
  border-color: #4035df;
  margin-bottom: 10px;
}

.filters-modal .close-modal-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: #f8f9fa;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  transition: all 0.3s ease;
}

.filters-modal .close-modal-btn:hover {
  background: #4035df;
  color: white;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Ajuste para dispositivos móviles */
@media (max-width: 768px) {
  .filters-modal {
    width: 95%;
    padding: 20px;
    margin: 10px;
  }

  .filters-modal-content select,
  .filters-modal-content input {
    padding: 12px;
  }
}

.provider-navigation {
  background: linear-gradient(135deg, #4035df11 0%, #ffffff 100%);
  padding: 1.5rem;
  margin-bottom: 2rem;
  border-radius: 12px;
  border: 1px solid rgba(64, 53, 223, 0.1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.provider-nav-header {
  text-align: center;
  margin-bottom: 1.5rem;
}

.provider-nav-header h4 {
  color: #4035df;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.provider-progress {
  display: flex;
  justify-content: center;
  gap: 0.8rem;
  margin: 1.5rem 0;
}

.progress-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #e0e0e0;
  transition: all 0.3s ease;
  position: relative;
}

.progress-dot::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #4035df;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.3s ease;
}

.progress-dot.active {
  background-color: #4035df;
  transform: scale(1.1);
}

.progress-dot.active::after {
  transform: translate(-50%, -50%) scale(1);
}

.provider-nav-buttons {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.provider-nav-buttons button {
  padding: 0.8rem 2rem;
  border: 2px solid #4035df;
  border-radius: 25px;
  background-color: white;
  color: #4035df;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.provider-nav-buttons button:hover:not(:disabled) {
  background-color: #4035df;
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(64, 53, 223, 0.2);
}

.provider-nav-buttons button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  border-color: #ccc;
  color: #999;
}

.provider-info {
  background: #fff;
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
  border: 1px solid #e9ecef;
}

.provider-info h3 {
  color: #333;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.provider-info h3 svg {
  color: #4035df;
}

.modal-content {
  background: white;
  border-radius: 16px;
  padding: 2rem;
  position: relative;
  width: 95%;
  max-width: 1200px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(64, 53, 223, 0.1);
}

.provider-totals {
  margin-top: 1rem;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #e9ecef;
}

.provider-totals p {
  margin: 0.5rem 0;
  color: #666;
  font-size: 0.95rem;
}

.provider-totals strong {
  color: #4035df;
  font-size: 1.1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .provider-navigation {
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .provider-nav-buttons {
    flex-direction: column;
    gap: 0.8rem;
  }

  .provider-nav-buttons button {
    width: 100%;
    justify-content: center;
  }
  
  .modal-content {
    width: 100%;
    padding: 1rem;
    border-radius: 12px;
  }
}

/* ... rest of the code ... */

.floating-cart {
  position: fixed;
  right: -350px; /* Aumentado para más espacio */
  top: 0;
  width: 350px;
  height: 100vh;
  background: #ffffff;
  box-shadow: -5px 0 25px rgba(0, 0, 0, 0.15);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1001;
  display: flex;
  flex-direction: column;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.floating-cart.show {
  right: 0;
}

.cart-header {
  padding: 25px 20px;
  border-bottom: 2px solid #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1002;
  margin-top: -14px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.03);
}

.cart-header h3 {
  font-size: 1.2rem;
  color: #333;
  display: flex;
  align-items: center;
  gap: 8px;
}

.cart-header h3 span {
  background: #4035df;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.9rem;
}

.cart-items {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  padding-bottom: 100px;
}

.cart-items::-webkit-scrollbar {
  width: 8px;
}

.cart-items::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.cart-items::-webkit-scrollbar-thumb {
  background: #4035df;
  border-radius: 4px;
}

.cart-item {
  display: flex;
  gap: 15px;
  padding: 15px;
  border-radius: 12px;
  position: relative;
  background: white;
  margin-bottom: 10px;
  transition: all 0.2s ease;
  border: 1px solid #eee;
}

.cart-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border-color: #4035df20;
}

.cart-item img {
  width: 70px;
  height: 70px;
  object-fit: contain;
  border-radius: 8px;
  background: #f8f9fa;
  padding: 5px;
}

.cart-item-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cart-item-details h4 {
  font-size: 1em;
  margin: 0;
  color: #333;
  line-height: 1.3;
}

.cart-item-price {
  color: #4035df;
  font-weight: 600;
  font-size: 1.1em;
  margin: 5px 0;
}

.quantity-controls {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 8px;
  background: #f8f9fa;
  padding: 5px;
  border-radius: 20px;
  width: fit-content;
}

.quantity-controls button {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: none;
  background: white;
  color: #4035df;
  cursor: pointer;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.quantity-controls button:hover {
  background: #4035df;
  color: white;
  transform: scale(1.1);
}

.quantity-controls span {
  font-weight: 600;
  color: #333;
  min-width: 30px;
  text-align: center;
}

.remove-item {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #999;
  transition: all 0.2s ease;
  padding: 5px;
  border-radius: 50%;
}

.remove-item:hover {
  color: #ff4444;
  background: #fff1f1;
}

.cart-footer {
  position: sticky;
  bottom: 0;
  background: white;
  padding: 20px;
  border-top: 2px solid #f5f5f5;
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.05);
}

.cart-total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 10px 0;
  border-bottom: 1px dashed #eee;
}

.cart-total span:first-child {
  color: #666;
}

.cart-total span:last-child {
  font-size: 1.2em;
  font-weight: 700;
  color: #4035df;
}

.checkout-btn {
  width: 100%;
  padding: 15px;
  background: linear-gradient(135deg, #4035df 0%, #5b4eff 100%);
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1em;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(64, 53, 223, 0.3);
}

.checkout-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(64, 53, 223, 0.4);
}

.empty-cart {
  text-align: center;
  padding: 40px 20px;
  color: #666;
  font-size: 1.1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.empty-cart svg {
  font-size: 3em;
  color: #4035df;
  opacity: 0.5;
}

/* Ajustes para móvil */
@media (max-width: 768px) {
  .floating-cart {
    width: 100%;
    right: -100%;
  }

  .cart-header {
    margin-top: 50px;
    padding: 15px;
  }

  .cart-item {
    padding: 12px;
  }

  .cart-item img {
    width: 60px;
    height: 60px;
  }

  .checkout-btn {
    padding: 12px;
  }
}

.product-card-buttons {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
  width: calc(100% - 30px);
  padding: 0 5px;
  flex-direction: column; /* Cambio a columna para apilar los botones */
}

.add-to-cart-btn,
.send-client-btn {
  width: 100%; /* Ocupar todo el ancho disponible */
  padding: 10px 15px;
  border-radius: 25px;
  font-weight: 600;
  cursor: pointer;
  font-size: 0.85em;
  text-align: center;
  transition: all 0.3s ease;
}

.add-to-cart-btn {
  background: white;
  color: #4035df;
  border: 2px solid #4035df;
  margin-bottom: 61px; /* Espacio entre botones */
}

.add-to-cart-btn:hover {
  background: #4035df;
  color: white;
}

.send-client-btn {
  background: linear-gradient(135deg, #4035df 0%, #5b4eff 100%);
  color: white;
  border: none;
  box-shadow: 0 4px 10px rgba(64, 53, 223, 0.2);
}

.send-client-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(64, 53, 223, 0.3);
}

@media (max-width: 768px) {
  .floating-cart {
    width: 100%;
    right: -100%;
  }

  .cart-header {
    margin-top: 50px; /* Ajustar para navbar móvil */
  }

  .landing-drop-container {
    margin-right: 20px !important;
    transition: none;
  }

  .floating-cart.show + .landing-drop-container {
    margin-right: 20px !important;
  }

  .product-card-buttons {
    position: absolute;
    bottom: 10px;
    width: calc(100% - 24px);
    flex-direction: column;
    gap: 5px;
  }

  .add-to-cart-btn,
  .send-client-btn {
    width: 100%;
    min-width: auto;
  }
}

/* Ajustar el contenedor principal cuando el carrito está visible */
.floating-cart.show ñ+ .landing-drop-container {
  margin-right: 320px;
}

.cart-toggle-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: linear-gradient(135deg, #4035df 0%, #5b4eff 100%);
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: all 0.3s ease;
}

.cart-toggle-btn:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 20px rgba(64, 53, 223, 0.4);
}

.cart-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background: #ff4444;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
  font-weight: bold;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  animation: bounceIn 0.3s ease;
}

@keyframes bounceIn {
  0% { transform: scale(0); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

/* Ajuste para móvil */
@media (max-width: 768px) {
  .cart-toggle-btn {
    bottom: 80px; /* Dar más espacio en móvil */
    right: 20px;
    width: 50px; /* Ligeramente más pequeño en móvil */
    height: 50px;
  }
}

.weight-input-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  padding: 5px 0;
}

.weight-input {
  width: 80px;
  padding: 6px 8px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 0.9em;
  text-align: center;
  background: #f8f9fa;
  transition: all 0.2s ease;
}

.weight-input:focus {
  border-color: #4035df;
  background: white;
  outline: none;
  box-shadow: 0 0 0 2px rgba(64, 53, 223, 0.1);
}

.weight-input::-webkit-inner-spin-button {
  opacity: 1;
  background: transparent;
}

.weight-input-container span {
  color: #666;
  font-size: 0.9em;
}

.cart-weight-total {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  font-size: 0.9em;
  color: #666;
  border-bottom: 1px dashed #eee;
  margin-bottom: 15px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.modal-content {
  background: white;
  border-radius: 16px;
  padding: 2rem;
  position: relative;
  width: 95%;
  max-width: 1200px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(64, 53, 223, 0.1);
}

.close-modal-btn {
  position: fixed;
  top: 20px;
  right: 20px;
  background: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: none;
  z-index: 1001;
  transition: all 0.3s ease;
}

.close-modal-btn:hover {
  background: #4035df;
  color: white;
  transform: rotate(90deg);
}

@media (max-width: 768px) {
  .modal-content {
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 1rem;
    margin: 0;
  }

  .close-modal-btn {
    top: 10px;
    right: 10px;
    width: 35px;
    height: 35px;
  }
}