.sidebarV {
    width: 340px; /* Ajustar según el diseño */
    background-color: #f3f4f6;
    padding: 10px;
    margin-left: 0%;
  }
  .search-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .order-list {
    list-style-type: none;
    padding: 0;
  }
  .order-item {
    background-color: #ffffff;
    border: 1px solid #d1d5db;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 4px;
  }
  .order-header {
    font-weight: bold;
    margin-bottom: 10px;
  }