.shipping-form-container-cotizador-externo {
  padding: 1%;
  max-width: 50%;
  margin-top: 2%;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
}

.guia-simple-wrapper-cotizador-externo {
  width: 100%;
  min-height: 100vh;
  background: url('login-fondo.png') no-repeat center center fixed;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

.guia-simple-main-wrapper-cotizador-externo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  background: url('./login-fondo.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  z-index: 1;
}

.guia-simple-form-container-cotizador-externo {
  width: 90%;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
}

@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.shipping-form-cotizador-externo {
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 15px;
}

.form-row-cotizador-externo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.form-group-cotizador-externo {
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  margin-right: 5px;
}

.form-group-cotizador-externo input[type="text"] {
  margin-bottom: 8px;
}

.form-group-cotizador-externo select {
  max-height: 200px;
  overflow-y: auto;
}

label {
  margin-bottom: 4px;
  color: #333;
  font-weight: bold;
  font-size: 12px;
}

.input-field-cotizador-externo, select-cotizador-externo {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  transition: border-color 0.3s ease;
  background-color: #f9f9f9;
}

.input-field-cotizador-externo[list] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
}

.input-field-cotizador-externo[list]:focus {
  border-color: #23a6d5;
  outline: none;
  box-shadow: 0 0 5px rgba(35, 166, 213, 0.3);
}

.result-table-cotizador-externo {
  width: 100%;
  border-collapse: collapse;
  margin-top: 5px;
  background: rgba(255, 255, 255, 0.95);
}

.result-table-cotizador-externo th, .result-table-cotizador-externo td {
  border: 1px solid #ddd;
  padding: 4px 8px;
  text-align: left;
  font-size: 13px;
}

.result-table-cotizador-externo th {
  background-color: #f4f4f4;
}

.submit-button-cotizador-externo {
  background: linear-gradient(45deg, #23a6d5, #23d5ab);
  color: white;
  padding: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: transform 0.3s ease;
}

.submit-button-cotizador-externo:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.submit-button-cotizador-externo:disabled {
  background: #cccccc;
  transform: none;
  box-shadow: none;
}

.loading-spinner-cotizador-externo {
  text-align: center;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .shipping-form-container-cotizador-externo {
    max-width: 90%;
    margin: 5% auto;
  }
  
  .form-row-cotizador-externo {
    flex-direction: column;
  }

  .guia-simple-form-container-cotizador-externo {
    width: 95%;
    margin: 10px auto;
    padding: 15px;
  }

  .form-row-cotizador-externo {
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
  }

  .form-group-cotizador-externo {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .input-field-cotizador-externo,
  .input-field-cotizador-externo[list] {
    width: 100%;
    min-height: 40px;
    font-size: 16px; /* Mejor legibilidad en móviles */
    padding: 8px 12px;
  }

  /* Mejorar visualización del datalist en móviles */
  datalist {
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
  }

  /* Ajustar botones en móviles */
  .submit-button-cotizador-externo {
    width: 100%;
    padding: 15px;
    font-size: 16px;
    margin-top: 20px;
  }

  /* Ajustes para el modal en móviles */
  .modal-content-cotizador-externo {
    width: 95%;
    margin: 10px;
    padding: 15px;
    max-height: 90vh;
    overflow-y: auto;
  }

  .result-table-cotizador-externo {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .result-table-cotizador-externo th,
  .result-table-cotizador-externo td {
    padding: 10px;
    font-size: 14px;
  }

  /* Ajuste para el espacio del checkbox */
  .form-group-cotizador-externo label {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 10px 0;
  }
}

.modal-overlay-cotizador-externo {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.modal-content-cotizador-externo {
  background: white;
  padding: 15px;
  border-radius: 15px;
  max-width: 700px;
  width: 90%;
  position: relative;
  animation: modalFadeIn 0.3s ease;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  max-height: 80vh;
  display: flex;
  flex-direction: column;
}

.modal-header-cotizador-externo {
  text-align: center;
  margin-bottom: 10px;
  padding: 0 10px;
}

.modal-header-cotizador-externo h2 {
  color: #23a6d5;
  font-size: 18px;
  margin: 0 0 5px 0;
}

.modal-header-cotizador-externo p {
  color: #666;
  font-size: 12px;
  line-height: 1.2;
  margin: 0 0 5px 0;
}

.modal-close-cotizador-externo {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #999;
  transition: color 0.3s ease;
}

.modal-close-cotizador-externo:hover {
  color: #23a6d5;
}

.modal-footer-cotizador-externo {
  text-align: center;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #eee;
}

.modal-footer-cotizador-externo p {
  margin: 0 0 5px 0;
  font-size: 12px;
}

.register-button-cotizador-externo {
  background: linear-gradient(45deg, #23a6d5, #23d5ab);
  color: white;
  padding: 6px 15px;
  border: none;
  border-radius: 20px;
  font-size: 13px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin: 5px 0;
}

.register-button-cotizador-externo:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(35, 166, 213, 0.3);
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Estilos para el datalist personalizado */
.custom-datalist-cotizador-externo {
  position: absolute;
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  z-index: 1000;
  margin-top: 2px;
}

.custom-datalist-option-cotizador-externo {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 14px;
  border-bottom: 1px solid #f0f0f0;
}

.custom-datalist-option-cotizador-externo:hover {
  background-color: #f5f5f5;
}

/* Estilo para el scrollbar del datalist */
.custom-datalist-cotizador-externo::-webkit-scrollbar {
  width: 8px;
}

.custom-datalist-cotizador-externo::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.custom-datalist-cotizador-externo::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.custom-datalist-cotizador-externo::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Ajustes responsive */
@media (max-width: 768px) {
  .custom-datalist-cotizador-externo {
    max-height: 200px;
  }

  .custom-datalist-option-cotizador-externo {
    padding: 12px;
    font-size: 16px; /* Más grande para móviles */
  }
}

.info-banner-cotizador-externo {
  background: linear-gradient(135deg, #23a6d5, #23d5ab);
  color: white;
  padding: 15px 20px;
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0 4px 15px rgba(35, 166, 213, 0.2);
  position: relative;
  overflow: hidden;
}

.info-banner-content-cotizador-externo {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 15px;
}

.info-banner-icon-cotizador-externo {
  font-size: 24px;
  min-width: 24px;
}

.info-banner-text-cotizador-externo {
  font-size: 14px;
  line-height: 1.5;
}

.info-banner-text-cotizador-externo strong {
  font-weight: 600;
  color: #ffffff;
}

@media (max-width: 768px) {
  .info-banner-content-cotizador-externo {
    flex-direction: column;
    text-align: center;
  }
  
  .info-banner-text-cotizador-externo {
    font-size: 13px;
  }
}
