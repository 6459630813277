.modal-transportadoras-main-card {
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 270px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.modal-transportadoras-row {
  display: flex;
  flex-wrap: wrap;
}

.modal-transportadoras-col {
  margin-bottom: 16px;
}

.modal-transportadoras-transporter-card {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.modal-transportadoras-transporter-image {
  width: 100%;
  height: 150px;
  width: auto;
  margin: 0 auto;
  object-fit: contain;
  margin-bottom: 20px;
}

.modal-transportadoras-estado {
  margin-top: auto;
}

.modal-transportadoras-execute-button {
  background-color: #8c8cff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.modal-transportadoras-execute-all-row {
  margin-top: 20px;
}

.modal-transportadoras-execute-all-button {
  background-color: #8c8cff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  height: 44px;
  display: block;
  margin: 0 auto;
}

@media screen and (max-width: 1200px) {
    .modal-transportadoras-col {
      flex: 0 0 50%; /* Cada columna ocupará el 50% del ancho, es decir, dos columnas por fila */
      max-width: 50%; 
    }
  }

@media screen and (max-width: 768px) {
  .modal-transportadoras-main-card {
    margin-left: 20px;
  }
  .modal-transportadoras-col {
    flex: 0 0 100%; /* Cada columna ocupará el 100% del ancho */
    max-width: 100%; 
  }
}

@media (max-width: 576px) {
  .modal-transportadoras-execute-all-button {
    width: 100%;
  }
}