.dashboard-containerAD {
    padding: 20px;
    text-align: center;
    margin-left: 250px;
    background-color: #F6F5F7;
    margin-bottom: -20px;
    height: auto;
    min-height: 91.6vh;
    padding-bottom: 24px;
  }
  
  .cards-containerAD {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .rowAD {
    display: flex;
    justify-content: center; /* Cambiado de center a space-between */
    width: 100%;
  }
  
  .cardAD {
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: left;
    transition: transform 0.2s;
    width: calc(33% - 20px); /* Ajustado para dejar espacio entre las tarjetas */
    box-sizing: border-box;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    margin: 0 10px; /* Añadido margen horizontal */
  }
  
  .cardAD:hover {
    transform: translateY(-5px);
  }
  
  .icon-circle-AD {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .local-shipping-icon {
    background-color: #41f1b6;
    color: white;
  }
  
  .check-circle-icon {
    background-color: #7380ec;
    color: white;
  }
  
  .report-icon {
    background-color: #ff7782;
    color: white;
  }
  
  .track-changes-icon {
    background-color: #7380ec;
    color: white;
  }
  
  .public-icon {
    background-color: #ff7782;
    color: white;
  }
  
  .material-icons-sharp {
    font-size: 28px;
  }
  
  .cardAD h3 {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: bold;
    color: #363949;
  }
  
  .card-table-AD {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;
  }
  
  .card-table-AD td {
    padding: 0px 10px;
    color: #757577;
  }
  
  .cardAD p {
    margin: 5px 0;
    color: #333;
    font-weight: bold;
  }
  
  .cardAD .main-number {
    font-size: 24px;
    font-weight: bold;
    margin: 10px 0;
  }
  
  .table-containerAD {
    background: white;
    padding: 24px;
    border-radius: 8px;
    margin-top: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .ant-table-wrapper {
    margin-top: 20px;
  }
  
  .ant-table {
    background: white;
  }
  
  .ant-table-thead > tr > th {
    background: #f0f2f5;
    font-weight: 600;
  }
  
  .ant-tag {
    min-width: 50px;
    text-align: center;
  }
  
  .table-title {
    font-size: 1.5em;
    font-weight: 600;
    color: #363949;
    margin-bottom: 20px;
    text-align: left;
  }
  
  /* Para pantallas medianas (tablets) */
  @media (max-width: 1024px) {
    .dashboard-containerAD {
      height: 152vh; /* Ocupa toda la altura de la ventana */
    }
  
    .cards-containerAD {
      margin-left: 0px;
      margin-right: 0px;
    }
  
    .rowAD {
      flex-direction: column;
    }
  
    .cardAD {
      width: calc(100% - 20px);
      margin-bottom: 20px;
    }
  }
  
  /* Para pantallas pequeñas (móviles) */
  @media (max-width: 768px) {
    .dashboard-containerAD {
      margin-left: 0px;
      margin-right: 0px;
    }
  
    .cards-containerAD {
      margin-left: 0px;
      margin-right: 0px;
    }
  }