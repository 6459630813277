.agregar-producto-shopify-table-container {
    margin-top: 20px;
    margin-left: -210px;
    margin-right: 20px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: 20px;
}

.ant-form-item {
    margin-bottom: 16px;
    width: 100%; /* Asegura que los campos ocupen todo el espacio disponible */
}

.ant-input,
.ant-select-selector,
.ant-upload,
.ant-checkbox-wrapper {
    border-radius: 4px;
    padding: 8px 12px;
}

.ant-input:focus,
.ant-select-focused .ant-select-selector,
.ant-checkbox-wrapper:hover {
    border-color: #1890ff;
    box-shadow: 0 0 5px rgba(24, 144, 255, 0.5);
}

.ant-btn-primary {
    background-color: #1890ff;
    border-color: #1890ff;
    border-radius: 5px;
    padding: 10px 20px;
    margin-bottom: 2%;
}

.ant-btn-primary:hover {
    background-color: #40a9ff;
    border-color: #40a9ff;
}

.ant-btn {
    white-space: nowrap; /* Evita que el texto del botón se corte en pantallas pequeñas */
}

.ant-btn-dashed {
    border-color: #1890ff;
    color: #1890ff;
    border-radius: 5px;
}

.ant-btn-dashed:hover {
    border-color: #40a9ff;
    color: #40a9ff;
}

.ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.ant-upload-list-item {
    border-radius: 8px;
}

.ant-checkbox + span {
    font-size: 16px;
    margin-left: 8px;
}

.ant-form-item-label > label {
    font-weight: bold;
    color: #595959;
    font-size: 16px;
}

.ant-form-horizontal .ant-form-item {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.ant-form-horizontal .ant-form-item-label {
    text-align: left;
    min-width: 100px;
    margin-right: 8px;
}

.ant-form-horizontal .ant-form-item-control {
    flex-grow: 1;
}

.variation-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
    width: 100%; /* Asegura que ocupe todo el ancho del modal */
}

.variation-row .ant-form-item {
    flex: 1;
    margin-bottom: 0;
}

.variation-remove-button {
    font-size: 20px;
    color: red;
    cursor: pointer;
}

.ant-btn-danger {
    background-color: #ff4d4f;
    border-color: #ff4d4f;
    border-radius: 5px;
}

.ant-btn-danger:hover {
    background-color: #ff7875;
    border-color: #ff7875;
}

.agregar-producto-shopify-ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Alineación superior */
    align-items: stretch; /* Asegura que los elementos ocupen todo el ancho disponible */
    width: 100%;
    max-height: 70vh; /* Limita la altura máxima del modal */
    overflow-y: auto; /* Agrega scroll si el contenido es demasiado alto */
    padding: 20px;
}

.ant-col {
    flex: 1; /* Cada columna ocupa la misma cantidad de espacio */
    padding: 0 8px; /* Espaciado entre las columnas */
}


/* Responsive para tablets */
@media (max-width: 768px) {
    .agregar-producto-shopify-table-container {
        margin-left: 20px;
    }

    .ant-col {
        flex: 1 1 100%; /* Hace que las columnas tomen el 100% del ancho en pantallas pequeñas */
        padding: 8px 4px;
    }

    .variation-row .ant-input {
        width: auto; /* Hace que los inputs dentro de las filas de variaciones sean flexibles */
    }
}

.custom-table {
    min-width: 100%;
    text-align: center;
}

.custom-table .ant-table-thead > tr > th,
.custom-table .ant-table-tbody > tr > td {
    font-size: 12px;
    text-align: center;
    vertical-align: middle;
}

.custom-details {
    text-align: justify;
}

/* Responsive para móviles */
@media (max-width: 480px) {
    .agregar-producto-shopify-table-container {
        width: 100%;
        margin-left: 0;
    }

    .agregar-producto-shopify-ant-modal-body {
        padding: 10px; /* Reduce el padding en dispositivos muy pequeños */
    }

    .ant-form-item {
        margin-bottom: 12px;
    }

    .ant-row, .ant-col {
        flex-direction: column; /* Coloca los elementos en columna */
    }

    .variation-row {
        flex-direction: column; /* Hace que las variaciones se apilen verticalmente */
    }

    .variation-row .ant-form-item-label {
        width: auto;
    }
}
