* {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}

.entregado-container {
  font-family: "Poppins", sans-serif;
  color: #333;
  max-width: 60%;
  margin: 2rem auto;
  padding: 1rem;
}

.pedido {
  background-color: #f8f8f8;
  border-radius: 10px; /* Bordes más redondeados para un aspecto moderno */
  padding: 20px; /* Más espacio interno */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.507);
  margin-bottom: 1rem; /* Separación entre tarjetas */
  transition: transform 0.2s; /* Animación al pasar el mouse */
}

.pedido:hover {
  transform: translateY(-5px); /* Elevación ligera al pasar el mouse */
}

.pedido-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem; /* Espaciado uniforme entre elementos */
}

.pedido h4 {
color: #7380EC;
  font-size: 1.1rem; /* Tamaño más grande para el número de pedido */
  font-weight: 600; /* Más peso para destacar */
}

.pedido p {
  color: #555; /* Color oscuro para mejor legibilidad */
  line-height: 1.5; /* Espaciado de línea para mejorar la legibilidad */
  margin: 0; /* Restablecer márgenes */
}

.detalle-pedido-btn {
  background-color: #7380EC;
  color: white;
  border: none;
  padding: 12px 25px; /* Botón más grande para fácil interacción */
  border-radius: 20px; /* Bordes muy redondeados para el botón */
  cursor: pointer;
  font-size: 0.875rem; /* Tamaño de fuente apropiado para botones */
  font-weight: 500; /* Peso de fuente para botones */
  text-transform: uppercase; /* Texto en mayúscula para el botón */
  letter-spacing: 0.05em; /* Espaciado de letras para el texto del botón */
  transition: background-color 0.2s, box-shadow 0.2s; /* Transiciones suaves para efectos */
  display: block; /* Hacer que el botón ocupe su propio espacio */
  width: 100%; /* El botón se extiende al ancho completo */
  text-align: center; /* Texto centrado dentro del botón */
}

.detalle-pedido-btn:hover {
    background-color: #7380EC; /* Color más oscuro al pasar el mouse */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra más pronunciada al pasar el mouse */
  }

.fecha-pedidos h2,
.fecha-pedidos h3 {
  text-align: center;
  margin-bottom: 0.5rem;
  color: #4a4a4a;
}

.resumen-pedidos {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 20px; /* Space between grid items */
  justify-content: center; /* Center grid items horizontally */
  align-items: start; /* Align grid items to the start vertically */
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.507);
  margin: 20px auto; /* Centers the grid and adds space above and below */
  max-width: none; /* Removes the max-width restriction */
}

.resumen-pedidos div {
  text-align: center;
  grid-template-columns: repeat(
    2,
    minmax(0, 1fr)
  ); /* Two columns with flexible size */
  gap: 20px; /* Space between grid items */
}

.total-pedidos,
.comision,
.total-envio,
.total-ganancias {
  margin: 10px;
}

.lista-pedidos {
  display: flex;
  flex-direction: column; /* Stack the orders vertically */
  align-items: center; /* Center align the orders horizontally */
  justify-content: center; /* Center align the orders vertically if there is extra space */
  width: 100%; /* Use full width of the container */
}







.summary-item {
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: none; /* Ensure the cards can fill the grid columns */
  box-sizing: border-box;
  font-size: 0.9em;
}

.summary-item h3 {
  margin: 0;
  font-size: 1.5em;
}

.summary-item p {
  margin: 5px 0 0;
  font-size: 1.2em;
}


.spinner {
  border: 16px solid #000000; /* Light grey */
  border-top: 16px solid #6c63ff; 
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
}
