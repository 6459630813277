.table-container-novedades-admin {
  font-family: Arial, sans-serif;
  margin-right: 20px;
  margin-left: 270px;
  margin-top: 40px;
  overflow-x: auto; /* Permite desplazamiento horizontal */
}

.table-controls-novedades-admin {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}

.table-controls-novedades-admin label {
  margin-right: 10px;
}

.search-input-novedades-admin {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 200px;
}

.table-wrapper {
  overflow-x: auto; /* Permite desplazamiento horizontal */
}

.custom-table-novedades-admin th, .custom-table-novedades-admin td {
  border: 1px solid #ddd;
  padding: 12px 8px;
  text-align: left;
}

.custom-table-novedades-admin th {
  cursor: pointer;
  background-color: #7380ec;
  color: white;
  position: sticky;
  top: 0;
}

.custom-table-novedades-admin th:hover {
  background-color: #5b6bdf;
}

.custom-table-novedades-admin tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.pagination-novedades-admin {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.page-button-novedades-admin {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #5b6bdf; /* Changed background color */
  cursor: pointer;
}

.page-button.active {
  background-color: #e0218a;
  border-color: #e0218a;
  color: #fff;
}

.page-button-novedades-admin:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pagination-novedades-admin button {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #5b6bdf; /* Red background color */
  color: #fff;
  cursor: pointer;
}

.pagination-novedades-admin button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.delete-button {
  background-color: red;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: darkred;
}

.modal-button {
  height: 44px;
}

@media (max-width: 768px) {
  .table-container-novedades-admin {
    margin-left: 20px;
  }
}
