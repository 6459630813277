.ordenes-y-bodega-container {
    padding: 20px;
    max-width: 85%;
     margin-left: 18%;
  }
  
  .ordenes-y-bodega-table {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .ordenes-y-bodega-table-thead > tr > th {
    background-color: #f0f2f5;
    text-align: center;
    font-weight: bold;
  }
  
  .ordenes-y-bodega-table-tbody > tr > td {
    text-align: center;
  }
  
  .ordenes-y-bodega-select,
  .ordenes-y-bodega-input {
    width: 200px;
  }
  
  .ordenes-y-bodega-space-bottom {
    margin-bottom: 16px;
  }
  