.subscriber-form-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f0f2f5;
  border-radius: 10px;
  margin-left: 3%;
  margin-top: -4%;
}

.subscriber-form-card {
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.ant-card-head-title {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.subscriber-form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.subscriber-submit-button {
  width: 100%;
  height: 40px;
  font-size: 16px;
  margin-left: -2%;
}

/* Responsive styles */

  
@media (max-width: 576px) {
  .subscriber-form-card {
      margin: 10px;
  }

  .subscriber-form-grid {
      grid-template-columns: 1fr;
  }
}
