.archivos-externos-container {
  padding: 20px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  background: linear-gradient(to bottom, #f8f9fa, #ffffff);
}

.upload-section {
  margin-bottom: 32px;
  transition: transform 0.3s ease;
}

.upload-section:hover {
  transform: translateY(-5px);
}

.upload-card {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background: white;
  border: none;
  overflow: hidden;
}

.upload-card .ant-card-head {
  background: #1890ff;
  color: white;
  padding: 10px 16px;
  border-bottom: none;
}

.upload-card .ant-card-head-title {
  font-size: 18px;
  font-weight: 600;
}

.ant-upload-drag {
  padding: 20px;
  height: 160px;
  border: 2px dashed #d9d9d9;
  border-radius: 12px;
  background: #fafafa;
  transition: all 0.3s ease;
}

.ant-upload-drag:hover {
  border-color: #1890ff;
  background: #f0f8ff;
}

.ant-upload-drag-icon {
  font-size: 54px;
  color: #1890ff;
  margin-bottom: 16px;
  transition: transform 0.3s ease;
}

.ant-upload-drag:hover .ant-upload-drag-icon {
  transform: scale(1.1);
}

.ant-upload-text {
  font-size: 18px;
  margin: 12px 0;
  color: #333;
  font-weight: 500;
}

.ant-upload-hint {
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
}

.download-example-button {
  margin-top: 24px;
  width: 80%;
  height: 40px;
  border-radius: 8px;
  font-weight: 500;
  background: #f0f8ff;
  border: 1px solid #1890ff;
  color: #1890ff;
  transition: all 0.3s ease;
}

.download-example-button:hover {
  background: #1890ff;
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(24, 144, 255, 0.2);
}

.ant-card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
}

.upload-icon {
  font-size: 36px;
  color: #1890ff;
}

.upload-text {
  margin: 8px 0;
  font-size: 14px;
}

.download-button {
  margin-top: 12px;
  width: 100%;
}

@media (min-width: 768px) {
  .archivos-externos-container {
    padding: 48px;
  }
  
  .upload-section {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .archivos-externos-container {
    grid-template-columns: 1fr;
  }
}
