.contenedor {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 60%; /* Ajusta esto según tu diseño */
  margin: auto;
  background: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  font-family: "Arial", sans-serif;
}

.head {
  margin: auto;
  margin-top: 3%;
  max-width: 40%;
  overflow: hidden;
}

.information {
  background: #4a4a4a;
  color: white;
  padding: 15px;
}

.information p {
  margin: 5px 0;
}

.section {
  padding: 15px;
  background: #f7f7f7;
  margin: 15px;
  border-radius: 10px;
}

.section h3 {
  color: #333;
  margin-top: 0;
}

.section p {
  color: #666;
}

.input-file {
  display: none;
}

.image-preview {
  max-width: 100%;
  height: auto;
  margin-top: 15px;
}

.submit-button {
  background: #4caf50;
  color: white;
  padding: 10px;
  margin: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  max-width: 30%;
}

.submit-button:hover {
  background-color: #45a049;
}
