.misPedidos-picking {
  padding: 20px;
  margin-left: 250px;
  margin-right: 20px;
  position: relative;
}

.misPedidos-picking .scroll-container {
  overflow-x: scroll;
  overflow-y: hidden;
  height: 16px;
  margin-bottom: 10px;
}

.misPedidos-picking .scroll-container .scroll-bar {
  height: 16px;
}

.misPedidos-picking .misPedidos-picking-tabla {
  overflow-x: auto;
}

.misPedidos-picking .ant-table-pagination.ant-pagination {
  display: flex;
  justify-content: center;
  float: none;
  margin: 16px 0;
}

.misPedidos-picking .filters {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.misPedidos-picking .filters > * {
  margin-bottom: 10px;
}

/* Ajustes para dispositivos móviles */
@media (max-width: 768px) {
  .misPedidos-picking {
    padding: 10px;
    margin-left: 0;
    margin-right: 0;
  }

  .misPedidos-picking .filters {
    flex-direction: column;
    align-items: stretch;
  }

  .misPedidos-picking .filters > * {
    width: 100%;
  }

  .misPedidos-picking .misPedidos-picking-tabla {
    overflow-x: scroll;
  }

  .misPedidos-picking .ant-dropdown-trigger {
    width: 100%;
  }

  .misPedidos-picking .ant-table {
    overflow-x: scroll;
    width: 100%;
  }
}

/* Ajustes para pantallas muy pequeñas (menos de 480px de ancho) */
@media (max-width: 480px) {
  .misPedidos-picking .filters {
    flex-direction: column;
    align-items: stretch;
  }

  .misPedidos-picking .filters > * {
    width: 100%;
  }

  .misPedidos-picking .misPedidos-picking-tabla {
    overflow-x: scroll;
  }

  .misPedidos-picking .ant-table {
    width: 100%;
    overflow-x: scroll;
  }

  .misPedidos-picking .ant-table-pagination.ant-pagination {
    display: flex;
    justify-content: center;
  }
}
