.GB-table-container {
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 270px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-size: 14px;
}

@media (max-width: 768px) {
    .GB-table-container {
        margin-left: 20px;
    }
}

/* Estilo para los botones de ordenamiento en las columnas */
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
    color: white !important;
}

/* Estilo para el hover de los botones de ordenamiento */
.ant-table-column-sorter-up:hover,
.ant-table-column-sorter-down:hover {
    color: white !important;
}

/* Estilo para el header de la columna cuando está ordenada */
.ant-table-thead th.ant-table-column-sort {
    background: #531dab !important;
    color: white !important;
}

/* Estilo para el hover del header de la columna */
.ant-table-thead th:hover {
    background: #531dab !important;
    color: white !important;
}

/* Actualizar los estilos de ordenamiento y hover */
.ant-table-column-has-sorters:hover .ant-table-column-sorter {
    color: white !important;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
    background: #531dab !important;
    color: white !important;
}

.ant-table-column-sorter:hover {
    color: white !important;
}

.ant-table-filter-trigger:hover {
    color: white !important;
    background: transparent !important;
}

/* Estilos para los encabezados de columnas */
.ant-table-thead th {
    background: #722ed1 !important;
    color: white !important;
}

/* Estilo para los íconos de ordenamiento */
.ant-table-column-sorter {
    color: rgba(255, 255, 255, 0.7) !important;
}

/* Estilo para el hover en los íconos de ordenamiento */
.ant-table-thead th:hover .ant-table-column-sorter {
    color: white !important;
}