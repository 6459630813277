/* RechargeForm.css */
.recharge-form-container {
    max-width: 600px;
    margin-left: 35%;
    margin-top: 1%;
    padding: 20px;
    border-radius: 8px;
    background-color: #dbdbdb;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
  }
  
  .recharge-form-container h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .recharge-form-container p {
    color: #666;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  .account-details {
    background-color: #fff;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .account-details p {
    margin: 5px 0;
  }
  
  .recharge-form {
    display: flex;
    flex-direction: column;
  }
  
  .recharge-form label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
  }
  
  .recharge-form input[type="text"],
  .recharge-form input[type="file"] {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .recharge-form button {
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .recharge-form button:hover {
    background-color: #0056b3;
  }
  