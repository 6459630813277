/* pedidos.css */
.pedidos-tabla {
  max-width: 80%;
  max-height: 100%;
  margin-left: 18%;
  margin-top: 2%;
  margin-bottom: 4%;
  padding: 16px;
  background-color: #bbbbbb;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.pedidos-tabla .ant-table-wrapper {
  overflow-x: auto;
  width: 100%;
}

.pedidos-tabla .ant-table-thead > tr > th {
  background-color: #adadad;
  color: #333;
  font-weight: 500;
}

.pedidos-tabla .ant-table-tbody > tr > td {
  color: #333;
}

.pedidos-tabla .ant-table-filter-trigger-container {
  color: #1890ff;
}

.pedidos-tabla .ant-input-affix-wrapper {
  border-radius: 4px;
  border-color: #d9d9d9;
}

.pedidos-tabla .ant-input-affix-wrapper-focused {
  border-color: #1890ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.pedidos-tabla .ant-btn-primary {
  background-color: #1890ff;
  border-color: #1890ff;
}

.pedidos-tabla .ant-space-item {
  margin-right: 8px;
}

/* Añade estilos adicionales según tus necesidades */

.row-devuelto {
  background-color: #ffc0cb; /* Color rosa claro para pedidos devueltos */
}

.row-en-proceso {
  background-color: #ffff99; /* Color amarillo claro para pedidos en proceso */
}

/* Estilos para móviles */
@media (max-width: 768px) {
  .pedidos-tabla {
    padding: 8px;
    margin-right: 5%;
    margin-left: 11%;
  }

  .pedidos-tabla .ant-table {
    min-width: 600px; /* Ajustar según sea necesario */
  }

  .pedidos-tabla .ant-table-thead > tr > th,
  .pedidos-tabla .ant-table-tbody > tr > td {
    font-size: 12px; /* Reducir el tamaño de fuente para móviles */
    padding: 8px; /* Ajustar el padding para móviles */
  }

  .pedidos-tabla .ant-btn-primary {
    font-size: 12px; /* Reducir el tamaño de fuente de los botones */
    padding: 4px 8px; /* Ajustar el padding de los botones */
  }

  .pedidos-tabla .ant-input-affix-wrapper {
    font-size: 12px; /* Reducir el tamaño de fuente de los inputs */
  }
}
