.lista-de-bodegas-container {
    padding: 20px;
    max-width: 80%;
    margin-left: 18%;
  }
  
  .lista-de-bodegas-table {
   
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
  }
  
  .lista-de-bodegas-table-thead > tr > th {
    background-color: #f0f2f5;
    text-align: center;
    font-weight: bold;
  }
  
  .lista-de-bodegas-table-tbody > tr > td {
    text-align: center;
  }
  
  .lista-de-bodegas-button {
    background-color: #0056b3;
    border-color: #0056b3;
    color: white;
    font-weight: bold;
    border-radius: 5px;
  }
  
  .lista-de-bodegas-button:hover {
    background-color: #003f7f;
    border-color: #003f7f;
  }
  
  .lista-de-bodegas-select,
  .lista-de-bodegas-input {
    width: 200px;
  }
  
  .lista-de-bodegas-space-bottom {
    margin-bottom: 16px;
  }
  