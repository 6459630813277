.misPedidos-e {
  padding: 20px;
  margin-left: 250px;
  margin-right: 20px;
  position: relative;
}

.misPedidos-e .misPedidos-tabla {
  overflow-x: auto;
}

.misPedidos-e .ant-table-pagination.ant-pagination {
  display: flex;
  justify-content: center;
  float: none;
  margin: 16px 0;
}

.misPedidos-e .filters {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.misPedidos-e .filters > * {
  margin-bottom: 10px;
}

/* Ajustes para dispositivos móviles */
@media (max-width: 768px) {
  .misPedidos-e {
    padding: 10px;
    margin-left: 0;
    margin-right: 0;
  }

  .misPedidos-e .filters {
    flex-direction: column;
    align-items: stretch;
  }

  .misPedidos-e .filters > * {
    width: 100%;
  }

  .misPedidos-e .misPedidos-tabla {
    display: flex;
    flex-direction: column;
  }

  .misPedidos-e .ant-dropdown-trigger {
    width: 100%;
  }

  .misPedidos-e .ant-table {
    display: none;
  }

  .misPedidos-e .card-pedidos-e {
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the button */
  }

  .misPedidos-e .card-pedidos-e .card-item-pedidos-e {
    display: flex;
    flex-direction: row;
    justify-content: flex-start; /* Align items to the left */
    width: 100%; /* Ensure full width for items */
  }

  .misPedidos-e .card-pedidos-e .card-item-pedidos-e strong {
    margin-right: 8px;
  }

  .misPedidos-e .card-pedidos-e button {
    margin-top: 10px; /* Add top margin to the button */
    margin-bottom: 0px;
  }
}

/* Ajustes para pantallas muy pequeñas (menos de 480px de ancho) */
@media (max-width: 480px) {
  .misPedidos-e .filters {
    flex-direction: column;
    align-items: stretch;
  }

  .misPedidos-e .filters > * {
    width: 100%;
  }

  .misPedidos-e .misPedidos-tabla {
    overflow-x: scroll;
  }

  .misPedidos-e .ant-table {
    width: 100%;
    overflow-x: scroll;
  }

  .misPedidos-e .ant-table-pagination.ant-pagination {
    display: flex;
    justify-content: center;
  }
}
