.button-container {
    text-align: center;
}

.btnSigAnt {
    width: 150px;
    background-color: #6C63FF;

}

.btnSigAnt:hover{
    background-color: #6b63ffd2;
}

.white-button {
    background-color: #ffffff;
    color: #000000;
}

.white-button:hover{
    background-color: #f0f0f0;
    color: black;
}

.p-placa{
    font-size: 12px;
    color: #6C63FF;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
    color: #6C63FF;
}

h4{
    justify-content: center;
    align-items: center;
}

.resaltado {
    color: #6C63FF; 
    font-weight: bold; 

}