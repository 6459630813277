.inicio-usuario-dashboard-layout {
  margin-left: 250px;
}

.inicio-usuario-dashboard-content {
  padding: 24px 40px;
  background: #ffffff;
}

.inicio-usuario-custom-alert-wrapper {
  margin-bottom: 24px;
  width: 100%;
}

.inicio-usuario-custom-alert {
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
}

.inicio-usuario-alert-icon {
  background-color: #8a2be2; /* Morado oscuro */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 16px;
}

.inicio-usuario-alert-icon svg {
  width: 24px;
  height: 24px;
  color: white;
}

.inicio-usuario-card-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Permite que las tarjetas se ajusten en pantallas pequeñas */
  gap: 24px; /* Espacio entre las tarjetas */
}

.inicio-usuario-custom-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.inicio-usuario-1);
  flex: 1 1 calc(50% - 24px); /* Ajusta el tamaño de la tarjeta a un 50% del contenedor */
  min-width: 300px; /* Asegura que la tarjeta no se haga demasiado pequeña */
  margin-bottom: 24px;
  text-align: justify;
}

.inicio-usuario-custom-card h3 {
  color: #6f42c1; /* Título morado */
}

.inicio-usuario-custom-card p {
  color: #333; /* Texto general */
}

.inicio-usuario-redirect-button {
  background-color: #6f42c1;
  border-color: #6f42c1;
  color: white;
  margin-top: 24px;
  height: 40px;
}

.inicio-usuario-redirect-button:hover {
  background-color: #8a2be2;
  border-color: #8a2be2;
}

.inicio-usuario-carousel {
  max-width: 800px;
  margin: 0 auto;
}

.inicio-usuario-carousel-slide {
  height: 500px;
  line-height: 400px;
  background: #364d79;
  overflow: hidden;
  border-radius: 25px;
}

.inicio-usuario-carousel-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Updated styles for carousel dots */
.inicio-usuario-carousel .slick-dots {
  bottom: -30px; /* Move dots further down */
  left: -90px; /* Move dots further left */
}

/* Optional: Style the dots if needed */
.inicio-usuario-carousel .slick-dots li button {
  background: #6f42c1;
}

.inicio-usuario-carousel .slick-dots li.slick-active button {
  background: #8a2be2;
}

/* Optional: Add responsive adjustments if needed */
@media (max-width: 768px) {
  .inicio-usuario-carousel-slide {
    height: 300px;
    line-height: 300px;
  }
}