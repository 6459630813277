.inventarios-bodega-container {
    padding: 20px;
    background-color: #f9f9f9;
    width: 82%;
    margin-left: 16%;
}

.inventarios-bodega-container .ant-table {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.inventarios-bodega-container .ant-table-thead > tr > th {
    background-color: #fafafa;
    font-weight: bold;
}

.inventarios-bodega-container .ant-table-tbody > tr > td {
    padding: 12px 8px;
}

.inventarios-bodega-container .ant-input-search {
    width: 200px;
    margin-right: 10px;
}

.inventarios-bodega-container .ant-select {
    width: 200px;
    margin-right: 10px;
}

.inventarios-bodega-container .ant-btn {
    height: 44px;
}
