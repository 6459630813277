.formulario-creacion-bodega-container {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formulario-creacion-bodega-form-grid {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  width: 100%;
}

.formulario-creacion-bodega-form-grid .ant-form-item {
  display: grid;
  grid-template-columns: 300px 1fr;
}

.formulario-creacion-bodega-checkbox-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formulario-creacion-bodega-checkbox-section .ant-form-item {
  margin: 0;
  display: flex;
  justify-content: center;
  width: 100%;
}

.formulario-creacion-bodega-form-control-width,
.ant-select {
  width: 100%;
}

.ant-form-item {
  margin-left: 0;
}

.crear-bodega-lista-de-bodegas-container {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}
  
@media (min-width: 769px) {
  .formulario-creacion-bodega-container {
    margin-left: 255px;
  }

  .formulario-creacion-bodega-form-grid {
    grid-template-columns: 1fr 1fr;
  }

  .formulario-creacion-bodega-form-grid .ant-form-item {
    grid-template-columns: 250px 1fr;
  }

  .formulario-creacion-bodega-form-grid .ant-form-item-label {
    padding-right: 16px;
  }

  .formulario-creacion-bodega-form-grid {
    margin-bottom: 0;
  }

  .crear-bodega-lista-de-bodegas-container {
    margin-left: 270px;
  }
}
