.prelogin-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('prelogin-fondo.jpg') no-repeat center center/cover;
  margin-bottom: -22px;
  flex-direction: column;
}

.prelogin-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.prelogin-header {
  background: linear-gradient(90deg, #8e44ad, #3498db);
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 2rem;
}

.prelogin-title {
  color: white;
  font-size: 1.8rem;
  margin-bottom: 0;
}

.cotiza-title {
  text-decoration: none;
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
  display: inline-block;
  transition: all 0.3s ease;
}

.cotiza-title-text {
  color: white;
  font-size: 3rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(52, 152, 219, 0.3);
  transition: all 0.3s ease;
  display: block;
}

.cotiza-title-underline {
  width: 0;
  height: 3px;
  background: linear-gradient(90deg, #2196f3, #ffffff);
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  transition: width 0.3s ease;
}

.cotiza-title:hover .cotiza-title-text {
  transform: translateY(-3px);
  text-shadow: 3px 3px 6px rgba(52, 152, 219, 0.5);
  color: #e3f2fd;
}

.cotiza-title:hover .cotiza-title-underline {
  width: 100%;
}

.prelogin-options {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.prelogin-option {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.prelogin-option-title-top,
.prelogin-option-title-ultima-milla {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.prelogin-option-title-ultima-milla {
  color: #8e44ad;
}

.prelogin-option-title-top {
  color: #3498db;
}

.prelogin-option-description {
  color: #666;
  margin-bottom: 1rem;
}

.prelogin-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: white;
}

.prelogin-button-ultima-milla {
  background-color: #8e44ad;
}

.prelogin-button-ultima-milla:hover {
  background-color: #732d91;
}

.prelogin-button-top {
  background-color: #3498db;
}

.prelogin-button-top:hover {
  background-color: #2980b9;
}

@media screen and (max-width: 768px) {
  .cotiza-title-text {
    font-size: 2.5rem;
  }
  .cotiza-title {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 480px) {
  .cotiza-title-text {
    font-size: 2rem;
  }
  .cotiza-title {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
}