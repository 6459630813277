.reporte-repartidores {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 32px auto;
    max-width: 85%;
    padding: 24px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-left: 17%;
}

.form-group {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #333;
}

select,
input[type="date"] {
    width: 100%;
    padding: 12px;
    font-size: 14px;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    box-sizing: border-box;
    transition: all 0.3s ease;
    background-color: #f8f9fa;
}

select:focus,
input[type="date"]:focus {
    border-color: #6C63FF;
    outline: none;
    box-shadow: 0 0 0 3px rgba(108, 99, 255, 0.1);
}

.btn-generar-reporte {
    background-color: #6C63FF;
    color: #fff;
    border: none;
    padding: 12px 24px;
    font-size: 14px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;
    display: inline-block;
    font-weight: 500;
    box-shadow: 0 2px 4px rgba(108, 99, 255, 0.2);
}

.btn-generar-reporte:hover {
    background-color: #5b52ff;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(108, 99, 255, 0.3);
}

.btn-crear-conductor {
    background-color: #ffffff;
    color: #000000;
    border: 2px solid #000000;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-crear-conductor:hover {
    background-color: rgba(0, 0, 0, 0.034);
}

.table-reporte {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 24px;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.table-reporte th,
.table-reporte td {
    padding: 16px;
    text-align: center;
    border-bottom: 1px solid #eee;
}

.table-reporte th {
    background-color: #f8f9fa;
    font-weight: 600;
    color: #444;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.5px;
}

.table-reporte tr:hover {
    background-color: #f8f9fa;
}

.table-reporte td[style*="cursor: pointer"] {
    transition: color 0.3s ease;
}

.table-reporte td[style*="cursor: pointer"]:hover {
    color: #6C63FF !important;
    background-color: #f0f0ff;
}

/* Estilo para las celdas con números */
.table-reporte td:nth-child(n+6) {
    font-family: 'Roboto Mono', monospace;
    font-size: 14px;
}

/* Encabezado del reporte */
.reporte-repartidores h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 24px;
    font-weight: 600;
    border-bottom: 2px solid #6C63FF;
    padding-bottom: 12px;
}

/* Estilo para el texto del rango de fechas */
.reporte-repartidores p {
    color: #666;
    font-size: 14px;
    margin: 16px 0;
    font-style: italic;
}

/* Responsivo para móviles */
@media (max-width: 768px) {
    .reporte-repartidores {
        margin: 16px;
        max-width: 100%;
        padding: 16px;
    }

    .form-group {
        margin-bottom: 16px;
    }

    select,
    input[type="date"] {
        padding: 10px;
        font-size: 14px;
    }

    .table-reporte {
        display: block;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-reporte th,
    .table-reporte td {
        padding: 12px;
        font-size: 13px;
    }

    .btn-generar-reporte {
        width: 100%;
        text-align: center;
        margin-top: 16px;
    }
}