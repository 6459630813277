/* Estilos/pedidos.css */

/* Estilos generales para la página */
.order-summary {
  font-family: "Arial", sans-serif;
  color: #333;
  max-width: 600px;
  margin: 20px auto;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 4px;
  background: #f3f3f3;
}

.card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.644);
  margin-bottom: 20px;
  padding: 20px;
}

.card h2 {
  color: #333;
  margin: 0;
  padding-bottom: 10px;
  border-bottom: 2px solid #eee;
}

.card p {
  color: #666;
  line-height: 1.4;
  margin: 10px 0;
}

/* Estilos para los encabezados de cada sección */
.order-summary h2 {
  color: #000;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

/* Estilos para los párrafos en cada sección */
.order-summary p {
  margin: 5px 0;
  line-height: 1.6;
}

/* Estilos para la sección de información de pedido */
.order-info {
  margin-bottom: 20px;
}

/* Estilos para la sección de dirección de envío */
.shipping-info {
  margin-bottom: 20px;
}

/* Estilos para la lista de productos */
.product-list {
  margin-bottom: 20px;
}

.product-list div {
  background: #f7f7f7;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.product-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

/* Estilos para el resumen de totales */
.total-summary {
  margin-bottom: 20px;
}

/* Estilos para la información del repartidor */
.delivery-person {
  margin-bottom: 20px;
}

/* Estilos para botones de acción */
.button {
  display: inline-block;
  padding: 10px 15px;
  margin-right: 10px;
  border: none;
  border-radius: 4px;
  color: #fff;
  background-color: #007bff;
  text-decoration: none;
  font-size: 14px;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #0056b3;
}

.button-red {
  background: #d9534f; /* Rojo */
}

/* Estilos para los botones de Mapa y Navegar (asumiendo que estos son enlaces) */
.map-button,
.navigate-button {
  padding: 5px 10px;
  margin: 5px;
  background: #337ab7; /* Azul */
  color: white;
  text-decoration: none;
  border-radius: 4px;
}

.product-name,
.product-quantity,
.product-total {
  margin: 0;
}

.product-subtotal {
  text-align: right;
  margin-top: 10px;
  font-weight: bold;
}

.total-summary {
  margin-top: 20px;
}

.total-summary p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  font-size: 16px;
}

.total-summary p.red-text {
  color: #dc3545; /* Color rojo para textos específicos */
}

.total-summary p.net-total {
  font-weight: bold;
  color: #28a745; /* Color verde para el total neto */
}

/* Estilo para los botones en la sección del repartidor */
.delivery-person-buttons {
  display: flex;
  justify-content: center; /* Centra el contenido horizontalmente */
  align-items: center; /* Centra el contenido verticalmente */
  gap: 10px; /* Ajusta este valor según sea necesario */
  margin-right: 35%;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s;
}

.unassign-button {
  background-color: #6c757d; /* Color gris */
}

.unassign-button:hover {
  background-color: #5a6268; /* Color gris oscuro para hover */
}

.deliver-button {
  background-color: #28a745; /* Color verde */
}

.deliver-button:hover {
  background-color: #218838; /* Color verde oscuro para hover */
}
/* Agregar aquí cualquier otro estilo específico necesario para tu diseño */
