  .table-containerCA {
    margin-top: 20px;
    margin-left: 270px;
    margin-right: 20px;
    padding: 20px;
    background: #ffffff;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  }

  .controls-group {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1;
  }

  .filters-group {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1;
  }

  .export-group {
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }
  
  .filter-select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 14px;
    background-color: #fff;
    transition: border-color 0.3s ease;
  }
  
  .search-input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 14px;
    width: 200px;
    transition: border-color 0.3s ease;
  }
  
  .export-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .export-button:hover {
    background-color: #45a049;
  }
  
  

  .table-title {
    font-size: 22px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
  }
  
  .table-controls {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .table-controls label {
    font-size: 14px;
    margin-right: 10px;
    color: #666;
    font-weight: 500;
  }
  
  .table-controls select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 14px;
    color: #333;
    background-color: #fff;
    transition: border-color 0.3s ease;
    max-width: 40%;
    margin-left: 1%;
  }
  
  .table-controls select:focus {
    border-color: #3f51b5;
    outline: none;
  }
  
  .search-input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 200px;
    font-size: 14px;
    color: #333;
    transition: border-color 0.3s ease;
  }
  
  .search-input:focus {
    border-color: #3f51b5;
    outline: none;
  }
  
  .custom-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .custom-table thead {
    background-color: #3f51b5;
    color: white;
  }
  
  .custom-table th, .custom-table td {
    padding: 12px;
    text-align: left;
    font-size: 14px;
    border-bottom: 1px solid #ddd;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .custom-table th {
    cursor: pointer;
    font-weight: bold;
  }
  
  .custom-table th:hover {
    background-color: #303f9f;
  }
  
  .custom-table tbody tr {
    background-color: #fff;
    transition: background-color 0.3s;
  }
  
  .custom-table tbody tr:nth-of-type(even) {
    background-color: #f9f9f9;
  }
  
  .custom-table tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  .custom-table tbody td {
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .custom-table tbody td:hover {
    background-color: #e0e0e0;
  }
  
  /* Paginación */
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .page-button {
    background-color: #3f51b5;
    color: white;
    border: none;
    padding: 8px 12px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .page-button:hover {
    background-color: #303f9f;
    transform: translateY(-2px);
  }
  
  .page-button.active {
    background-color: #ff4081;
  }
  
  @media (max-width: 768px) {
    .table-containerCA {
      margin-left: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .table-containerCA {
      padding: 10px;
    }
    
    .table-controls {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .table-controls label {
      margin-bottom: 5px;
    }
    
    .pagination {
      flex-wrap: wrap;
    }
  }
  
  .historicos-button {
    background-color: #3f51b5;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin-left: 40%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  }
  
  .historicos-button:hover {
    background-color: #303f9f;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }
  