/* Estilo general para los items del menú */
.navbar-menu a, .dropdown-toggle {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    text-decoration: none;
    color: #333;
    border-radius: 8px;
    background-color: #f8f9fa;
    font-weight: 500;
    transition: background-color 0.3s, color 0.3s;
    width: 100%; /* Para que el botón ocupe todo el ancho */
  }
  
  .navbar-menu a .material-icons,
  .dropdown-toggle .material-icons {
    margin-right: 10px;
  }
  
  .navbar-menu a:hover,
  .navbar-menu a.active,
  .dropdown-toggle:hover {
    background-color: #3f51b5;
    color: white;
  }
  
  /* Dropdown - estilo limpio y sencillo */
  .dropdown {
    position: relative;
    width: 100%;
  }
  
  .dropdown-menu {
    list-style: none;
    padding: 10px 0;
    margin: 0;
    display: none; /* Se oculta por defecto */
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 100%; /* Colocar justo debajo del botón */
    left: 0;
    width: 100%; /* Asegura que el menú tenga el mismo ancho que el botón */
    z-index: 1000; /* Para que sobresalga */
  }
  
  .dropdown-menu li {
    padding: 2px 10%;
  }
  
  .dropdown-menu li a {
    text-decoration: none;
    color: #333;
    display: block;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .dropdown-menu li a:hover {
    background-color: #e0e0e0;
    color: #333;
  }
  
  .dropdown.open .dropdown-menu {
    display: block; /* Mostrar el menú cuando esté abierto */
  }
  
  /* Otros estilos ya existentes */
  .navbar-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #ffffff;
    color: black;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .navbar-tittle {
    flex-grow: 1;
    display: flex;
    align-items: center;
    margin-left: 250px;
  }
  
  .navbar-tittle p {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin: 0;
  }
  
  .user-info {
    display: flex;
    align-items: center;
  }
  
  .user-info p {
    margin: 0 10px;
  }
  
  .panel-link {
    margin-right: 15px;
    text-decoration: underline;
    cursor: pointer;
    color: black;
  }
  
  .user-avatar img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
  
  /* Contenedor principal de la barra de navegación lateral */
  .navbar-container {
    flex-direction: column;
    align-items: center;
    background-color: #f8f9fa;
    padding: 20px;
    width: 250px;
    height: 100vh;
    overflow-y: auto;
    position: fixed;
    left: 0;
    top: 0;
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
  }
  
  .navbar-container.hide {
    transform: translateX(-100%);
  }
  
  .navbar-container.show {
    transform: translateX(0);
  }
  
  /* Scrollbar personalizado */
  .navbar-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .navbar-container::-webkit-scrollbar-track {
    background: #f8f9fa;
  }
  
  .navbar-container::-webkit-scrollbar-thumb {
    background-color: #3f51b5;
    border-radius: 20px;
    border: 3px solid #f8f9fa;
  }
  
  .navbar-title {
    margin-bottom: 20px;
  }
  
  .logo {
    width: 60px;
    margin-bottom: 20px;
    height: auto;
    margin-left: 35%;
  }
  
  .navbar-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  
  .navbar-menu li {
    margin-bottom: 15px;
  }
  
  .close-btn {
    align-self: flex-end;
    cursor: pointer;
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
    color: black;
    display: none;
  }
  
  /* Ajustes para el menú hamburguesa */
  .hamburger-menu {
    display: none;
    flex-direction: column;
    cursor: pointer;
    position: absolute;
    left: 20px;
  }
  
  .hamburger-menu div {
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 5px;
    transition: 0.3s;
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .navbar-container {
      transform: translateX(-100%);
    }
  
    .navbar-top {
      justify-content: space-between;
      width: 100%;
      padding-left: 60px;
    }
  
    .navbar-tittle {
      justify-content: center;
      margin-left: 0;
    }
  
    .navbar-top .user-info {
      display: none;
    }
  
    .hamburger-menu {
      display: flex;
    }
  
    .close-btn {
      display: block;
    }
  
    .content-container {
      margin-left: 0;
      padding: 20px;
    }
  
    .navbar-container.show {
      transform: translateX(0);
    }
  
    .navbar-container .user-info {
      display: flex;
      margin-top: 20px;
    }
  }
  