.bitacora-cartera-dashboard-container {
    padding: 20px;
    text-align: center;
    margin-left: 250px;
    background-color: #F6F5F7;
    margin-bottom: -20px;
}

.bitacora-cartera-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.bitacora-cartera-row {
    display: flex;
    justify-content: center; /* Cambiado de center a space-between */
    width: 100%;
}

.bitacora-cartera-card {
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: left;
    transition: transform 0.2s;
    width: calc(33% - 20px); /* Ajustado para dejar espacio entre las tarjetas */
    box-sizing: border-box;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    margin: 0 10px; /* Añadido margen horizontal */
}

.bitacora-cartera-card:hover {
    transform: translateY(-5px);
}

.bitacora-cartera-icon-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-bottom: 10px;
    padding-top: 8px;
}

.bitacora-cartera-local-shipping-icon {
    background-color: #41f1b6;
    color: white;
}

.bitacora-cartera-check-circle-icon {
    background-color: #7380ec;
    color: white;
}

.bitacora-cartera-report-icon {
    background-color: #ff7782;
    color: white;
}

.bitacora-cartera-track-changes-icon {
    background-color: #7380ec;
    color: white;
}

.bitacora-cartera-public-icon {
    background-color: #ff7782;
    color: white;
}

.bitacora-cartera-material-icons-sharp {
    font-size: 28px;
}

.bitacora-cartera-card h3 {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: bold;
    color: #363949;
    text-align: left;
}

.bitacora-cartera-card-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;
}

.bitacora-cartera-card-table td {
    padding: 0px 10px;
    color: #757577;
}

.bitacora-cartera-card p {
    margin: 5px 0;
    color: #333;
    font-weight: bold;
}

.bitacora-cartera-card .main-number {
    font-size: 24px;
    font-weight: bold;
    margin: 10px 0;
}

.bitacora-cartera-search {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    position: relative;
    left: -3%;
}

.bitacora-cartera-search-input {
    padding: 10px 15px;
    padding-right: 45px; /* Add space for the button */
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 250px;
    font-size: 14px;
}

.bitacora-cartera-search-button {
    position: absolute;
    right: 46%;
    transform: translateX(125px);
    padding: 5px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    background-color: #7380ec;
    color: white;
    display: flex;
    align-items: center;
    top: -4%;
    gap: 0px;
}

.bitacora-cartera-clear-button {
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    background-color: #ff7782;
    color: white;
    display: flex;
    align-items: center;
    gap: 5px;
}

.bitacora-cartera-error {
    color: #ff7782;
    text-align: center;
    margin: 10px 0;
    font-weight: bold;
}

.loading-spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #7380ec;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
}

.loading-text {
    color: #363949;
    font-size: 16px;
    font-weight: 500;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Para pantallas medianas (tablets) */
@media (max-width: 1024px) {
    .bitacora-cartera-cards-container {
        margin-left: 0px;
        margin-right: 0px;
    }

    .bitacora-cartera-row {
        flex-direction: column;
    }

    .bitacora-cartera-cards-container {
        margin-left: 0px;
        margin-right: 0px;
    }
}

/* Para pantallas pequeñas (móviles) */
@media (max-width: 768px) {
    .bitacora-cartera-dashboard-container {
        margin-left: 0px;
        margin-right: 0px;
    }

    .bitacora-cartera-cards-container {
        margin-left: 0px;
        margin-right: 0px;
    }
}