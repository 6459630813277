/* Contenedor principal del componente PagoVendedores */
.pago-vendedores-container {
    max-width: 1200px;
    margin-left: 18%;
    margin-top: 1%;
    padding: 20px;
    
  }
  
  /* Header del componente */
  .pago-vendedores-header {
    background-color: #d4d4f7;
    color: #5e5ef7;
    text-align: center;
    padding: 15px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  /* Módulo de pagos */
  .pago-vendedores-module {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .pago-vendedores-amount, .pago-vendedores-info {
    width: 48%;
    background: #f5f5f5;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }
  
  .pago-vendedores-amount h2, .pago-vendedores-info h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .pago-vendedores-amount p {
    font-size: 30px;
    font-weight: bold;
    color: #333;
  }
  
  .pago-vendedores-info p {
    color: #e63946;
  }
  
  .material-icons-sharp {
    font-size: 40px;
    margin-bottom: 10px;
  }
  
  .pago-vendedores-bank-icon {
    color: #3fc1c9;
  }
  
  .pago-vendedores-info-icon {
    color: #f05454;
  }
  
  /* Sección de retiro */
  .pago-vendedores-withdraw {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .pago-vendedores-withdraw label {
    font-weight: bold;
    font-size: 16px;
  }
  
  .pago-vendedores-withdraw input {
    width: 200px;
  }
  
  /* Tabla */
  .ant-table {
    margin-top: 20px;
  }
  
  h3 {
    margin-top: 20px;
  }
  